md-dialog.abx-print-dialog {
  .print-options-content,
  .print-options-layout {
    margin-top: 16px;
    padding: 16px 16px 0;

    .print-section-title {
      font-weight: 700;
      padding-bottom: 8px;
    }

    .print-option-title {
      @extend .text-uppercase;
      font-weight: 600;
    }

    .print-option-count {
      color: $blue;
      font-weight: 700;
    }
  }

  .print-options-content {
    .print-section-title {
      display: none;
    }
  }

  .abx-layouts-enabled {
    .print-options-content {
      .print-section-title {
        display: block;
      }
    }
  }

  md-dialog-actions {
    border-top: none;
  }
}
