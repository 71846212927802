abx-tree-input {
  display: flex;
  flex-direction: column;

  &[disabled] {
    md-autocomplete md-autocomplete-wrap md-input-container {
      input.md-input {
        pointer-events: none;
      }
    }
  }
}
