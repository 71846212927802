.script-imports {
  abx-import-file-select {
    padding-top: 16px;
    &.main-import-file-select {
      md-select {
        padding-right: 52px;
      }
    }
  }
}
