// -------------------------------------
//   Inputs
// -------------------------------------

.abx-new-input-container {
  &__label {
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding-left: 8px;
  }
  &__input {
    height: 32px;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    padding: 4px 8px;
    width: 100%;
    border-radius: 2px;
    background-color: $white-F5;
    border: 1px solid $color-border-gray;
    &:focus {
      outline: none;
      background-color: $color-white;
      border-color: $secondary;
    }
  }
  &__error {
    min-height: 24px;
    font-size: 12px;
    color: $color-error;
    padding: 3px 0 3px 8px;
  }

  .abx-new-input-status {
    height: 24px;
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &--success {
      color: $green-5C;
    }
  }
}

// ---------
// Constants
// ---------
$field-border-width: 1px;

/* Wrapper for inputs and labels */
abx-input {
  display: block;
}

%abx-input {
  z-index: 1;
  display: block;
  margin: 0;
  font-size: 14px;
  color: $color-text;
}
%abx-input__field--disabled {
  cursor: not-allowed;
  color: $color-dark-gray;
}
%abx-required-asterisk {
  content: " *";
  color: $color-link;
}
%abx-required-asterisk--invalid {
  color: $color-error;
}

.abx-input {
  @extend %abx-input;

  md-input-container {
    padding: 0;
    margin: 0;
  }

  &__field {
    box-sizing: border-box;
  }

  // Readonly
  .abx-input__readonly--value {
    padding-left: 8px;
    margin: 8px 0 18px;
    font-size: 14px;
    line-height: 1.2;

    .no-value {
      @extend .text-placeholder;
      font-size: 14px;
    }
  }

  // Non-editable
  &:not(.abx-input--editable) {
    &:not(.abx-input--saving) &:not(.abx-input--readonly) .abx-input__label {
      color: $color-medium-gray;
    }

    .abx-input__label {
      color: $color-dark-gray;
      font-weight: normal;
    }

    .abx-input__field {
      @extend %abx-input__field--disabled;
    }
  }

  .abx-input__error-container {
    min-height: 20px;
    font-size: 11px;
  }

  .abx-input__helper-text {
    padding: 2px 8px;
  }

  .abx-input__error-message {
    padding-left: 10px;
    height: 100%;
    color: $color-error;
    -webkit-touch-callout: none;
  }

  .abx-input__failed-save {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px 2px;

    background-color: $color-error;
    color: $white;

    .abx-input__try-again {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .blue-icon {
    color: $akita-blue;
  }

  &.abx-input--required .abx-input__label::after {
    @extend %abx-required-asterisk;
  }

  &.abx-input--required.abx-input--invalid .abx-input__label::after {
    @extend %abx-required-asterisk--invalid;
  }

  md-select:focus {
    // Apply custom focus styling to indicate to the user that they can
    // interact with the select if they tabbed into it
    background-color: $white;
    // Blue border all the way around to differentiate from other inputs
    // (only bottom blue border) so they don't think they can type in it
    border: $field-border-width solid $secondary;
  }

  &--trailing-icon {
    .abx-input__status {
      margin-right: 30px; // icon (24px) + margin (6px)
    }
  }
}

/* Header for the input */
.abx-input__status {
  height: 24px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  /* Revert button */
  .abx-input__revert {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 24px;
    line-height: initial;
    opacity: 1;
    &.ng-hide {
      opacity: 0;
    }

    md-icon {
      font-size: 18px;
      padding: 2px 0;
    }
  }

  /* Success indicator */
  .abx-input__success {
    color: $green-5C;
  }
}

%abx-input__field--editing {
  border-bottom-color: $secondary;
  outline: none;
  background-color: transparent;
}

%abx-input__field--invalid {
  background-color: $red-10;
  border-bottom-color: $red;
}

%abx-input__field--failure {
  border-bottom-color: transparent;
}

/* Container element for text inputs */
.abx-input__text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

/* The prefix element for prefixed text inputs */
.abx-input__prefix,
.abx-input__suffix {
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding: 8px 4px;
  height: 32px;
  background-color: $white-F5;
  border: 1px solid $color-border-gray;
  border-radius: 2px;
  transition: background-color 0.25s, border-color 0.25s;
  -webkit-appearance: none;

  .abx-input--editing & {
    @extend %abx-input__field--editing;
  }

  &.abx-input__prefix--icon,
  &.abx-input__suffix--icon {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.abx-input__prefix {
  border-right: 0;
}
.abx-input__suffix {
  border-left: 0;
}

/* Actual input */
.abx-input__field {
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding: 4px 8px;
  width: 100%;
  border-radius: 2px;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  background-color: $white-F5;
  border: $field-border-width solid $color-border-gray;
  transition: background-color 0.25s, border-color 0.25s;

  // Active
  .abx-input--editing & {
    @extend %abx-input__field--editing;
  }
  .abx-input--failure & {
    @extend %abx-input__field--failure;
  }
  .abx-input--invalid & {
    @extend %abx-input__field--invalid;
  }
  // Prefixed
  .abx-input__prefix + & {
    padding-left: 0;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  // Suffixed
  .abx-input__suffix + & {
    order: -1;
    padding-right: 0;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // Loading
  &--loading {
    // Remove bottom border so that the loading bar can replace it
    border-bottom: none;
    height: 30px;
  }

  &--select {
    display: flex;
    align-items: center;
  }

  &--light {
    md-select {
      background-color: $white;
      .md-select-icon {
        color: $blue;
      }
    }
  }

  &--no-errors {
    .abx-input__error-container {
      display: none;
    }
  }

  &--hide-selected {
    .abx-input__label-container {
      visibility: visible !important;
    }

    md-select-value {
      span {
        &:not(.md-select-icon) {
          visibility: hidden;
        }
      }
    }
  }

  &--inline-label {
    position: relative;

    .abx-input__label-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      height: 32px;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;

      label {
        font-size: 14px;
        padding: 0 10px;
      }
    }

    div:not(.abx-input--empty) {
      .abx-input__label-container {
        visibility: hidden;
      }
    }

    .abx-input:not(.abx-input--editable) {
      .abx-input__label {
        font-weight: normal;
        font-style: italic;
      }
    }
  }

  // md-select overrides (repeat class for increased specificity)
  md-select-value {
    span {
      &:not(.md-select-icon) {
        min-height: 26px;
        .md-text {
          line-height: 26px;
        }
      }
    }

    &.md-select-value,
    &.md-select-value.md-select-value {
      border-bottom: none;

      // Prevents dotted underline for disabled fields
      background-image: none;
    }
  }
}

/* Loading indicator */
.abx-input__loading-bar {
  height: $field-border-width;

  .md-container.md-mode-indeterminate {
    background-color: $secondary-1;
    height: 100%;
  }
}

// Mixin for styling companion material icon buttons. This should be used with
// a high-specificty selector since angular material rules will override
// otherwise.
@mixin abxInputCompanionButton {
  $iconSize: 24px;
  margin: 0 0 0 6px;
  padding: 0;
  width: $iconSize;
  height: $iconSize;
  min-width: $iconSize;
  min-height: $iconSize;
  border-radius: 0;
  &:not([disabled]):hover {
    background-color: transparent;
  }
  md-icon {
    min-width: $iconSize;
    min-height: $iconSize;
    width: $iconSize;
    height: $iconSize;
  }
}

/* URL Input Container */
.abx-input__url-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // force a height here since the md-icon-buttons are a bit bigger than we want
  height: 32px;
  .abx-input__field {
    flex-grow: 1;

    &.ng-animate {
      transition: 0s none;
      animation: 0s none;
    }
  }

  .url-wrapper__launch-button {
    height: 100%;
    min-height: 0;
    width: 100%;
    padding: 0 12px;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid #ddd;
    text-transform: uppercase;

    .button-content {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
    }

    md-icon {
      color: $color-text;
      font-size: 12px;
      min-height: 0;
      min-width: 0;
      margin-right: 6px;
    }

    &[disabled] {
      background-color: #eee;
    }
  }

  .md-icon-button.edit {
    margin: 0 0 0 6px;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 0;
  }
}

// Placeholder selector to be @extend'ed by input label wrappers
%abx-input-label {
  display: inline-block;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  user-select: none;

  text-align: left;
  pointer-events: none;

  padding: 3px 0 3px 8px;
}

// Input label wrapper
.abx-input__label {
  @extend %abx-input-label;
}

.abx-input__select-option {
  @extend .abx-list-item;
  height: 36px;
}

/* One-off for textareas, since we use md-input-containers for a dynamic height */
.abx-input__textarea-wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0;

  .abx-input__field.md-input {
    // Override md-input styles
    @extend .abx-input__field;
    line-height: 20px;
    border-color: $color-border-gray !important;
    min-height: 5em;
    .abx-input--editing & {
      border-bottom-color: $secondary !important;
    }
    &[disabled] {
      color: $black-87;
    }
  }

  .md-errors-spacer {
    display: none;
  }
}

// -------------------------------------
//   Input Groups
// -------------------------------------
.input-group {
  position: relative;
  display: flex;
  flex-direction: row;
  border-collapse: separate;

  .form-control:first-child {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-btn {
    width: inherit;
    .md-button {
      @extend .flat;
      height: 100%;
      margin: 0;
    }
    &:first-child .md-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child .md-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// -------------------------------------
//   Placeholders
// -------------------------------------
::-webkit-input-placeholder,
:-moz-placeholder,           /* Firefox 18- */
:-moz-placeholder,           /* Firefox 19+ */
:-ms-input-placeholder {
  font-style: italic;
}

// -------------------------------------
//   Select
// -------------------------------------
md-select {
  &:not([disabled]):focus .md-select-value,
  md-select:not([disabled]):focus .md-select-value {
    border-bottom: none;

    // Prevents dotted underline for disabled fields
    background-image: none;
  }
  &.abx-input__field {
    &[disabled] {
      background: $gray-DD;
      border-color: $gray-DD;

      .md-select-value {
        .md-select-icon {
          color: $color-dark-gray;
        }
      }
    }
  }
  .md-select-value {
    .md-select-icon {
      width: auto;
    }
  }
}
.abx-input__select-menu-container {
  &--no-padding {
    md-select-menu {
      md-content {
        padding: 0;
      }
    }
  }
  md-progress-circular {
    padding-top: 64px !important;
  }
  .md-checkbox-enabled[selected] {
    .md-icon {
      background-color: $color-link;
      &::after {
        border-color: $color-white;
      }
    }
  }
  .abx-select-menu-container__options {
    max-height: 256px;
    padding: 0 8px;
    overflow: auto;
  }
}
.abx-select-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  margin-top: -1px;
  z-index: 1;
  background-color: $white;
  cursor: pointer;

  div.abx-select-header__container {
    width: 100%;
    display: flex;
    padding: 8px;
  }

  input.abx-select-header__search {
    border: 1px solid #ddd;
    background: $gray-EF;
    width: 100%;
    height: 32px;
    padding: 0px 31px 0px 4px;
    outline: none;
  }
  md-icon.abx-select-header__search-icon {
    position: absolute;
    top: 12px;
    right: 8px;
    margin: 0 4px;
  }

  hr.abx-select-header__bottom-rule {
    width: 100%;
    margin: 0px;
    border-top-width: 2px;
  }

  md-radio-group.abx-select-header__radio-group {
  }
}

.abx-input__empty-search-placeholder {
  @extend .text-placeholder;
  padding: 8px 16px;
}

.abx-select-loading-indicator {
  position: fixed;
  top: 43px;
  left: 0;
  z-index: 2;
}

md-optgroup.abx-select__group {
  label {
    padding: 8px 16px;
  }
}

.abx-currency-field-container {
  position: relative;

  .abx-input__field {
    padding-left: 16px;
  }

  .abx-currency-symbol {
    display: block;
    position: absolute;
    height: 32px;
    line-height: 32px;
    top: 0;
    left: 0;
    padding: 0 8px;
    z-index: 2;
    pointer-events: none;
  }
}

.abx-hours-minutes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .abx-input__field {
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 1em);
  }
}
