abx-inspection-program-instruction {
  $content-width: 306px;

  .abx-inspection-program-instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;

    right: -$content-width;
    transition: right 0.3s;

    &.open {
      right: 0;
    }
  }

  .abx-inspection-program-instruction-btn {
    height: 40px;
    min-width: 44px;
    border-radius: 0;
    margin: 0;
    background-color: $white !important; // override hover transparency on buttons
    position: absolute;
    top: 0px;
    right: $content-width;

    .abx-lightbulb-icon {
      color: $color-link;
      position: absolute;
      left: 10px;
      bottom: 8px;
    }

    .abx-close-arrow-icon {
      position: absolute;
      left: 6px;
      bottom: 4px;
      color: $color-link;
    }

    &[disabled] {
      cursor: not-allowed;
      border-bottom-color: $color-medium-gray;

      md-icon {
        &.abx-location {
          color: $color-medium-gray;
        }
      }
    }
  }

  .abx-inspection-program-instruction-header-and-list {
    width: $content-width;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    .abx-inspection-program-instruction-header {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: $white;
      justify-content: space-between;
      padding: 8px 0;

      .abx-inspection-program-instruction-header__text {
        padding-left: 16px;
        font-size: 24px;
        white-space: normal;
      }
    }

    .abx-inspection-program-instruction-list {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 8px 0 8px 16px;
    }
  }
}
