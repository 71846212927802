.abx-org-work-order-list-container {
  @extend .abx-org-list-container;
}

.filter-bar-container {
  display: flex;
  flex-direction: row;

  & > abx-managed-filter-bar {
    flex: 1 1 auto;
  }
}

.filter-bar {
  flex: 1 1 auto;
}

.calendar-switch {
  flex: 0;
  margin: 0px 16px;
  min-width: 200px;
}

.calendar {
  margin: 24px;
  z-index: 3;
}
