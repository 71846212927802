abx-condition-input {
  .assembly-condition {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &__label {
      @extend %abx-input-label;
    }
    &__value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button.abx-button {
        min-height: 18px;
        min-width: 60px;
        margin: 0;
        padding: 2px 4px;
      }
    }
  }

  .condition-grade {
    padding-left: 8px;

    &__text {
      font-size: 14px;
    }
    &__date {
      font-size: 12px;
      font-style: italic;
      color: $color-dark-gray;
    }
    &--unknown {
      @extend .text-placeholder;
    }
  }
}
