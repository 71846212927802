.abx-pagination {
  position: relative;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
}
.abx-pagination-button.md-button.md-icon-button {
  margin: 0;
}
.abx-pagination-select {
  margin: 24px 12px;
  .md-select-value {
    min-width: 40px;
  }
}
.abx-pagination-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.8);
}
