.asset-cost-adjustment-dialog {
  h2 {
    font-weight: normal;
    font-size: 16px;
    font-family: "Open Sans";
    margin: 8px;
  }

  .md-toolbar-tools--error {
    margin-top: 4px;
    background-color: #f6cdd2;
    color: $color-error;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    h2 {
      font-size: 14px;
    }

    .btn-link {
      margin: 0;
    }

    .retry-btn-container {
      display: flex;
      align-items: center;
      font-style: normal;
    }
  }

  .asset-cost-adjustment-dialog__add {
    margin-bottom: 20px;

    form[name="add"] {
      display: flex;

      .asset-cost-adjustment-dialog__add_form_wrapper {
        background-color: $color-light-gray;
        display: flex;
        flex-direction: row;
        padding: 10px 5px 0px 5px;
        gap: 10px;
        flex: 1 0 auto;

        input {
          height: 40px;
          background-color: white;
        }

        md-select {
          height: 40px;
          background-color: white;

          md-select-value {
            min-width: 38px;
          }
        }

        .description {
          flex: 1;
        }

        .adjustment_field {
          display: flex;
          align-items: baseline;
          flex-basis: 10%;
          gap: 10px;
          flex-basis: 20%;
          align-items: unset;

          @media screen and (max-width: 1705px) {
            flex-basis: 30%;
          }

          label {
            font-size: 18px;
            font-weight: 600;
          }
        }

        .add {
          height: 40px;
          margin: 0;
          padding: 0;
          border: none;
        }
      }
    }
  }

  .cost-adjustments-list-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    > span {
      font-size: 12px;
      font-style: italic;
      padding-bottom: 2px;
    }
  }

  .icon-warning-container {
    display: flex;
  }

  .icon-warning-container__icon-warning {
    font-size: 16px;
    color: $color-graph-8;
  }

  .icon-warning-container__tooltip-warning {
    font-size: 12px;
    width: 185px;
    height: auto !important;
    overflow: visible !important;
    white-space: normal !important;
    text-align: center !important;
    line-height: 15px;
    padding: 16px 8px;
  }

  .cost-adjustment-header {
    md-toolbar.abx-list-header,
    md-toolbar.abx-batch-actions {
      height: 30px;
      max-height: 30px;
      min-height: 30px;
      background: #d8d8d8;
      border-bottom: none !important;
    }

    md-content.abx-list {
      padding-bottom: 5px;
    }

    font-size: 12px;

    .list-header > div {
      color: $color-text;
      text-transform: none;
      font-weight: 600;
      font-size: 12px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
    }

    .item-description {
      font-style: italic;
    }

    .preloader-list-item-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 8px;
    }

    .preloader-item-rectangle-large {
      @extend %abx-field-preloader;
      @extend .preloader--pulse;
      animation-name: pulse-darken;
      border-radius: 5px;
      margin-right: 12px;
      height: 20px;
      width: 250px;
    }

    .preloader-item-rectangle-small {
      @extend %abx-field-preloader;
      @extend .preloader--pulse;
      animation-name: pulse-darken;
      border-radius: 5px;
      margin-right: 12px;
      height: 20px;
      width: 35px;
    }

    .preloader-last-item {
      margin-bottom: 5px;
    }
  }

  abx-list md-content.abx-list abx-list-item:last-of-type {
    margin-bottom: 10px !important;
  }

  .cost-adjustment-footer {
    display: flex;
    height: 37px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 600;

    label {
      margin-bottom: 0px;
      justify-content: center;
    }
  }

  .cost-adjustment-footer-total-cost {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(45, 141, 217, 0.25);
    height: 37px;
    white-space: nowrap;
  }
}

.tooltip-description {
  height: auto !important;
  max-width: 250px !important;
  overflow: visible !important;
  white-space: normal !important;
}

.list-title-and-icon-warning {
  display: flex;
  align-items: center;

  h2 {
    display: inline;
    margin-right: 8px;
  }
}

.icon-warning-container {
  display: flex;

  &__icon-warning {
    font-size: 16px;
    color: $color-graph-8;
  }

  &__tooltip-warning {
    font-size: 12px;
    width: 185px;
    height: auto !important;
    overflow: visible !important;
    white-space: normal !important;
    text-align: center !important;
    line-height: 15px;
    padding: 16px 8px;
  }
}

.cost-adjustment-table {
  &__description-cell,
  &__adjustment-percent-cell {
    .abx-input__error-container {
      display: none;
    }

    .abx-input__field {
      height: 40px;
    }
  }

  &__scope-cell {
    flex: 24 0;
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 0.65rem;
    &__icon {
      display: inline-flex;
      .material-icons {
        font-size: 22px;
        color: $color-medium-gray;
      }
    }
    &__title {
      display: inline-flex;
      flex-direction: column;
    }
    .scope-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .scope-rule {
      font-weight: bold;
      font-style: normal;
    }
    .scope-building {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__description-cell {
    flex: 60 60;
  }

  &__adjustment-percent-cell {
    flex: 15 0;
  }

  &__right-side {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 224px;
    align-items: center;
  }

  &__updated-cost-cell {
    flex-grow: 1;
  }

  &__action-cell {
    display: flex;
    justify-content: space-between;
    flex-basis: 64px;
  }

  &__cancel-save-container {
    button {
      margin-top: 3px;
      margin-bottom: 4px;

      &.save {
        padding: 0;
      }

      &.cancel {
        &:disabled,
        &:disabled:hover,
        &:disabled:focus,
        &:disabled:active {
          @extend .btn-disabled;
        }
      }
    }
  }

  &__saved-icon {
    md-icon {
      color: #4ca54c;
    }
  }
}

// This is for adding high-specificity to cost adjustment table list items.
#{repeatString(".cost-adjustment-table__list-item", 5)} {
  align-items: center;
  font-style: italic;
  padding: 0;
}

// The cost adjustment table list items have a similar padding.
// This is to make sure the footer aligns with the rest of the
// table content.
.cost-adjustment-footer {
  > * {
    padding-right: 8px;
  }
}

md-dialog.abx-dialog.delete-cost-adjustment-dialog {
  min-width: 30%;
}
