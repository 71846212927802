abx-job {
  md-toolbar {
    md-tabs {
      margin-left: -16px;
      height: 64px;

      md-tabs-wrapper,
      md-tabs-canvas,
      md-pagination-wrapper {
        height: 100%;
      }

      md-tab-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .md-tab {
        margin: 0;
        font-size: 18px;
        text-transform: none;
      }
    }
  }
}

.processing-step {
  min-height: 112px;
  abx-step {
    &.ng-enter {
      animation: fadeIn 0.5s;
    }
    &.ng-leave {
      animation: fadeOut 0.2s;
    }
  }
}

.job-error {
  margin: 12px 0;
  > span {
    padding: 0 16px;
  }
}

.job-detail__list-header {
  @extend .elevation-frame-bot-2dp;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white;

  md-tabs {
    flex: 1;
  }

  md-input-container {
    margin: 0;
  }

  .md-tab {
    margin: 0;
    font-size: 18px;
    text-transform: none;
  }
}

.job-detail__error-log {
  background-color: $white;

  md-content {
    background-color: $white;
  }
}
