abx-schedule-list {
  .schedule-location {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;

      i.material-icons {
        margin-right: 4px;
        color: $color-dark-gray;
      }
    }
  }
  .schedule-assignees {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 4px !important;

    i {
      font-size: 18px;
      color: $color-dark-gray;
    }

    &__unassigned {
      height: 100%;
      display: flex;
      font-style: italic;
      color: $color-dark-gray;
    }

    &__preview {
      height: 100%;
      max-width: calc(100% - 51px);

      &.full-preview {
        max-width: 100%;
      }

      div {
        display: flex;
      }
    }

    &__chip {
      padding-left: 5px;
      min-width: 46px;
    }
  }
}

.maintenance-schedule-list__assignees {
  display: flex;
  align-items: center;

  span {
    display: block;
  }

  &__preview {
    height: 100%;
    max-width: calc(100% - 51px);
  }

  &__chip {
    padding-left: 5px;
    min-width: 46px;
  }
}

.md-tooltip.multiline {
  height: auto !important;
}
