abx-location-map {
  md-card {
    align-items: center;
    overflow: hidden;
  }

  md-card-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: $color-link;
    padding: 4px;
    text-transform: uppercase;
    color: $color-white;
    font-weight: bold;

    &.inspection {
      background-color: $color-primary;
      color: $color-black;
    }

    span {
      padding-left: 4px;
    }
  }
}
