abx-wo-quick-actions {
  display: block;

  .wo-quick-actions {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    background-color: #eeeeee;
    padding: 8px 24px;

    button.md-button {
      padding: 8px 12px;
      min-width: 128px;
      min-height: 0;

      @include respond-to(gt-sm) {
        min-width: 88px;
      }
    }

    .icon-button {
      height: 32px;
      margin: 0 8px;
    }

    .wo-assign-section {
      display: flex;
      flex: 1 100%;
      align-items: center;

      max-height: 64px;

      @include respond-to(gt-sm) {
        flex: 1 auto;
        margin: 8px 0 16px;
      }

      .wo-assign-input {
        flex: 1 auto;
        abx-input {
          input.abx-input__field {
            background-color: $white;
            border: 1px solid $gray-DD;
          }
        }
      }

      .wo-assign-button {
        flex: 0 auto;
        button.md-button {
          margin-top: 14px;
        }
      }
    }

    .wo-room-asset-section {
      flex: 1 100%;
      display: flex;
      justify-content: center;

      margin-top: 16px;

      @include respond-to(gt-sm) {
        display: none;
      }

      & > span {
        flex: 0 auto;
        margin: 0 8px;
      }
    }

    .wo-buttons-section {
      display: flex;
      flex: 1 100%;
      flex-wrap: wrap;

      align-items: center;
      justify-content: center;

      margin-top: 16px;

      .wo-primary-actions,
      .wo-secondary-actions {
        display: flex;
      }

      @include respond-to(gt-sm) {
        flex: 2 auto;
        justify-content: flex-end;

        margin-top: 0;

        .wo-primary-actions {
          flex: none;
        }
      }

      button.md-button {
        flex: 0 auto;

        span {
          white-space: nowrap;
        }
      }
    }

    .wo-buttons-preloader {
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      margin-top: 16px;

      .wo-buttons-preloader__row {
        display: flex;
      }

      .preloader-button {
        min-width: 128px;
      }

      @include respond-to(gt-sm) {
        flex-wrap: nowrap;
        flex: 2 auto;
        justify-content: flex-end;

        margin-top: 0px;

        .preloader-button {
          min-width: 104px;
        }
      }
    }
  }
}
