abx-side-bar {
  display: none;
  flex-direction: column;
  background-color: $white;
  @extend .elevation-frame-6dp;

  .organization-thumbnail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: 176px;
    min-height: 176px;
    margin: 2px;
    background-color: lighten($color-link, 30%);
    border-radius: 50%;

    &__placeholder {
      text-align: center;
      color: $color-link;
      font-size: 128px;
      margin: 0;
    }
  }

  .abx-side-bar__thumbnail {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 192px;
    margin: 16px 0;

    .abx-side-bar__fit-options {
      display: none;
      position: absolute;
      top: 8px;
      right: 0;
    }

    &:hover {
      .abx-side-bar__fit-options {
        display: block;
      }
    }
  }

  .abx-side-bar__footer {
    padding: 8px 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-image-sm {
      display: none;
    }

    &-text {
      color: #cccccc;
      font-size: 7px;
      text-align: center;
    }
  }

  @include respond-to("sm") {
    display: flex;
    width: 64px;
    min-width: 64px;
    max-width: 64px;

    .abx-side-bar__thumbnail {
      display: none;
    }
    abx-side-menu {
      .side-menu-section {
        .side-menu-item {
          display: flex;
          justify-content: center;
          a {
            padding: 16px 0;
          }
        }
        .side-menu-section__name,
        .side-menu-item-child__name {
          display: none;
        }
        .side-menu-item-child {
          a {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .abx-side-bar__footer {
      &-image {
        display: none;
      }

      &-image-sm {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        img {
          width: 50px;
        }
      }

      &-text {
        display: none;
      }
    }
  }

  .abx-branding-primary-logo {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 16px;

    img {
      width: 70%;
    }
  }

  @include respond-to("gt-sm") {
    display: flex;
    width: 240px;
    min-width: 240px;
    max-width: 240px;
  }
}

.abx-side-bar__fit-select {
  label {
    padding: 0 12px;
    font-size: 12px;
    color: $color-dark-gray;
  }
  md-menu-item button.md-button {
    &.active,
    &.active:hover {
      background: $secondary-3;
    }
  }
}
