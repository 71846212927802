.abx-pin-type-subfilter-sidebar {
  abx-sidebar-header {
    flex-shrink: 0;
  }

  &__header-content {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  }

  &__pin-type {
    margin: 0 0 8px;
  }

  &__pin-type-name {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 6px 0 4px;
    line-height: 1.2;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px;

    .md-button {
      &:first-child {
        margin-right: 5px;
      }
      margin: 0;
      flex: 1;
    }
  }

  &__subfilters {
    @extend .abx-chips;
    padding: 0 8px;
  }

  &__subfilter {
    @extend .abx-chip;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 16px 8px 0;
    &--value {
      display: inline;
    }
  }

  &__subfilterValue {
    display: inline-block;
  }

  &__content {
    padding: 8px 16px;
  }
}
