.abx-plan-view__content {
  // Fixes a bug preventing PV from rendering on iPads.
  // 64px is the height of the header-switcher.
  height: calc(100vh - 64px);
}

abx-plan-view-app .redirect-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 3px;

  * {
    height: 3px;
  }

  &.ng-hide-add {
    animation: fadeOutUp 1s;
    -webkit-animation: fadeOutUp 1s;
  }
  &.ng-hide-remove.ng-hide-remove-active {
    animation: fadeInDown 0.3s;
    -webkit-animation: fadeInDown 0.3s;
  }
}
