abx-y-axis-switcher {
  display: flex;
  max-width: 34px;
  min-height: 172px;

  .y-axis-switcher__input-container {
    display: flex;
    flex: 1;
    height: 34px;
    min-width: 192px;
    min-height: 100%;
    transform: rotate(90deg);
    transform-origin: top left;

    abx-input {
      display: flex;
      flex: 1;
      transform: rotate(180deg);
      transform-origin: top;

      .abx-input {
        flex: 1;
      }

      md-select {
        border-radius: 2px;
      }
    }
  }
}

.abx-y-axis-switcher__select-menu-container {
  margin-left: 44px;
}
