// -------------------------------------
//   Padding
// -------------------------------------
.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-bot {
  padding-bottom: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

$i: 2;
@while $i < 257 {
  .pad-#{$i} {
    padding: #{$i}px;
  }
  .pad-#{$i}-top {
    padding-top: #{$i}px;
  }
  .pad-#{$i}-right {
    padding-right: #{$i}px;
  }
  .pad-#{$i}-bot {
    padding-bottom: #{$i}px;
  }
  .pad-#{$i}-left {
    padding-left: #{$i}px;
  }

  // Increment iterator
  $i: $i + 2;
}
