abx-tags {
  span.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 5px 3px 0;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    color: $black-87;
    background-color: $color-light-gray;
    border-radius: 0;

    &.tag-hover:hover {
      cursor: pointer;
    }
    &.no-hover {
      cursor: inherit;
    }

    i {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
}
