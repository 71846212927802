.asset-overview {
  .floor-plan-preview {
    overflow: hidden;
  }

  .asset-location-header {
    display: flex;
    align-items: center;

    header {
      font-size: 18px;
      font-weight: 400;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .asset-location-header__button {
      margin-left: auto;
    }
  }

  .asset-location-card {
    margin-top: 8px;
  }

  .property {
    padding: 3px;
    margin-bottom: 18px;
    font-size: 16px;

    &__label {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__name {
      padding-left: 2px;
    }
  }
}
