abx-job {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: stretch;
  min-height: 116px !important;
  padding: 16px;
  background-color: $white;

  .success {
    color: $green-5C;
  }

  .error {
    color: $red-FF;
  }

  .canceled {
    color: $gray-light;
  }

  .pending {
    color: $color-medium-gray;
  }

  .no-changes {
    color: $color-dark-gray;
  }

  .job-status {
    min-width: 88px;
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    padding-right: 16px;

    .job-status-text {
      margin-top: 8px;
      font-size: 12px;
    }
  }

  .job-content {
    .job-title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 2em;
        height: 100%;
        background: linear-gradient(
          -90deg,
          #fff 0,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    .job-info {
      position: relative;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;

      .job-info-col {
        margin-right: 16px;
        > * {
          position: relative;
          padding-bottom: 6px;
          overflow: hidden;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 2em;
            height: 100%;
            background: linear-gradient(
              -90deg,
              #fff 0,
              rgba(255, 255, 255, 0) 100%
            );
          }
        }
        md-icon {
          margin: 4px 8px 0 0;
        }
      }
    }
  }

  .job-actions {
    min-width: 48px;
  }

  &.job-header {
    min-height: 128px !important;
    .job-status {
      cursor: inherit;
      md-icon {
        @extend .md-48;
      }
    }
    .job-content {
      .job-title {
        cursor: inherit;
        font-size: 24px;
        .preloader-line {
          height: 32px;
        }
      }
    }
  }
}
