// -------------------------------------
//   Icons
// -------------------------------------
i,
img,
md-icon {
  $i: 2;
  @while $i < 257 {
    &.md-#{$i} {
      font-size: #{$i}px;
      height: #{$i}px;
      width: #{$i}px;
      min-height: #{$i}px;
      min-width: #{$i}px;
      max-height: #{$i}px;
      max-width: #{$i}px;
      line-height: #{$i}px;
    }
    // Increment iterator
    $i: $i + 2;
  }
  &.md-dark {
    color: rgba(0, 0, 0, 0.54) !important;
    fill: rgba(0, 0, 0, 0.54) !important;
  }
  &.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26) !important;
    fill: rgba(0, 0, 0, 0.26) !important;
  }
  &.md-light {
    color: rgba(255, 255, 255, 1) !important;
    fill: rgba(255, 255, 255, 1) !important;
  }
  &.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.26) !important;
    fill: rgba(255, 255, 255, 0.26) !important;
  }
}

.preventive-icon {
  background: url("../../img/preventive-icon.svg");
}
.reactive-icon {
  background: url("../../img/reactive-icon.svg");
}
