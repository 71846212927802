abx-donut-chart {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 305px;
  padding-top: 2px;

  @include respond-to(gt-sm) {
    flex-direction: row;
    align-items: flex-start;
    .legend {
      margin: 32px 24px;
    }
  }

  &.abx-no-click {
    .legend {
      .legend-value {
        &:hover {
          cursor: default;
        }
        .valueText {
          color: $black-87;
        }
      }
    }
  }

  .value-text {
    @extend .text-bold;
    font-size: 40px;
  }

  .name-text {
    @extend .text-capitalize;
    font-size: 12px;
    max-width: 10px;
  }

  .legend {
    display: flex;
    width: 100%;
    max-height: 180px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;

    @include respond-to(gt-md) {
      width: 70%;
      max-height: 120px;
    }

    .legend-value-container {
      display: flex;
      align-items: center;
      padding: 8px 0;
    }

    .legend-value {
      display: flex;
      width: 50%;
      height: 30px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0 8x;

      @include respond-to(gt-md) {
        width: 33%;
      }

      @include respond-to(gt-lg) {
        width: 25%;
      }

      &:hover {
        cursor: pointer;
      }

      .legend-value-container {
        svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
        }

        .value-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 8px;
          white-space: nowrap;
          color: #2d8dd9;
          font-size: 12px;
          @extend .text-capitalize;

          @include respond-to(gt-xs) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
