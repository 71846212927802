abx-service-request-list {
  .request-location {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;

      i.material-icons {
        margin-right: 4px;
        color: $color-dark-gray;
      }
    }
  }
}

.abx-request-list__container {
  flex: 1;
  max-height: 100%;
  overflow: auto;

  .request-subject {
    word-break: break-all;
  }
}
