.login-content {
  .banner-message {
    font-size: 12px;
    text-align: center;
    md-icon {
      color: $color-error;
    }
    span p {
      display: inline;
    }
  }

  abx-input {
    margin: 8px 0;
    .abx-input__error-container {
      display: none;
    }
  }
  button.abx-button {
    margin: 6px 0; // remove side margins
  }
  .auth-error {
    margin: 16px 0;
  }
  .md-caption {
    padding: 0 4px;
  }
  .email-form,
  .password-form {
    margin-top: 8px;
  }
  .next-button,
  .submit-button {
    margin-top: 8px;
  }
}
