abx-bldg-thumbnail {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: transparent;
  box-sizing: border-box;

  .preloader-block,
  .thumbnail-container {
    @extend .elevation-frame-2dp;
    z-index: inherit;
  }

  .preloader {
    position: absolute;
  }

  .thumbnail-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;
    z-index: 1;

    &.cannotUpdate {
      cursor: default;
    }

    &:hover {
      @extend .elevation-frame-3dp;
      z-index: inherit;
      .building-thumbnail {
        .building-thumbnail__edit-text {
          visibility: visible;
        }
      }
    }

    .building-thumbnail {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;

      &__icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: lighten($color-link, 30%);
        color: $color-link;
        font-size: 128px;
      }

      &__edit-text {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: $white;
        background-color: $black-26;
        border-radius: 50%;
        visibility: hidden;
      }
    }
  }
}
