abx-round-stop-item {
  display: block;
  border-bottom: 1px solid $color-border-gray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;

  .abx-stop-item {
    background-color: $color-white;
    height: 100%;
    overflow: hidden;

    .abx-stop-header {
      width: 100%;
      min-height: 36px;
      padding: 8px 0 8px 16px;
      display: flex;
      align-items: center;
      border-bottom: 3px solid $color-link;
      margin-bottom: 8px;

      .abx-stop-info {
        &__icon {
          abx-pin-type {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 16px;
            .abx-pin-type-icon-wrap {
              margin: 0;
            }
          }
          .floor-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            margin-right: 16px;
            border-radius: 50%;
          }
        }
        &__info {
          overflow: hidden;
        }
      }

      &__action-buttons {
        margin-left: auto;
        color: $color-link;
        margin-right: 6px;
        min-width: 96px;

        .abx-stop-header__action-buttons__add-btn-wrapper {
          display: inline-block;
          position: relative;

          .md-icon-button {
            z-index: 12;
            margin: 0;
          }

          abx-simple-dropdown {
            position: absolute;
            top: 50%;
            right: 50%;
            border: 1px solid $color-border-gray;

            .abx-simple-dropdown,
            .abx-simple-dropdown__list {
              width: 225px;
              min-width: 225px;
            }

            .abx-simple-dropdown__list__item__icon-button span {
              font-weight: 600;
              text-transform: uppercase;
            }
          }
        }
      }

      .abx-stop-info {
        display: flex;
        flex-direction: row;
        min-width: 30%;
        &__info {
          .abx-stop-title,
          .abx-stop-subtitle {
            text-transform: initial;
          }
          .abx-stop-title {
            font-size: 14px;
            color: $color-link;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .abx-stop-subtitle {
            font-size: 12px;
            color: $color-dark-gray;
            font-weight: normal;
          }
          .abx-stop-indicator {
            margin-left: 4px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .header-column {
        font-size: 12px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__assignee {
          width: 30%;
        }

        &__date {
          width: 30%;
        }

        &__result {
          width: 10%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;

          .header-label {
            margin-right: 8px;
          }

          .status-passed {
            color: $color-pass;
          }

          .status-incomplete {
            color: $color-medium-gray;
          }

          .status-failed {
            color: $color-fail;
          }
        }
      }
    }

    .abx-stop-content {
      position: absolute;
      /* Needs adjustment if header height gets adjusted */
      top: 59px;
      padding-top: 8px;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;

      &__container {
        width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        margin: 0 8px;
      }

      .abx-stop-reopen-button {
        display: flex;
        justify-content: flex-end;
        width: 90%;
        margin: 0 auto 8px;
        border-bottom: 1px solid $color-border-gray;
      }

      .abx-stop-item-associated-items {
        padding: 16px 8px;
        .abx-stop-item {
          &__message-list,
          &__skip-reason-list,
          &__work-order-list,
          &__attachment-list {
            margin: 4px 0;
            padding: 8px 0;
            &__header .abx-stop-item {
              &__message-list,
              &__skip-reason-list,
              &__work-order-list {
                &__label {
                  font-size: 14px;
                  text-transform: uppercase;
                  font-weight: bold;
                }
              }
            }
            &__loading-spinner {
              margin: 1em auto;
            }
            &__placeholder-text {
              font-size: 16px;
              font-style: italic;
              color: $color-medium-gray;
              padding: 4px 0 8px 16px;
            }

            &__add-button,
            &__edit-button,
            &__delete-button {
              width: 72px;
              min-width: 72px;
              height: 20px;
              min-height: 20px;
              padding: 0;
              margin: 0;
            }

            &__edit-button,
            &__delete-button {
              margin: 8px 0;
              background: transparent !important;
            }

            &__delete-button {
              border: none !important;
              box-shadow: none !important;
            }

            &__show-all-btn {
              margin: 0 auto;
            }

            &__item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.2em 0.6em 12px;
              margin-bottom: 8px;

              &:not(:last-of-type) {
                border-bottom: 1px solid $color-border-gray;
              }

              .material-icons {
                color: $color-dark-gray;
                margin-right: 12px;
              }

              .work-order-container {
                width: 100%;
              }

              &__content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &__work-order-info {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }

                &__text {
                  font-size: 12px;
                  span:first-child {
                    font-weight: bold;
                  }
                  &__message {
                    font-style: italic;
                  }
                }

                &__info {
                  font-size: 12px;
                  &__name {
                    font-weight: bold;
                  }

                  &__edit-link {
                    min-width: 25%;
                    max-width: 25%;
                    padding: 18px;
                  }
                }

                &__status {
                  min-width: 25%;
                  max-width: 25%;
                  padding: 16px;
                  border-left: 1px solid $color-border-gray;
                }
              }

              &__date {
                font-size: 10px;
                color: $color-dark-gray;
                line-height: 14px;
              }

              .abx-stop-item__message-button-container {
                padding-left: 3px;
                @include respond-to(gt-sm) {
                  visibility: hidden;
                }
              }

              &:hover {
                .abx-stop-item__message-button-container {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
      .abx-stop-complete {
        border-top: 1px solid $color-border-gray;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        .abx-stop-complete-text {
          font-size: 12px;
          font-style: italic;
          color: $color-dark-gray;
        }

        &.skippable {
          flex-wrap: wrap;
          justify-content: space-evenly;

          & > * {
            flex: 1;
          }

          .abx-stop-complete-text {
            flex-basis: 100%;
            text-align: center;
          }
        }
      }

      .abx-round-stop-item-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 16px);
        height: 100%;
        background: white;

        .abx-round-stop-item-preloader__question {
          width: 100%;
          margin: 8px 0 16px;
          padding: 0 24px;
        }
        .abx-round-stop-item-preloader-answers {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  .abx-pin-type-wrap {
    position: relative;

    &.pass {
      &::after {
        position: absolute;
        font-family: "Material Icons";
        content: "\e876";
        color: white;
        background-color: $color-success;
        text-align: center;
        display: block;
        left: 12px;
        top: -4px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        border-radius: 50%;
        line-height: 13px;
        border: 1.5px solid white;
      }
    }
    &.fail {
      &::before {
        position: absolute;
        font-family: "Material Icons";
        content: "\e14c";
        color: white;
        background-color: $color-error;
        text-align: center;
        display: block;
        left: -4px;
        top: -4px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        border-radius: 50%;
        line-height: 13px;
        border: 1.5px solid white;
      }
    }

    &.skip {
      &::after {
        content: "";
        display: block;
        height: 120%;
        background-color: $color-dark-gray;
        border: 1px solid white;
        width: 4px;
        transform: rotate(45deg);
        position: absolute;
        left: 9px;
        top: -3px;
      }
    }
  }
}
