.external-wo-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;

  &__save-cancel {
    display: flex;
    flex-direction: row;
  }
}
