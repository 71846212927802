abx-header-list {
  display: block;

  md-list.abx-header-list__list {
    flex: 1;
    background-color: $white;
    padding: unset !important;
  }

  md-list-item.abx-header-list-item {
    // Angular Material theming doesn't support what we want here, so
    // override their colors
    background-color: $white;

    &:hover,
    &.abx-selected {
      background-color: $secondary-1;
    }
    &.abx-selected:hover {
      background-color: $secondary-3;
    }
    md-icon {
      color: $blue;
    }
  }
}
