// -------------------------------------
//   Cards
// -------------------------------------

// Usage:
//  Card class comes with several modifiers
//      .card                       Simple card
//      .card-{n}dp                 Card with elevation
//      .card-{n}dp .no-padding     Card with elevation but no padding
//
//      * Where {n} is an integer constraint via min-dp and max-dp constants

.card {
  border-radius: 4px;
  padding: 16px;
  margin: 8px;
  background-color: $white;

  .card-header {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 30px;
    background: $white-F9;
    border-bottom: 1px solid $divider-light;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .card-header-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @extend .text-uppercase;
      font-weight: 400;
    }

    .card-action {
      height: 24px;
      min-height: 24px;
      max-height: 24px;
    }
  }

  .card-subheader {
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
    border-bottom: 1px solid $blue;

    .card-subheader-title {
      font-weight: bold;
      text-transform: uppercase;
    }
    .card-subheader-subtitle {
      text-transform: none;
      font-weight: 500;
    }
  }

  .card-content {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px;
  }

  &.no-padding {
    padding: 0px !important;
  }

  &.bottom-border {
    border-bottom: 4px solid $secondary;
  }
}

@for $i from $min-dp through $max-dp {
  .card-#{$i}dp {
    @extend .elevation-frame-#{$i}dp;
    @extend .card;
  }
}
