abx-range-input {
  display: block;
  .abx-range-input {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }
    &__label {
      @extend %abx-input-label;
      width: auto;
      border: 0;
    }
    &__separator {
      display: none;
      flex: 1 1 5%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: baseline;
      height: 32px;
      min-width: 5%;
      margin: 0;
      color: $color-medium-gray;
      cursor: default;
      .abx-input-separator {
        display: inline-block;
        height: 2px;
        width: 10px;
        background-color: $color-medium-gray;
      }
    }
    &__start,
    &__end {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }
    &__start {
      margin-bottom: 8px;
    }
    &--with-labels {
      .abx-range-input__separator {
        margin-top: 24px; // label height
      }
    }

    @include respond-to(gt-xs) {
      &__wrapper {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
      &__start {
        margin-right: 8px;
        margin-bottom: 0;
      }
      &__end {
        margin-left: 8px;
      }
      &__separator {
        display: flex;
      }
    }
  }
}
