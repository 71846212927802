.abx-schedule-item {
  font-weight: bold;
  align-items: center;
  color: $blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
}

.md-dialog-content {
  a:hover {
    text-decoration: none !important;
  }
}
