// -------------------------------------
//   Margin
// -------------------------------------
.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-bot {
  margin-bottom: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

$i: 2;
@while $i < 257 {
  .m-#{$i} {
    margin: #{$i}px;
  }
  .m-#{$i}-top {
    margin-top: #{$i}px;
  }
  .m-#{$i}-right {
    margin-right: #{$i}px;
  }
  .m-#{$i}-bot {
    margin-bottom: #{$i}px;
  }
  .m-#{$i}-left {
    margin-left: #{$i}px;
  }

  // Increment iterator
  $i: $i + 2;
}
