abx-collapsible-card {
  display: block;

  .abx-collapsible-card {
    @extend .card-2dp;
    @extend .bottom-border;
    margin: 16px 8px;
    padding: 0;
  }

  header {
    cursor: pointer;
    padding: 8px 8px 8px 16px;
    position: relative;
  }
  section {
    position: relative;
    transition: height 0.35s ease;
    overflow: hidden;
  }
  abx-collapsible-card-header {
    display: block;
  }

  abx-collapsible-card-content {
    display: block;
    padding: 16px;
  }
}
