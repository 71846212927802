abx-cost-division-selection-tree {
  display: block;
  width: 33%;
  min-width: 33%;
  padding-right: 5px;

  .abx-search-cost-division {
    position: relative;

    abx-input {
      height: 52px;
      padding-left: 5px;
      padding-right: 5px;

      input {
        @extend .abx-input__field;

        height: 40px;
        padding-left: 16px;
        padding-right: 40px;
      }

      label {
        font-weight: 600;
        color: $color-medium-gray;
      }
    }

    &__icon {
      color: $blue;
      font-size: 20px;
      position: absolute;
      right: 16px;
      top: 11px;
    }
  }

  .abx-result-cost-division {
    border-top: 2px solid $color-link;

    md-list {
      padding-bottom: 0;
      padding-top: 0;

      .list-item {
        border-bottom: 1px solid $color-border-gray;

        .md-button {
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: transparent;
          transition: none;
          // &:hover,
          // &.md-focused {
          //   background-color: transparent;

          //   & .md-ripple-container {
          //     display: none;
          //   }
          // }
        }

        &__icon {
          color: $color-link;
          font-size: 20px;
          margin-left: 0;
          margin-right: 0;
          pointer-events: none;
          &:first-child:not(.md-avatar-icon) {
            margin-right: 0;
          }
        }
        &__division-code {
          align-items: center;
          color: $color-text;
          background-color: $color-light-gray;
          border-radius: 50%;
          display: flex;
          font-weight: 600;
          justify-content: center;
          min-width: 25px;
          min-height: 25px;
          line-height: 25px;
        }
        &__dash {
          display: none;
          height: 7px;
          line-height: 4px;
          text-align: center;
          width: 12px;
          margin-left: 4px;
          margin-right: 4px;
        }
        &__title {
          flex: 1;
          font-size: 14px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__level2,
        &__level3,
        &__level4 {
          height: 30px;
          min-height: 30px;

          .md-button {
            height: 30px;
            min-height: 30px;
            padding-left: 28px;
          }

          .md-list-item-inner {
            height: 30px;
            min-height: 30px;
          }

          .list-item__division-code {
            font-size: 12px;
            background-color: $white;
          }

          .list-item__title {
            color: $color-link;
            font-size: 12px;
            margin-left: 0;
          }

          .list-item__dash {
            display: block;
          }
        }

        &__level1 {
          md-icon {
            margin-top: 4px;
          }
          .list-item__title {
            padding-left: 8px;
            color: $color-link;
          }
        }

        &__level3 {
          .list-item__icon {
            font-size: 12px;
            text-align: center;
          }
        }

        &__level4 {
          .list-item__icon {
            display: none;
          }
          .list-item__title {
            color: $color-text;
          }
          .md-button {
            padding-left: 52px;
          }
        }

        &.selected .list-item__icon {
          transform: rotate(90deg);
        }
      }
    }

    .loader {
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}

md-dialog {
  width: 50%;
  min-width: 50%;
}
