abx-cost-line-creation-section {
  display: block;

  .cost-line-creation-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__search-area {
      display: flex;
      column-gap: 20px;
    }

    &__search-field {
      min-width: 250px;
      margin-top: 23px;
    }

    &__organization-input {
      min-width: 200px;
    }

    &__uniformat-field {
      margin-right: 24px;
    }

    &__description-field {
      flex-grow: 1;
    }

    &__flex-break {
      flex-basis: 100%;
      height: 0;
    }
  }
}
