.abx-floor-dropdown-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color-dark-gray;

  &__icon {
    margin-right: 4px;
  }

  abx-input[abx-type="enum-radio"] {
    /* Within this single implementation, no errors should be showing ever anyways */
    .abx-input__error-container {
      display: none;
    }
  }
}

.abx-input__select-menu-container {
  &--floor-dropdown {
    md-select-menu,
    md-select-menu md-content {
      max-height: 512px;
    }
  }
}
