abx-radio-button-group {
  display: flex;
  flex: 1 1 auto;

  .radio-button-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &.radio-button-group--disabled {
      opacity: 0.8;
    }
  }

  abx-radio-button,
  .radio-button-option {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 36px;
    min-width: 120px;
    padding: 8px;
    margin: 8px;

    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;

    color: $secondary;
    border: thin solid $secondary-1;
    background-color: $white;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      border-color: $secondary-3;
      background-color: $secondary-1;
    }

    &.radio-button-option--selected {
      color: $white;
      border: 1px solid $white;
      background-color: $secondary;
    }
    &.radio-button-option--disabled {
      cursor: unset;
    }
  }
}

.filter-options {
  margin-bottom: 16px;

  .radio-button-group {
    justify-content: center;

    @include respond-to(gt-sm) {
      justify-content: flex-end;
    }

    .radio-button-option {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 8pt;
      height: 30px;
      color: $gray-666;
      background-color: $gray-EE;
      border-radius: 0;
      margin: 0px;
      border-left: 1px solid $gray-CC;
      border-right: 1px solid $gray-CC;
      border-top: none;
      border-bottom: none;
      box-shadow: inset 0px 8px 6px -9px rgba(0, 0, 0, 0.4);

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: none;
        box-shadow: inset 0px 8px 6px -9px rgba(0, 0, 0, 0.4),
          inset 5px 0px 9px -7px rgba(0, 0, 0, 0.4);
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: none;
        box-shadow: inset 0px 8px 6px -9px rgba(0, 0, 0, 0.4),
          inset -5px 0px 9px -7px rgba(0, 0, 0, 0.4);
      }
    }
    .radio-button-option--selected {
      background-color: $blue-2E;
      color: $white;

      .work-order-intent__reactive {
        .reactive-triangle {
          border-color: transparent transparent $white transparent;
          _border-color: $color-black $color-black $white $color-black;
        }
        .reactive-label {
          color: $blue-2E;
        }
      }
      .work-order-intent__preventive {
        border: 2px solid $white;
        color: $white;
      }
    }

    .work-order-intent__reactive {
      display: flex;
      justify-content: center;
      position: relative;
      margin-left: 6px;
      margin-top: -2px;
      .reactive-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 17.3px 10px;
        border-color: transparent transparent $gray-666 transparent;
        line-height: 0px;
        _border-color: $color-black $color-black $gray-666 $color-black;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }
      .reactive-label {
        position: absolute;
        top: 4px;
        left: 0;
        width: 100%;
        font-size: 8pt;
        text-align: center;
        color: $gray-EE;
      }
    }
    .work-order-intent__preventive {
      display: flex;
      justify-content: center;
      border: 2px solid $gray-666;
      border-radius: 50%;
      min-width: 20px;
      color: $gray-666;
      margin-left: 6px;
      font-size: 8pt;
    }
  }
}
