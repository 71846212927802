#abx-floor-card-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .abx-floor-card-wrapper {
    display: inline-block;
    margin: 4px;
    height: 168px;
    width: 210px;

    abx-floor-card {
      position: relative;
      min-height: 100%;
      min-width: 100%;
    }
  }
}

.abx-floor-card-container__placeholder {
  color: #b3b3b3;
  text-align: center;
  font-style: italic;
  font-size: 16px;
  padding: 16px;
}
