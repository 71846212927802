abx-asset-details-sidebar {
  display: block;

  .asset-details-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &__column {
      display: flex;
      flex-direction: column;
    }

    &__carousel-wrapper {
      width: 100%;

      .asset-details-sidebar_no-image {
        font-weight: bold;
        opacity: 0.4;
        font-size: large;
        height: 100%;
        text-align: center;
        padding: 20% 0;
      }
    }

    &__details-wrapper {
      width: 100%;
    }

    .media-viewer-wrapper {
      display: flex;
      width: auto;
      height: 300px;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      overflow: unset;
      background-color: transparent;
      margin-bottom: 8px;
    }

    .media-viewer-image {
      background-color: $color-light-gray;
      border: 1px solid $color-border-gray;
      pointer-events: none;

      .control-container {
        display: none;
      }

      img,
      .backdrop {
        transition: none;
        width: 300px;
        height: auto;
        position: relative;
      }
    }

    .asset-details-sidebar__carousel {
      display: flex;
      align-self: center;

      md-icon {
        color: $color-link;
        font-size: 20px;
        &:hover {
          color: $color-link-hover;
        }
      }

      .asset-details-sidebar__carousel-of {
        padding: 0 4px;
      }
    }

    &__details-list {
      display: flex;
      width: 100%;
      // height: 300px;
      margin-top: 6px;
      overflow-y: scroll;
      border: 1px solid $color-border-gray;
    }

    &__loading {
      padding: 20px;
    }

    &__info-title {
      display: flex;
      font-size: 12pt;
      span {
        margin-left: 6px;
      }
    }

    &__field {
      margin-bottom: 6px;
      padding: 0 10px;
      display: flex;
      border-bottom: 1px solid $color-border-gray;
      padding: 8px 16px;
      &:last-child {
        border-bottom: none;
      }
    }

    &__label {
      text-align: left;
      pointer-events: none;
      font-weight: bold;
      &::after {
        content: ":";
      }
    }

    &__field-value {
      max-width: 600px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 4px;

      &:empty::before {
        content: "\200B";
      }
    }
  }
}
