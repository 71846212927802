form.password-form {
  .ng-valid {
    color: $black;
  }

  .flex {
    flex: 1 1 auto;
  }

  .abx-name-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    md-input-container {
      margin-top: 10px;
      margin-bottom: 0px;
      padding: 0px;
      width: 45%;
    }
  }

  .abx-title-company-fields {
    display: flex;
    flex-direction: column;

    md-input-container {
      margin-top: 10px;
      margin-bottom: 0px;
      padding: 0px;
    }
  }

  .abx-password-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    md-input-container {
      margin-top: 10px;
      margin-bottom: 8px;
      padding: 0px;
      width: 45%;
    }
  }
}
