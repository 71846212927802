.qr-scanner-dialog {
  &__header {
    .md-button {
      // remove the right margin for this specific button
      margin-right: 0;
    }
  }
  &__title {
    font-size: 14px;
  }

  &__sub-title {
    font-size: 11px;

    i {
      color: $akita-blue;
    }
  }

  .qr-reader {
    margin: 8px 0;
    max-height: 66.66vh;
    max-width: 66.66vh;
    .webcam,
    video {
      position: relative;
      width: 100%;
      height: 100%;
    }
    #qr-canvas {
      display: none;
    }
    .qr-optics-container {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 3000;
      .qr-optics {
        border: 5px solid $white-54;
        height: 192px;
        width: 192px;
        @media screen and (min-width: 480px) and (max-width: 599px) {
          height: 240px;
          width: 240px;
          max-height: 40vh;
          max-width: 40vh;
        }
        @media screen and (min-width: 600px) {
          width: 384px;
          height: 384px;
          max-height: 40vh;
          max-width: 40vh;
        }
      }
    }
  }

  p {
    margin: 16px 0;
    padding: 8px;
    font-size: 1.125em;
    font-weight: 400;
    text-align: center;
    color: $white;
  }

  md-progress-circular {
    margin: 0 auto 20px;

    svg path {
      stroke: $color-medium-gray;
    }
  }
}

@media only screen and (max-width: 320px) {
  .qr-scanner-dialog {
    &__title {
      // Prevents the texts from smashing into each other
      font-size: 12px;
    }

    &__sub-title {
      font-size: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .qr-scanner-dialog {
    // Prevents the texts from being too damn small
    &__title {
      font-size: inherit;
    }

    &__sub-title {
      font-size: 14px;
    }
  }
}
