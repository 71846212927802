.inspection-program-checklist-details {
  margin: 12px;
  z-index: 0;
  &__back-button {
    padding: 12px;
    md-icon {
      color: $color-link;
    }
  }

  .checklist-details-card {
    padding: 0;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;

      md-icon {
        margin: 8px;
        color: $color-link;
      }
    }

    &__content {
      .checklist-details-table {
        &__header {
          flex-direction: row;
          padding: 0 16px;
          min-height: 35px;
          padding: 8px 0 8px 16px;

          .header-column {
            font-size: 12px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            &__stop {
              width: 30%;
            }

            &__assignee {
              width: 30%;
            }

            &__date {
              width: 30%;
            }

            &__result {
              width: 10%;
            }
          }
        }
      }

      abx-round-stop-item {
        position: relative;

        .abx-stop-item {
          position: relative;

          .abx-stop-content {
            position: relative;
            top: auto;

            .abx-round-stop-item-preloader {
              position: relative;
            }
          }
        }
      }
    }
  }
}
