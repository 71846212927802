[abx-animate-change] {
  transition: all 0.2s;
}
[abx-animate-change].abx-animate-fade {
  opacity: 1;
}
[abx-animate-change].abx-animate-fade-active {
  opacity: 0;
  transition: none;
}
