abx-unplaced-pins {
  $content-width: 306px;

  .abx-unplaced-pins {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;

    right: -$content-width;
    transition: right 0.3s;
    &.open {
      right: 0;
    }
  }
  .abx-unplaced-pins-btn {
    height: 40px;
    min-width: 44px;
    border-radius: 0;
    margin: 0;
    background-color: $white !important; // override hover transparency on buttons
    border-bottom: 4px solid $blue;
    position: absolute;
    top: 0px;
    right: $content-width;
    md-icon {
      &.abx-location {
        position: absolute;
        bottom: 1px;
        left: 3px;
        color: $color-text;
      }
      &.abx-ellipsis {
        font-size: 12px;
        min-height: 100%;
        min-width: 100%;
        color: white;
      }
    }
    .abx-unplaced-pins-circle {
      height: 15px;
      width: 15px;
      background-color: $color-error;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 3px;
      right: 8px;
      font-size: 10px;
      font-weight: 600;
      color: $white;
      line-height: 15px;
    }

    &[disabled] {
      cursor: not-allowed;
      border-bottom-color: $color-medium-gray;

      .abx-unplaced-pins-circle {
        background-color: $color-medium-gray;
      }

      md-icon {
        &.abx-location {
          color: $color-medium-gray;
        }
      }
    }
  }

  .abx-unplaced-pins-header-and-list {
    width: $content-width;
    position: absolute;
    top: 0;
    right: 0;
  }

  .abx-unplaced-pins-header {
    height: 40px;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    border-bottom: 4px solid $blue;
    background-color: $white;
    justify-content: space-between;
    .abx-unplaced-pins-header__text {
      padding-left: 18px;
      font-size: 16px;
      font-weight: 700;
    }
    .abx-unplaced-pins-header__icon {
      margin: 0;
      color: $blue;
    }
  }

  .abx-unplaced-pins-list {
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    background-color: $white;
    padding: 0;
    max-height: 240px;
    overflow: auto;

    .preload-wrapper {
      .preload-item {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        border-bottom: 1px solid $gray-EF;

        .preloader-line {
          flex: 0 auto;
          margin: 0 10px;
        }
      }
    }
  }

  .abx-unplaced-pins-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $secondary-1;
    }
    .md-button {
      // Disable md-button background color rules, since we're doing our own
      background-color: transparent;
      transition: none;
    }
  }

  abx-pin-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
