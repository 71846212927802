.abx-alert-dialog-content {
  font-size: 16px;
  max-height: 520px;

  ul {
    list-style-type: none;
  }

  p {
    margin: 0;
  }
}

.abx-alert-dialog-short {
  .abx-alert-dialog-content {
    min-height: 150px;
  }
}
