abx-wo-list {
  display: block;

  .work-order-list-header .header-label {
    &.quick-action-arrow {
      max-width: 56px;
    }
  }

  .work-order-list-header .header-label,
  .work-order-list .work-order-list-row .work-order-list-cell {
    &.work-order-priority {
      max-width: 36px;
    }
    &.btn-link {
      padding: 0 12px;
    }
    &.quick-action-arrow {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      user-select: none;
      width: 56px;
      min-width: 56px;
      max-width: 56px;
      padding: 8px 16px;

      .material-icons {
        font-size: 24px;
        color: $blue;
      }

      .caret-placeholder {
        &.material-icons {
          font-size: 24px;
          color: rgba(#fff, 0);
        }
      }
    }
  }

  .work-order-intent {
    padding-left: 20px;

    @include respond-to(gt-sm) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .work-order-assignees {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 4px !important;

    i {
      font-size: 18px;
      color: $color-dark-gray;
    }

    &__unassigned {
      height: 100%;
      display: flex;
      font-style: italic;
      color: $color-dark-gray;
    }

    &__preview {
      height: 100%;
      max-width: calc(100% - 51px);

      &.full-preview {
        max-width: 100%;
      }

      div {
        display: flex;
      }
    }

    &__chip {
      padding-left: 5px;
      min-width: 46px;
    }
  }

  .work-order-location {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;

      i.material-icons {
        margin-right: 4px;
        color: $color-dark-gray;
      }
    }
  }

  .work-order-floors {
    display: flex;
    align-items: center;
    height: 100%;

    &__preview {
      height: 100%;
      max-width: calc(100% - 51px);

      &.full-preview {
        max-width: 100%;
      }

      a {
        display: flex;
      }
    }

    &__chip {
      padding-left: 5px;
      min-width: 46px;
    }
  }

  .work-order-priority {
    display: flex;
    align-content: center;
    justify-content: center;

    @include respond-to(xs) {
      padding-left: 4px;
    }

    .high-priority {
      color: $color-error;
    }
    .material-icons {
      font-size: 18px;
    }
  }
  .work-order-list-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 32px;
    height: 32px;
    background-color: #f9f9f9;
    color: $color-dark-gray;

    .header-label {
      @extend .text-bold;
      font-size: 12px;
      padding: 0 8px;

      &.work-order-intent-label {
        display: flex;
        justify-content: flex-start;
      }

      &.work-order-priority {
        justify-content: center;
      }
    }
  }

  .work-order-list {
    display: flex;
    align-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    border: 2px solid $gray-DD;

    .work-order-list-loading-spinner {
      margin: 32px;
      align-self: center;
    }

    .row-divider {
      margin: 0;
    }

    .work-order-list-row {
      display: flex;
      align-items: center;
      flex: 1 0 0%;
      flex-direction: row;
      min-height: 48px;

      .work-order-list-cell {
        padding: 8px;

        &.work-order-intent {
          padding-left: 20px;
        }

        .text-link {
          @extend .text-bold;
        }
      }
    }

    .empty-list-placeholder {
      @extend .text-placeholder;
      font-size: 24px;
      padding: 32px;
      text-align: center;
    }
    .work-order-intent__reactive {
      .reactive-triangle {
        border-color: transparent transparent $blue-2E transparent;
        _border-color: $color-black $color-black $blue-2E $color-black;
      }
      .reactive-label {
        color: $white;
        font-weight: bold;
      }
    }
    .work-order-intent__preventive {
      font-weight: bold;
      color: $blue-2E;
      border: 2px solid $blue-2E;
    }
  }

  .work-order-intent__reactive {
    display: flex;
    justify-content: center;
    position: relative;
    .reactive-triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 21.7px 12.5px;
      border-color: transparent transparent $white transparent;
      line-height: 0px;
      _border-color: $color-black $color-black $white $color-black;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    .reactive-label {
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      color: $blue-2E;
    }
  }
  .work-order-intent__preventive {
    display: flex;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    min-width: 23px;
    max-height: 23px;
    color: $white;
  }
}
