.upload-document-dialog {
  min-height: 90%;
  max-height: 90%;
  height: 90%;

  .md-dialog-content {
    background-color: $white-F1;

    &.offline-warning {
      display: flex;
      flex-direction: row;

      background-color: $white;

      span {
        margin: 0 20px;
      }

      md-icon.warning {
        color: $color-pin-14;
        margin-left: 10px;
        margin-right: 20px;
      }

      .md-button {
        margin-left: auto;
      }
    }
  }
}

.no-pointer-events {
  pointer-events: none;
}
