abx-cost-line-customization-form {
  display: block;

  .cost-line-customization-form {
    display: flex;
    flex-direction: row;
    align-items: center;

    abx-input {
      flex-grow: 1;
      &:not(:first-child) {
        margin-left: 8px;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &__formula-symbol {
      flex-grow: 0;
    }
  }
}
