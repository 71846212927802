.message-dialog .note-input-input {
  margin: 8px 0 0;
  width: 100%;
  padding: 10px;
  font-size: 12pt;
  background-color: #eee;
  border: 2px solid #d9d9d9;
  border-radius: 2px;
  resize: none;
}

.message-dialog-save-cancel {
  display: flex;
  flex-direction: row;
}

.message-dialog-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
