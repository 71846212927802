abx-chart-legend {
  display: block;
  min-height: 56px;

  .chart-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 8px 24px;
    border: 1px solid $color-border-gray;
    border-radius: 2px;

    @for $i from 1 through length($graphColors) {
      .legend-item:nth-child(#{length($graphColors)}n + #{$i}) {
        .legend-circle {
          background: nth($graphColors, $i);
        }
      }
    }
    .stack-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1 auto;
      max-width: 100%;
      padding: 8px 0;

      .stack-by {
        min-width: 74px;
        font-weight: bold;
      }

      .stack-filter {
        flex: 1 1 100%;
        max-width: 240px;
        max-height: 32px;
        margin-left: 8px;

        md-select {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;

          .md-select-icon {
            color: $blue;
          }
        }
      }

      .stack-warning {
        display: flex;
        flex-direction: row;
        padding: 0px 32px;
        align-items: center;
        font-size: 12px;

        .material-icons {
          margin: 16px;
          color: $red;
        }
      }
    }

    .legend-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-height: 240px;
      padding: 8px;
      cursor: default;
      overflow-y: scroll;
    }

    .legend-item {
      display: flex;
      align-items: center;
      flex: 1 1 50%;
      max-width: 50%;
      margin: 8px 0;
      transition: all 0.1s ease-in-out;

      &:hover {
        .legend-text {
          font-weight: bold;
        }
        .legend-circle {
          transform: scale(1.1);
        }
      }

      .legend-circle {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        border-radius: 50%;
      }

      .legend-text {
        margin: 0 32px 0 12px;
        font-size: 14px;
        text-transform: capitalize;

        &.legend-text-unset {
          text-transform: unset;
        }
      }
    }

    @include respond-to(gt-md) {
      .legend-container {
        max-height: 304px; // 3 columns of 8
      }
      .legend-item {
        flex-basis: 33%;
        max-width: 33%;
      }
    }

    @include respond-to(gt-lg) {
      .legend-item {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
}
