abx-cost-line-selection-list-item {
  display: block;
}
.cls-list-item {
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 4px;
  background-color: $color-white;
  width: 100%;

  md-radio-button {
    margin-bottom: 0;
  }

  &__label-uniformat {
    @include text-sm;
    color: $color-dark-gray;
  }

  &__label-description {
    @include text-p;
  }

  &__form {
    border-top: 1px solid;
    border-color: $color-light-gray;
    margin-top: 4px;
    padding-top: 8px;
  }
}
