.asset-cost-estimation {
  &__container {
    padding: 16px;
  }

  &__title {
    font-weight: bold;
    color: $color-dark-gray;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    a {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  &__loading {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding: 24px 16px;
  }

  &__no-result {
    text-align: center;
    font-style: italic;
    color: $color-medium-gray;
    margin-top: 8px;
    font-size: 18px;
  }

  &__base-cost {
    &__cost {
      text-align: end;
      font-weight: bold;
      font-size: 14px;
    }

    &__container {
      margin-top: 8px;
    }

    &__uniformat {
      font-weight: bold;
    }

    &__description {
      font-style: italic;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__total-cost {
      background-color: #e1eefa;
      text-align: end;
      font-weight: bold;
      width: 100%;
      padding: 5px 20px;
      font-size: 14px;
    }
  }
}
abx-asset-cost-estimation {
  header {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.asset-cost-estimation__table {
  text-decoration: none;
  padding: 0px;
  margin-top: 8px;
  &__item {
    display: flex;
    flex-direction: row;
    :first-child {
      text-overflow: ellipsis;
      width: 80%;
      font-style: italic;
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }
    :last-child {
      width: 20%;
      font-weight: bold;
      text-align: end;
    }
  }
}
