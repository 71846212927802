// TODO: WEBAPP-8009 - Maks - Remove important flags when bootstrap is removed

// Bootstrap ovverides
a {
  color: $color-link;
  font-weight: 600;

  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }

  &:active,
  &:focus {
    color: $color-secondary;
  }
}

// Use this for future links
.abx-link {
  color: $color-link;
  font-weight: 600;

  &:hover {
    color: $color-link-hover;
  }

  &:active,
  &:focus {
    color: $color-secondary;
  }

  &--destructive {
    color: $color-error;

    &:hover {
      color: $color-error-hover;
    }
  }
}
