abx-map {
  display: flex;
  flex: 1;
  background-color: #e5e5e5;

  .abx-map__container {
    display: flex;
    flex: 1;
  }

  ui-gmap-google-map {
    display: flex;
    flex: 1;

    .angular-google-map {
      display: flex;
      flex: 1;

      .angular-google-map-container {
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }

    .info-window-title {
      margin-bottom: 8px;
    }

    .abx-info-window {
      width: unset !important;
      height: 22px;
      margin-left: 10px;
      opacity: 0.85;
      font-size: 12px;
      color: $white;
      background-color: $color-text;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -12px;
        border-style: solid;
        border-width: 11px 12px 11px 0;
        border-color: rgba(0, 0, 0, 0) $color-text rgba(0, 0, 0, 0)
          rgba(0, 0, 0, 0);
      }

      .abx-info-window__content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        white-space: nowrap;
      }
    }
  }
}
