// -------------------------------------
//   Tabs
// -------------------------------------
md-tabs {
  md-tab-item {
    min-width: 110px;

    @media screen and (max-width: 330px) {
      min-width: 105px;
      padding: 12px 6px !important;
      overflow: unset !important;
    }
  }
  md-ink-bar {
    height: 3px;
  }

  &.elevated-tabs,
  &.elevated-tabs md-tabs-wrapper {
    @extend .elevation-frame-bot-4dp;
  }
  &.white-tabs {
    md-tabs-wrapper {
      background-color: $white;
      md-tabs-canvas md-pagination-wrapper md-tab-item {
        text-transform: none;
        &:not([disabled]) {
          color: $color-text;
          &.md-active {
            @extend .text-bold;
            color: $color-link;
          }
        }
        &.md-disabled {
          color: $black-75;
        }
        md-icon.material-icons {
          color: $blue;
          margin-bottom: 5px;
        }
      }
    }
  }
  &.gray-tabs {
    md-tabs-wrapper {
      background-color: $color-light-gray;

      md-tabs-canvas md-pagination-wrapper md-tab-item {
        &:not([disabled]) {
          color: $color-text;
          &.md-active {
            color: $color-text;
          }
        }
        &.md-disabled {
          color: $black-26;
        }
        md-icon.material-icons {
          color: $blue;
          margin-bottom: 5px;
        }
      }
    }
  }
  &.black-tabs {
    md-tabs-wrapper {
      background-color: rgb(33, 33, 33);

      md-tabs-canvas md-pagination-wrapper md-tab-item {
        &:not([disabled]) {
          color: $white;
          &.md-active {
            color: $white;
          }
        }
        &.md-disabled {
          color: $white-26;
        }
        md-icon.material-icons {
          color: $color-primary;
          margin-bottom: 5px;
        }
      }
    }
    md-ink-bar {
      background-color: $color-primary;
    }
  }
  &.dark-tabs {
    @extend .black-tabs;
    md-tabs-wrapper {
      background-color: $color-secondary;
    }
  }
}
