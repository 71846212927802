.abx-edit-location-dialog {
  .duplicate-location-warning {
    color: $color-error;
    text-align: center;
    font-weight: bold;
  }

  .abx-work-order-location-section {
    display: flex;
    flex-direction: column;

    &__inputs {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }
  }
}
