abx-round-stop-list {
  display: flex;
  flex-direction: column;
  min-height: 40px; // height of the header
  overflow-y: auto;

  .abx-stop-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background-color: $color-light-gray;
    color: $color-text;
    border-top: 1px solid $color-border-gray;
    border-bottom: 1px solid $color-border-gray;

    &__title {
      margin-right: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    &__count {
      font-size: 12px;
    }

    &__toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;

      .toggle {
        &__bulk-edit-switch {
          .bulk-edit-switch {
            margin: 0 16px 0 0;
          }
        }
        &__icon {
          md-icon {
            color: $color-link;
            fill: $color-link;
          }
        }
        &__visibility {
          margin-right: 8px;
        }
      }
    }
  }

  .abx-stop-list__bulk-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    background-color: $color-border-gray;
    border-top: 1px solid $color-medium-gray;

    .bulk-edit {
      &__select-all {
        margin: 0 0 0 18px;
        min-width: 39px;
        height: 100%;
        border-right: 1px solid $color-medium-gray;
      }

      &__button {
        min-height: 32px;
        padding: 0;
      }

      &__checklist-dropdown {
        display: flex;
        align-items: center;
        margin-left: auto;

        .checklist-dropdown {
          &__label {
            font-weight: 600;
            text-align: right;
            font-size: 12px;
          }
          &__dropdown {
            margin: 0 16px;
            width: 220px;

            .abx-input__readonly--value {
              display: flex;
              align-items: center;
              margin-bottom: 0px;
              height: 30px;
              background-color: $color-light-gray;

              .ng-scope {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .abx-input__error-container {
          display: none;
        }
      }
    }
  }

  .round-stop-list__fetch-btn {
    margin-left: 25%;
    width: 50%;
  }

  md-content {
    position: relative;
    overflow-y: auto;
    max-height: 0;
    transition: max-height 0.1s ease-out;

    /* this is a hack to animate the height of an element, must use max-height instead of height */
    &.abx-stop-list__expanded {
      max-height: 10000px;
      transition: max-height 0.5s ease-in;
    }
  }
}
