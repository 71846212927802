a.md-button.md-icon-button.remove-spacing {
  margin: 0;
  padding: 0;
  height: 24px;
  min-height: 24px;
}

abx-asset-list {
  abx-list-item {
    line-height: 24px;
  }
  //Color the photo_library icon
  .file-preview-icon-active {
    color: #2d8dd9;
  }
  .file-preview-icon-deactivated {
    color: #999999;
  }
}
