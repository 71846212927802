abx-asset-stats {
  display: block;

  .no-assets-label {
    @extend .text-capitalize;
    @extend .text-placeholder;

    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-top: 16px;
  }

  .view-all-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;

    button.md-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;

      @include respond-to(xs) {
        flex: 1 1 100%;
        justify-content: center;
        margin-top: 0;
      }
    }

    .material-icons {
      margin-left: 8px;
      color: $color-black;
      float: right;
    }
  }
}
