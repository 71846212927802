.attachment-thumbnail {
  $width: 130px;
  $height: 105px;

  display: flex;
  flex-direction: column;
  width: $width;
  padding: 2px;

  .attachment-image {
    position: relative;
    height: $height;
    width: $width;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;

    &__extension {
      position: absolute;
      border: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: $black-54;
      font-size: 24px;
      text-transform: uppercase;

      md-icon {
        margin: 0 0 0 -192px;
        font-size: 32px;
      }

      span {
        max-width: 100%;
        font-size: 12px;
        padding: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-transform: none;
      }
    }

    &__progress {
      position: absolute;
      border: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      background-color: #eee;
    }

    .error {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $white;

      > i {
        font-size: 48px;
        color: $color-error;
      }

      > span {
        color: $black-75;
        font-size: 10px;
      }
    }

    .hover {
      display: none;
      position: absolute;
      top: 0;
      text-align: right;
      z-index: 10;
      box-sizing: border-box;
      width: 100%;
      pointer-events: none;
      padding: 5px 8px;

      .remove {
        display: inline;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
        background-color: transparent;
        text-shadow: 1px 1px 3px $black-75;
        pointer-events: all;
      }

      &.mobile {
        display: block;

        .remove {
          text-shadow: 1px 1px 3px #000;
        }
      }
    }

    &:hover {
      .hover {
        display: block;
        border: solid 3px $blue;
        border-radius: 2px;
        margin: -1px;
        background-color: $black-26;
        width: $width;
        height: $height;
        padding: 3px 6px;
      }
    }
  }

  .attachment-name {
    color: $color-graph-2;
    font-size: 10px;
    padding: 2px 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .attachment-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    padding: 0 7px;
    color: $color-dark-gray;

    &--failed {
      @extend .attachment-desc;
      background-color: $color-error;
      color: $white;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        background-color: lighten($color-error, 10%);
      }
    }
  }
}
