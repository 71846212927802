abx-document-list-field {
  position: relative;
  display: block;
  margin: 16px 0 0;

  .abx-document-field-buttons {
    position: absolute;
    top: 0;
    right: 0;
  }

  button.md-button {
    margin: 0 6px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .abx-section-content {
    margin: 0 16px;
  }

  .show-more-item {
    cursor: pointer;
  }

  .show-more-btn {
    color: #2196f3;
  }

  abx-list {
    md-toolbar.abx-list-header {
      background: transparent;
    }
  }
}
