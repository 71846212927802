abx-wo-intent-chart {
  @extend %chart-component;

  .wo-intent-chart-container {
    abx-donut-chart {
      display: flex;

      .legend {
        flex: 1 1 auto;
        width: auto;
        max-width: 50%;
        max-height: unset;

        .legend-value {
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: flex-start;
          width: 100%;
          height: auto;
          padding: 0;
        }

        .legend-value-container {
          display: flex;
          align-items: center;
          padding: 8px 0;

          .legend-children {
            display: flex;
            flex-direction: column;
          }
        }
        .inner-legend-value {
          display: flex;
          padding-left: 16px;
        }
      }

      @include respond-to(gt-sm) {
        flex-direction: row-reverse;

        .abx-donut-chart {
          flex: 1 1 auto;
        }
      }

      @include respond-to(gt-md) {
        .legend {
          max-width: 25%;
        }
        .abx-donut-chart {
          flex: none;
        }
      }
    }
  }

  .preloader-chart {
    .preloader-donut-chart {
      min-width: 300px;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;
    }
    .preloader-donut-legend {
      align-content: center;
      margin: 8px 0;
      min-width: 260px;
      min-height: 288px;

      .legend {
        .legend-value {
          width: 154px;
        }
        &.legend--child {
          .legend-value {
            width: 176px;
          }
        }
      }
    }

    @include respond-to(gt-sm) {
      flex-direction: row-reverse;

      .preloader-donut-legend {
        align-content: flex-start;
        margin: 32px 0;

        .legend {
          width: 100%;
        }
      }
    }

    @include respond-to(gt-md) {
      justify-content: center;

      .preloader-donut-legend {
        max-width: 380px;

        .legend {
          width: 100%;
        }
      }
    }
  }
}
