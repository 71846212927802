// -------------------------------------
//   Constants
// -------------------------------------

$min-dp: 1;
$max-dp: 24;

$break-xs: 600px;
$break-small: 960px;
$break-medium: 1280px;
$break-large: 1920px;

// Respond-to mixin follows the breaking points of angularJS material spec
// Use 'mobile-first' mindset
// Usage: @include respond-to(xs) { color: red };

@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (max-width: $break-xs - 1) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $media == gt-xs {
    @media only screen and (min-width: $break-xs) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (min-width: $break-xs) and (max-width: $break-small - 1) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $media == gt-sm {
    @media only screen and (min-width: $break-small) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $break-small) and (max-width: $break-medium - 1) {
      @content;
    }
    @media print {
      @content;
    }
  } @else if $media == gt-md {
    @media only screen and (min-width: $break-medium) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == gt-lg {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}
