abx-record-condition-form {
  display: block;
  min-height: 370px;

  .abx-record-condition {
    &__inputs {
      display: flex;
      flex-direction: row;
    }

    &__input-container {
      flex: 1 1 50%;
      max-width: 50%;
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &__info {
      font-size: 12px;
      height: 132px;
      min-height: 132px;
      margin: 0 12px;

      .condition-title,
      .condition-subtitle {
        margin-bottom: 3px;
      }
      .condition-title {
        @extend .text-bold;
      }
      .condition-subtitle {
        @extend .text-italic;
      }
    }

    &__condition-history {
      .history-title {
        margin: 4px 8px;
      }
      .history-table {
        width: 100%;
        font-size: 12px;
        border: 1px solid $color-border-gray;

        .table-header-row,
        .data-row {
          padding: 4px 16px;
        }
        .table-header-column,
        .data-column {
          width: 33%;
        }

        .table-header-row {
          display: flex;
          flex-direction: row;
          font-weight: bold;
          color: $color-white;
          background: $color-secondary;
        }

        .history-table-data {
          display: block;
          width: 100%;
          max-height: 100px;
          overflow-y: overlay;

          .data-row {
            display: flex;
            flex-direction: row;
            border-collapse: collapse;

            &:not(:last-of-type) {
              border-bottom: 1px solid $color-border-gray;
            }

            .data-column {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .abx-condition-dialog-inputs {
    display: flex;
    flex-direction: row;
  }
}
