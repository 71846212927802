abx-simple-list {
  .abx-simple-list-wrapper {
    background-color: $white;

    &.danger {
      border: 1px solid $red-EB;
    }

    &.warn {
      border: 1px solid $yellow-FA;
    }

    &.success {
      border: 1px solid $green-D6;
    }

    &.default {
      border: 1px solid $gray-DD;
    }
  }

  .abx-simple-list-header {
    padding: 16px;

    &.danger {
      background-color: $red-F2;
      color: $red-A9;
    }

    &.warn {
      background-color: $yellow-FC;
      color: $yellow-8A;
    }

    &.success {
      background-color: $green-DF;
      color: $green-3C;
    }

    &.default {
      background-color: $gray-E5;
      color: $gray-333;
    }
  }

  ul {
    margin-bottom: 0px !important;
    padding: 0px;

    li {
      list-style-type: none;
      padding: 12px 16px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray-DD;
      }

      &:hover {
        background-color: $white-F1;
      }

      .item-title {
        font-weight: bold;
      }
    }
  }

  .empty-text {
    @extend .text-placeholder;
    padding: 16px 8px;
  }
  .view-all {
    padding: 12px;
    background-color: $white-F9;
    border-top: 1px solid $gray-DD;

    &:hover {
      background-color: $color-light-gray;
    }
  }
}
