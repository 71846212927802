.media-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 72px;
  min-height: 72px;
  padding: 12px;
  line-height: 1;
  border-bottom: 1px solid $black-10;
  background-color: $color-secondary;
  color: $white-87;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 8;

  .logo {
    display: block;
    width: 36px;
    height: 36px;
    background-size: contain;
    margin-right: 16px;
  }

  .title {
    display: flex;
    flex-direction: column;
  }

  .building-name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }

  .media-name {
    margin-top: 4px;
    line-height: 24px;
    font-size: 20px;
  }
}

.media-viewer-wrapper {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: $color-light-gray;
}

.media-viewer-image {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: fixed;
  }

  img,
  .backdrop {
    transition: transform 0.5s ease-in-out;
  }

  .control-container {
    display: flex;
    flex-direction: column;

    // Position
    position: absolute;
    bottom: 76px; // 16px above help widget: 10 + 50 + 16
    right: 32px;
  }

  .control-container__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-secondary;
    color: $white;
    font-size: 24px;
    font-family: "Material Icons";
    width: 56px;
    height: 56px;
    border-radius: 50%;
    cursor: pointer;
    margin: 4px;
    font-feature-settings: "liga";
    @extend .elevation-frame-6dp;
  }

  .rotate {
    @extend .control-container__button;

    &::after {
      content: "rotate_right";
    }
  }

  .reset-position {
    @extend .control-container__button;
    &::after {
      content: "fullscreen_exit";
    }
  }

  .zoom-in {
    @extend .control-container__button;

    &::after {
      content: "add";
    }
  }

  .zoom-out {
    @extend .control-container__button;

    &::after {
      content: "remove";
    }
  }
}
