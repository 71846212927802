// ****************************************
//
//   Desktop Styles
//
// ****************************************

// App Content
#content-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100%;
  overflow: auto;
}
#content {
  margin-left: 60px;
  background-color: $color-light-gray;
  word-wrap: break-word;
  overflow: auto;

  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;

  &.ng-hide-add {
    animation: slideOutLeft 0.5s;
    -webkit-animation: slideOutLeft 0.5s;
  }
  &.ng-hide-remove {
    animation: slideInLeft 0.2s;
    -webkit-animation: slideInLeft 0.2s;
  }
}

#app,
.app {
  overflow: hidden;
  #content {
    margin: 0;
    max-width: 100%;
  }
}

// TODO: [WEBAPP-8497] remove with WEBAPP-8499: Create location component
.abx-location-component {
  margin: 16px 0;
}

// -------------------------------------
//   Headings
// -------------------------------------
.heading {
  align-items: center;
  height: 60px;
  border-bottom: 1px solid $black-10;
  padding-left: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.detail-heading {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 16px;
  background-color: $white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  h3 {
    margin: 0;
    margin-left: 8px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 500;
    color: $color-black;
  }
  .work-order-intent__reactive {
    display: flex;
    justify-content: center;
    position: relative;

    .reactive-triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 21.7px 12.5px;
      border-color: transparent transparent $blue-2E transparent;
      _border-color: $color-black $color-black $blue-2E $color-black;
      line-height: 0px;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    .reactive-label {
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      color: $white;
      font-weight: bold;
    }
  }
  .work-order-intent__preventive {
    display: flex;
    justify-content: center;
    border: 2px solid $blue-2E;
    border-radius: 50%;
    min-width: 25px;
    color: $blue-2E;
    font-weight: bold;
  }
}

// -------------------------------------
//   Buttons
// -------------------------------------
button,
[ng-click],
[data-ng-click],
[x-ng-click],
[data-event],
[ui-sref],
[href] {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

// -------------------------------------
//   Forms
// -------------------------------------
.form-control {
  &:focus {
    border-color: $blue;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

// -------------------------------------
//   Labels
// -------------------------------------
label.required::before {
  color: $blue;
  padding-right: 2px;
  content: "*";
}
md-input-container label {
  font-weight: normal;
  margin-bottom: 0;
}
.abx-not-provided {
  color: $black-38;
}

// -------------------------------------
//   Tables
// -------------------------------------
.table-container {
  padding: 16px 0;
  th,
  td {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    &:first-of-type,
    &:last-of-type {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  .table-placeholder {
    padding: 16px 80px;
    text-align: center;
    font-weight: 500;
    p {
      margin-bottom: 32px;
    }
  }
}

// -------------------------------------
//   Seperators
// -------------------------------------
.slash {
  color: $black-38;
}

// -------------------------------------
//   Spinners
// -------------------------------------
.loading-spinner {
  padding: 30px;
  text-align: center;
}
.detail-loading-indicator {
  margin-top: 32px;
  margin-bottom: -32px;

  // Children still visible, but this enables the
  // container to hover over any neighboring content
  height: 0px;

  &.ng-hide-add {
    animation: fadeOut 0.5s;
  }
}

// -------------------------------------
//   Date Pickers
// -------------------------------------
.uib-daypicker,
.uib-monthpicker,
.uib-yearpicker {
  padding: 0 5px;

  &:focus {
    outline: none;
  }

  button.btn {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    &.btn-info,
    &.btn-info:hover {
      color: $color-black;
      background-color: $yellow-87;
    }
  }
}

// -------------------------------------
//   MD Toast  -
// -------------------------------------
// Fixes the problem with the toast "view" button being hidden behind our help button
@media (max-width: 959px) {
  md-toast {
    .md-toast-content {
      justify-content: flex-start;
      span {
        flex: none; // the text will not push the button all the right anymore on small screens
      }
    }
  }
}
