abx-mobile-header-switcher {
  @extend .elevation-frame-4dp;
  display: block;
  background-color: transparent;
  z-index: 10;

  // Position the switcher below the md-toolbar.
  // Since it's dependant on MD's height rules for md-toolbar,
  // mirror their media queries for it
  position: absolute;
  top: 64px;
  left: 0;
  // Width needed for IE 11 to respect the box model when position absolute
  width: 100%;
  height: calc(100vh - 64px);

  md-content {
    background: $gray-333;
  }

  .header-switcher-container {
    display: flex;
    width: 80%;
    height: 100%;
    overflow: hidden;
  }

  .abx-header-switcher__change-organization {
    display: flex;
    align-items: center;
    background: $gray-333;
    color: $white;
    padding: 0 16px;
    min-height: 54px;
    font-size: 14px;

    md-icon {
      margin: 0 8px 0 0;
      color: $color-link;
    }
  }

  .abx-header-switcher__building-list {
    padding-bottom: 48px;
    background: $gray-333;
  }

  .abx-header-switcher__list {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid $gray-333;
    background-color: $gray-333;

    .abx-header-switcher__no-scroll {
      overflow: unset;
    }

    // Bold "All Buildings" group
    abx-header-list.abx-header-switcher__group-list {
      .abx-header-list__list {
        .abx-header-list-item:first-of-type {
          .abx-header-list-item__text {
            @extend .text-bold;
          }
        }
      }
    }
  }

  .abx-header-switcher__org-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    height: 48px;
    color: $color-link;
    background: $color-white;

    &:hover {
      color: $color-link-hover;
      background: $color-border-gray;
    }

    .org-link-icon {
      margin: 4px;
    }

    .org-link-text {
      font-size: 14px;
      text-transform: uppercase;
      margin: 4px;
      font-weight: bold;
    }
  }

  .abx-header-switcher__content {
    position: relative;
    flex: 1;
  }

  abx-header-list {
    md-list.abx-header-list__list {
      background-color: $gray-333;

      md-list-item.abx-header-list-item.abx-selected {
        background-color: $color-link-hover;
      }

      md-list-item.abx-header-list-item {
        background-color: $gray-333;
        color: white;

        .abx-selected {
          background-color: blue;
        }

        .md-secondary-container {
          display: none;
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 20vw;
    height: calc(100vh - 64px);
    top: 0;
    right: 0;
    background-color: $black-26;
  }

  @include respond-to(gt-sm) {
    .abx-header-switcher__list {
      max-width: 20%;
      &::only-child {
        max-width: 25%;
      }
    }
  }
}
