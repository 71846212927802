.abx-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.btn-no-text {
    min-width: 56px;
  }
  &:not(.btn-no-text) i:not(:last-child) {
    margin-right: 4px;
  }

  input[type="file"] {
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    opacity: 0;
    cursor: inherit;
    margin: 0;
  }

  &.btn {
    text-transform: uppercase;
    border-radius: 2px;
    min-height: 40px;
    height: auto;
    line-height: 16px;
    padding: 10px 12px;
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border: 2px solid;
    color: $color-text;

    &.btn-xs {
      font-size: 12px;
      padding: 2px 6px 1px 5px;
    }

    i:not(:last-child) {
      margin-right: 4px;
    }

    transition: background-color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
      border-color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
      box-shadow 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.btn-disabled {
    background-color: $color-light-gray;
    border: 2px solid $color-border-gray;
    color: $color-medium-gray;

    cursor: not-allowed;

    i {
      color: $color-medium-gray;
      fill: $color-medium-gray;
    }
  }

  &.btn-primary {
    @extend .btn;

    background-color: $color-primary;
    border: 2px solid $color-primary;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($color-primary, 8%);
      background-color: $lightenedBackground;
      border-color: $lightenedBackground;
    }

    &:active {
      $darkenedBackground: darken($color-primary, 8%);

      color: $color-black;
      background-color: $darkenedBackground;
      border-color: $darkenedBackground;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &.btn-secondary {
    @extend .btn;

    background-color: white;
    border: 2px solid $color-border-gray;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($gray, 85%);
      border: 2px solid $color-text;
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($gray, 75%);
      $lightenedBorder: lighten($gray, 15%);

      background-color: $lightenedBackground;
      border-color: $lightenedBorder;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &.btn-destructive {
    @extend .btn;

    background-color: white;
    color: $color-error;
    border: 2px solid $color-border-gray;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($color-error, 85%);
      border: 2px solid $color-error;
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($color-error, 55%);
      $lightenedBorder: lighten($color-error, 15%);

      background-color: $lightenedBackground;
      border-color: $lightenedBorder;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &.btn-link {
    @extend .btn;

    border: 2px solid transparent;
    text-decoration: none;
    color: $color-link;

    &:hover,
    &:focus {
      $lightenedBackground: lighten($color-link, 45%);
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($color-link, 40%);

      background-color: $lightenedBackground;
    }
  }

  &.btn-link-no-bg {
    @extend .btn;

    border: 2px solid transparent;
    text-decoration: none;
    color: $color-link;
    background: transparent !important;

    &:hover {
      color: $color-link-hover;
    }

    &:focus,
    &:active {
      color: $color-secondary;
    }
  }

  &.btn-success {
    @extend .btn;

    color: $color-success;
    border: 2px solid $color-success;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($color-success, 85%);
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($color-success, 55%);
      $lightenedBorder: lighten($color-success, 15%);

      background-color: $lightenedBackground;
      border-color: $lightenedBorder;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &.btn-pass {
    @extend .btn;

    border: 2px solid $color-pass;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($color-pass, 85%);
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($color-pass, 55%);
      $lightenedBorder: lighten($color-pass, 15%);

      background-color: $lightenedBackground;
      border-color: $lightenedBorder;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &.btn-fail {
    @extend .btn;

    border: 2px solid $color-fail;

    &:hover,
    &:focus {
      &:not(:disabled) {
        @extend .elevation-frame-1dp;
      }

      $lightenedBackground: lighten($color-fail, 85%);
      background-color: $lightenedBackground;
    }

    &:active {
      $lightenedBackground: lighten($color-fail, 55%);
      $lightenedBorder: lighten($color-fail, 15%);

      background-color: $lightenedBackground;
      border-color: $lightenedBorder;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      @extend .btn-disabled;
    }
  }

  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    @extend .btn-disabled;
  }
}
