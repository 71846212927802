abx-work-performed-by-chart {
  @extend %chart-component;

  .work-performed-by-chart-container {
    .work-performed-by-chart {
      .x-axis-text {
        text-transform: unset;
      }
      .x-axis-text--rotated {
        text-transform: unset;
      }
    }
  }
}
