abx-inspection-program-details {
  .abx-inspection-program-details {
    display: flex;
    flex-direction: column;

    .text-placeholder {
      font-size: 14px;
    }
  }

  .priority-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .priority-icon {
    margin: 0 8px 0 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    padding-right: 30px;
    margin-bottom: 18px;
  }

  .checklist-template-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $color-border-gray;
  }

  .checklist-template-details.no-top-border {
    border-top: none;
  }

  .checklist-template-info {
    margin: 4px 0;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .justify-flex-end {
    justify-content: flex-end;
  }

  .column-5 {
    flex: 5;
    flex-direction: column;
    margin-left: 30px;
  }

  .column-2 {
    flex: 2;
    flex-direction: column;
    margin-left: 30px;
  }

  .margin-16 {
    margin: 16px;
  }

  .small-header-text {
    color: $color-text;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 8px;
    display: block;
  }

  .large-header-text {
    color: $color-text;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }

  .under-line {
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid $color-border-gray;
  }

  .checklist-template-name {
    color: $color-text;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }

  .checklist-template-compliance-code {
    color: $color-dark-gray;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .component-building {
    margin-bottom: 8px;
    font-weight: bold;
  }

  .component-content {
    background: $color-light-gray;
    padding: 8px;

    .component-input {
      md-select {
        height: 42px;
        background: $white;

        .md-text {
          color: $black;
        }
      }
    }

    &__details {
      &.column-5 {
        margin-right: 64px;
      }
    }

    &__details {
      .details-header {
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 8px;
      }

      .detail {
        margin-bottom: 8px;
      }
    }
  }
}
