abx-help-tooltip {
  display: block;

  .icon-container {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;

    .help-icon {
      display: flex;

      .material-icons {
        @extend .md-18;
        color: $blue;
      }
    }
  }
}
