.asset-life-cycle-dialog {
  min-height: 50%;

  &--single {
    @media screen and (max-width: 1380px) {
      width: inherit !important;
    }
    width: inherit !important;
    max-width: 80% !important;
    max-height: 85%;
  }

  .md-dialog-content {
    padding: 0;
  }

  .abx-dialog-buttons {
    margin-top: 32px;
  }

  &__content {
    flex: 1 1 66%;
    padding: 24px;
    max-height: 60vh;
    overflow-y: auto;
  }

  &__asset-details-sidebar {
    flex: 1 1 33%;
    padding: 24px 16px;
    max-height: 60vh;
    overflow-y: auto;
  }

  &__action-delete-wrapper {
    // for left-alignment
    flex-grow: 1;
  }

  .md-warn {
    color: $red-A9;
    background-color: lighten($color: $red-A9, $amount: 43%);
  }

  &__loading {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding: 48px 16px;
  }

  &__installation-date,
  &__cost {
    padding: 16px 8px;
  }

  &__installation-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__installation-date-input {
    flex: 1 1 auto;
  }

  .asset-life-cycle-dialog-catalog-item,
  .asset-life-cycle-dialog-cost-line {
    margin-bottom: 18px;

    &__label {
      font-size: 12px;
      line-height: 20px;
      padding: 2px 8px;
      font-weight: 600;
      &::after {
        content: " *";
        color: #2d8dd9;
      }
    }
    &__input {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      font-size: 14px;
      line-height: 14px;
      margin: 0;
      padding: 4px 8px;
      width: 100%;
      border-radius: 2px;
      background-color: #f5f5f5;
      border: 1px solid #d9d9d9;

      md-icon {
        margin: 0;
      }
    }

    &--readonly {
      .asset-life-cycle-dialog-catalog-item__label,
      .asset-life-cycle-dialog-cost-line__label {
        &::after {
          content: "";
        }
      }
      .asset-life-cycle-dialog-catalog-item__input,
      .asset-life-cycle-dialog-cost-line__input {
        border: none;
        background-color: transparent;
      }
    }
  }

  .asset-life-cycle-dialog-frequency,
  .asset-life-cycle-dialog-unit-cost {
    margin-bottom: 18px;

    &__label {
      font-size: 12px;
      line-height: 20px;
      padding: 2px 8px;
      font-weight: 600;
    }
    &__input {
      font-size: 14px;
      line-height: 14px;
      margin: 0;
      padding-left: 8px;
      width: 100%;
      margin-top: 8px;
    }
  }

  .asset-life-cycle-dialog-total-cost {
    margin-top: 32px;
    padding-left: 8px;

    &__label {
      font-weight: bold;
      font-size: 12pt;
    }
    &__value {
      font-size: 14pt;
    }
  }

  .asset-life-cycle-dialog-quantity-fields {
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    &__quantity {
      flex: 1 1 70%;
    }
    &__quantity-unit {
      flex: 1 1 30%;
      margin-left: 16px;
    }
  }

  .catalog-items-selection__search-input {
    display: flex;
    flex-direction: row;
    justify-content: start;
    md-icon {
      margin: 4px 16px;
    }
  }

  .catalog-items-selection__items-list {
    md-content.abx-list {
      max-height: 480px;
    }
  }

  .catalog-items-selection__details {
    .abx-button {
      align-self: flex-end;
    }
  }
}
