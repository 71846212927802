abx-app-switcher {
  position: relative;
  height: 100%;

  .abx-app-switcher {
    height: 100%;

    &__button {
      border-right: 1px solid rgba(238, 238, 238, 0.3);

      display: inline-flex;
      align-items: center;
      height: 100%;
      padding: 0 7px 0 14px;
      width: 178px;

      cursor: pointer;
      & * {
        pointer-events: none;
        user-select: none;
      }

      &:focus {
        outline: none;
      }
      &:focus,
      &:hover {
        background-color: rgba(0, 0, 0, 0.16);

        & md-icon.abx-drop-down-icon__arrow,
        & md-icon.abx-drop-down-icon__apps {
          color: lighten($color-link, 15%) !important;
        }
      }

      &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        height: 100%;
        width: 10.5em;
        margin-right: 7px;

        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.33px;
        line-height: 17px;
      }
      &__toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;

        & md-icon.abx-drop-down-icon__arrow {
          color: #2d8dd9;
        }
        & md-icon.abx-drop-down-icon__apps {
          color: $color-white;
          display: none;
        }
      }
    }

    abx-simple-dropdown {
      position: absolute;
      top: 64px;
      right: 0;
    }
    @media screen and (max-width: 711px) {
      // Make the app switcher button into just an icon
      // See abx-app-switcher styles
      &__button {
        width: initial;
        padding: 0 7px;

        &__toggle {
          & md-icon.abx-drop-down-icon__arrow {
            display: none;
          }
          & md-icon.abx-drop-down-icon__apps {
            display: inline-block;
          }
        }

        &__title {
          display: none;
        }
      }

      abx-simple-dropdown {
        right: -147px;
      }
    }
  }
}
