.skip-stop-dialog {
  textarea {
    width: 100%;
    height: 200px;
    resize: none;
  }

  .skip-stop-error {
    color: $color-error;
    text-align: center;
    font-weight: bold;
    margin: 10px 0 0 0;
  }

  .abx-dialog-buttons {
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}
