abx-date-input {
  display: block;
}

.abx-date-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.abx-date-input__field {
  flex: 1 1 auto;
}

// High specificity selector for overriding md styles
#{repeatString(".abx-date-input__datepicker-button", 10)} {
  @include abxInputCompanionButton;
}
