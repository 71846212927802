abx-markup-width-selector {
  display: block;
  position: absolute;
  right: 0px;
  top: 45px;
  width: 80px;
}

.markup-width-selector.dropdown {
  @extend .elevation-frame-4dp;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: inherit;
  height: auto;
  padding: 5px 0px;

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    min-height: 10px;
    margin: 5px 0px 5px 0px;
    padding: 0px 2px;

    // Actual size
    & :first-child {
      display: block;
      width: 100%;
      background-color: $color-dark-gray;

      &:hover {
        @extend .elevation-frame-3dp;
        background-color: $color-text;
      }
    }

    &--selected {
      @extend .elevation-frame-3dp;
      border: 2px solid white;

      & > :first-child {
        background-color: $color-text;
      }
    }

    // Change this value when adjusting
    // the total number of possible width
    $sizeCount: 9;

    // Dynamically generate size class
    @for $i from 1 through $sizeCount {
      & > .size-#{$i} {
        // This is sufficient to see the difference
        height: #{$i * 1.61803398875}px;
      }
    }
  }
}
