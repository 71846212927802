abx-wo-activity-chart {
  @extend %chart-component;

  $activityGraphColors: $color-graph-3 $color-graph-8 $color-graph-6;

  .wo-activity-chart-container {
    .chart-legend {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid $color-border-gray;
      border-radius: 2px;
      padding: 8px 24px;

      @for $i from 1 through length($activityGraphColors) {
        .legend-item:nth-child(#{length($activityGraphColors)}n + #{$i}) {
          .legend-radio-button {
            div.md-container {
              .md-on {
                background-color: nth($activityGraphColors, $i);
              }
            }
          }
        }
      }

      .legend-item {
        display: flex;
        align-items: center;
        margin: 8px 0;

        .legend-radio-button {
          margin: 0 32px 0 12px;

          .md-label.span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
