abx-markup-color-selector {
  display: block;
  position: absolute;
  right: 0px;
  top: 45px;
  width: 175px;
}

.markup-color-selector.dropdown {
  @extend .elevation-frame-4dp;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: inherit;
  height: auto;
  padding: 5px 0px;

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: 20px;
    margin: 5px 0px;

    .color-option-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      // Actual Color Box
      & :nth-child(2) {
        display: block;
        width: 30%;
        height: inherit;
        display: flex;

        &:hover {
          @extend .elevation-frame-3dp;
        }
      }

      &--selected {
        @extend .elevation-frame-3dp;
        transform: scale(1.1);
      }
    }
  }
}
