.required-icon::after {
  @extend %abx-required-asterisk;
}

.md-dialog-content > .abx-label {
  font-size: 16px;
  padding: 8px 12px;
  margin-bottom: 16px;

  .material-icons {
    color: $color-link;
  }
}

md-dialog.complete-work-order-dialog {
  .complete-work-order-error-message {
    text-align: center;
    margin-bottom: 16px;
  }
  .complete-work-order-error-list {
    max-height: 480px;
    overflow-y: auto;
    border-top: 1px solid $color-border-gray;
    &__item {
      margin: 16px;
    }
    &__error {
      padding: 0 8px;
      color: $color-error;
    }
  }
  abx-record-condition-form {
    margin-top: 32px;
  }
}
