abx-assignee-chart {
  @extend %chart-component;

  .assignee-chart-container {
    .assignee-chart {
      .x-axis-text {
        text-transform: unset;
      }
      .x-axis-text--rotated {
        text-transform: unset;
      }
    }
  }
}
