$list-item-horizontal-margin: 48px;

abx-activity-list {
  display: block;

  abx-activity-list-item {
    display: block;

    &:last-child {
      margin-bottom: 16px;
    }

    .time-code-activity {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: bottom;
      display: inline-block;
    }
  }

  .header-container {
    display: flex;

    .filter-options {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  .request-lock-toggle {
    padding: 0 24px;
    padding-top: 12px;

    md-checkbox {
      margin-bottom: 0;
    }
  }

  .auto-refresh-pane {
    padding: 0 24px;
    text-align: right;
    color: #666;
    font-style: italic;
    .refresh-button {
      color: $blue;
      vertical-align: text-bottom;
      cursor: pointer;
    }
  }

  .work-order-log-header {
    height: 95px;
    background-color: $color-secondary;
    margin-bottom: 24px;

    > .stat-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-around;

      > .stat-container__item {
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .stat-title {
          font-size: 2em;
          font-weight: bold;
        }

        > .stat-label {
          font-size: 0.8em;
          display: flex;
          flex-direction: row;

          > span {
            margin-left: 4px;
          }

          .material-icons {
            @extend .md-18;
            color: $blue;
          }
        }
      }
    }

    &__fab {
      color: inherit;
      font-size: 30px;
      display: flex;
      flex-direction: row;
      align-content: center;
      position: relative;
      background-color: $yellow;
      border-radius: 50%;
      top: -16px;
      box-shadow: 0px 0px 5px $color-black;
    }
  }

  .show-more-button {
    margin-top: 8px;
  }
}

.activity-list-item {
  font-size: 12px;

  &__header {
    display: flex;
    flex-direction: row;
  }
  &__content {
    margin: 8px $list-item-horizontal-margin 0;
    &--collapsed {
      height: 0;
      transition: height 0.5s ease, padding 0.5s ease;
      overflow: hidden;
      margin: 8px 0 -8px;
      background-color: $color-light-gray;
    }
    &--expanded {
      padding: 16px 0;
      margin-bottom: 0;
      border-top: 1px solid $color-border-gray;
      border-bottom: 1px solid $color-border-gray;
    }
  }
  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: $list-item-horizontal-margin;
    min-width: $list-item-horizontal-margin;
    max-width: $list-item-horizontal-margin;
    color: $color-dark-gray;
    fill: $color-dark-gray;
    & > i {
      color: $color-dark-gray;
      fill: $color-dark-gray;
    }
  }
  &__title {
    display: flex;
    flex: 1 1 0px;
    margin-right: $list-item-horizontal-margin;
    flex-direction: row;
    align-items: center;
  }
  &__title-detailed {
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    width: 85%;

    &__user-action {
      background-color: white;
    }
    &__values {
      span {
        display: flex;
        max-width: 100%;
        word-wrap: break-word;
        overflow: auto;
      }

      span:first-child {
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  &__timestamp {
    font-size: 10px;
    color: $gray-666;
    margin: 0 $list-item-horizontal-margin;
  }
  &__account {
    font-weight: bold;
  }
  &__content-toggle {
    margin: -13px 16px 0;
  }

  button.md-button:last-child {
    margin-right: 0px;
  }
  .abx-button {
    background-color: transparent;
  }

  &--message {
    input[type="text"] {
      outline: none;
      border: none;
      display: inline;
    }

    .activity-list-item__content {
      display: flex;
      flex: 1 1 0px;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 12px;

      p {
        display: flex;
        max-width: 100%;
      }

      abx-note-input {
        width: 100%;
      }

      textarea {
        resize: none;
        outline: none;
        width: 100%;
        padding: 6px;
        background-color: $color-light-gray;
        border: 2px solid $color-border-gray;
        border-radius: 2px;
      }

      .message-text {
        font-style: normal;
        white-space: pre-line;
        color: $color-black;
      }

      .message-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .button-container {
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        }

        .abx-button {
          display: flex;
          min-width: 0;
          min-height: 1.2em;
          line-height: 1.2em;
          padding: 0 0.5em;
          margin: 0 0 0 0.5em;
          transition: none;
        }

        &--hidden {
          visibility: hidden;
        }
      }
    }

    &:hover {
      .button-container {
        visibility: visible;
      }
    }
    &.activity-list-item--message-public {
      .activity-list-item__icon {
        i {
          color: $color-secondary;
          fill: $color-secondary;
        }
      }
      .activity-list-item__content {
        .message-text {
          font-style: normal;
        }
      }
      &.activity-list-item--message-outgoing {
        .activity-list-item__timestamp,
        .activity-list-item__title {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
        .activity-list-item__header {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
        .activity-list-item__icon {
          margin: -4px 12px;
          width: 24px;
          min-width: 24px;
          max-width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $color-primary;
          i {
            color: black;
            fill: black;
          }
        }
        .activity-list-item__content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
  &--work-order-log {
    .work-order-log-details,
    .work-order-log-actions {
      padding: 0 $list-item-horizontal-margin;
    }
    .work-order-log-details {
      color: $black;
      font-size: 14px;
      line-height: 24px;
    }
    .work-order-log-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .abx-button {
        margin: 0 6px;
      }
    }
  }

  &__revert-changes {
    margin: 0 $list-item-horizontal-margin;
    text-align: end;
    button {
      font-weight: bold;
    }
  }
}

.mention {
  display: inline-block;
  position: relative;
  padding: 0px;
  color: $color-graph-2;
  font-style: normal;
}

.mention-disabled {
  display: inline-block;
  position: relative;
  padding: 0px;
  color: $color-dark-gray;
  font-style: italic;

  span:first-child {
    font-style: normal;
  }
}
