.abx-org-asset-list-container {
  @extend .abx-org-list-container;
  // prevents double scroll
  overflow: hidden;

  & > .org-asset-filter-bar-container {
    @extend .asset-filter-bar-container;
  }

  & > .org-asset-list-wrapper {
    display: flex;
    overflow: auto;

    & > abx-pin-detail-sidebar {
      // makes sidebar non-absolute positioned
      display: flex;
      overflow: initial !important;

      & > .org-asset-list-sidenav {
        position: relative;
      }
    }
  }
}
