hr {
  &.divider {
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-border-gray;
    height: 1px;
  }

  &.header-divider {
    width: 100%;
    border: none;
    border-bottom: 3px solid $color-primary;
    height: 1px;
  }

  &.text-divider {
    width: 50px;
    border: none;
    border-bottom: 1px solid $color-border-gray;
    height: 1px;
  }
}

.abx-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;
  padding-left: 3px;
  color: inherit;
}
