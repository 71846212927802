@import "~fullcalendar/dist/fullcalendar";

abx-work-order-calendar {
  padding: 24px;
  z-index: 3;
  background-color: white;

  .abx-work-order-calendar__container {
    position: relative;
  }

  .fc {
    .fc-toolbar {
      .fc-center {
        h2 {
          font-size: 20px;
          margin-left: 0;
        }

        .fc-next-button,
        .fc-prev-button {
          background-color: transparent;
          background-image: none;
          border: none;
          margin-top: -4px; // need value is dependant on the fc-center > h2's font-size above
        }
        .fc-next-button {
          margin-left: 0;
        }

        .fc-prev-button {
          margin-right: 0;
        }
      }

      .fc-basicDay-button,
      .fc-basicWeek-button,
      .fc-month-button {
        text-transform: uppercase;
        background-color: $color-light-gray;
        background-image: none;
        border: 1px solid $color-medium-gray;
        border-radius: 0;
        box-shadow: none;
        color: $color-dark-gray;
        font-weight: bold;
        font-size: 12px;
        margin-left: 0;
        text-align: center;
        width: 76.8px;
        height: 28px;

        &.fc-state-active {
          background-color: $color-secondary;
          border: 1px solid $color-secondary;
          color: white;
          box-shadow: 0px 1px 4px 0px black;
        }
      }

      .fc-right {
        .fc-button {
          &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
    }
  }

  .abx-popover {
    position: absolute;
    display: none;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 4;
    color: $color-text;
    padding: 4px;

    &.show {
      display: block;
    }
  }

  md-progress-circular {
    position: absolute;
    top: 50%;
    margin-top: -20px; // because the diameter is set to 40 in the template
    left: 50%;
    margin-left: -20px; // because the diameter is set to 40 in the template
  }
}
