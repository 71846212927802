.abx-create-ip-dialog {
  &__form {
    min-height: 250px;
  }

  &__form-row {
    display: flex;
    flex-direction: row;

    &--assignee {
      display: block;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__form-cell {
    padding: 0px 8px;
    flex-grow: 1;
    &--half {
      max-width: 50%;
      flex: 1 1 50%;
    }
    &--full {
      min-width: 100%;
      max-width: 100%;
      flex: 1 1 auto;
    }

    &--override-checklist {
      display: flex;
      align-items: center;
    }
  }

  &__primary-checklist {
    margin-left: 8px;
  }

  &__add-component-button {
    margin: 0 auto;
  }

  &__remove-component-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
  }

  &__repeat-inputs {
    align-items: flex-end;
  }

  &__freq-summary {
    padding: 8px;

    label {
      font-size: 12px;
    }
  }

  &__interval-input {
    &--readonly {
      align-self: center;
      padding-top: 8px;
    }
  }

  &__component-section-buttons {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    border-bottom: 1px solid $color-border-gray;
    padding: 8px 0px 4px 0;

    & > * {
      flex: 1;

      &:first-child {
        flex: 0;
        color: $color-link;
      }

      &:last-child {
        flex: 0;
      }
    }
  }

  &__preloader-inputs {
    display: flex;
    flex-direction: row;
  }

  &__preloader-input {
    height: 24px;
    width: 100%;
    margin: 8px;
  }

  &__preloader-block {
    height: 50px;
    width: 100%;
    margin: 8px;
  }
}
