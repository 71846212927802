// Fonts
$abx-section-font-size: 16px;
$abx-section-font-color: rgb(33, 33, 33);

// Margins
$abx-section-container-margin: 18px;

// Widths
$abx-section-edit-button-width: 94px;
$abx-section-submit-button-width: 194px;

header.abx-header {
  flex: 1;
  padding: 10px 0;
  font-size: 18px;
  font-weight: normal;
}

.abx-readonly-input-container {
  display: block;
  min-height: 58px;
  flex-direction: column;
  margin-bottom: 42px;
  padding-left: 2px;
  font-size: $abx-section-font-size;

  label:first-of-type {
    padding-left: 2px;
    margin: 0;
    font-weight: bold;
  }
  & > * {
    display: block;
    padding: 2px;
  }
}

abx-input-section,
.abx-input-section,
.abx-activity-section {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  overflow-x: hidden;

  form,
  .abx-section-header,
  .abx-section-content {
    max-width: 100%;
  }

  .abx-section-header {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    @extend .pad-8-bot;

    header {
      @extend .abx-header;
    }

    button.md-button {
      margin: 0 6px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .abx-section-button-container {
    button.md-button {
      margin: 3px 6px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .abx-section-content {
    margin-left: 16px;
    padding-top: 16px;
    font-size: $abx-section-font-size;
    line-height: 26px;

    hr:first-child,
    label {
      font-weight: bold;
      font-size: $abx-section-font-size;
      &.md-placeholder {
        height: 22px;
        top: auto;
        opacity: inherit;
      }
    }
    input.md-input,
    textarea.md-input,
    md-select {
      font-size: $abx-section-font-size;
    }
    md-input-container {
      min-height: 58px;

      label {
        transform: none;
        padding-left: 3px;
        font-size: $abx-section-font-size;
        &.md-required:after {
          font-size: $abx-section-font-size;
        }
      }

      &:not(.md-input-invalid) {
        label.md-required:after {
          color: $blue !important;
        }
      }

      &:not(.md-input-has-value) {
        input.md-input,
        textarea.md-input,
        md-select,
        abx-datepicker {
          &.abx-readonly {
            @extend .text-placeholder;
          }
        }
      }
    }

    // Autocomplete no label
    *[abx-no-label] {
      md-autocomplete {
        margin-top: -$abx-section-container-margin;
        md-input-container {
          label {
            display: none;
          }
        }
      }
    }

    // Valid input
    md-input-container:not(.md-input-invalid) {
      label {
        color: $abx-section-font-color;
      }
      input.md-input,
      textarea.md-input {
        &:read-only,
        &[disabled] {
          color: inherit;
          margin-bottom: 0;
        }
      }
    }

    // Input Focused
    md-input-container.md-input-focused {
      md-select {
        md-select-value.md-select-value.md-select-placeholder {
          span:first-child {
            transform: none;
          }
        }
      }
    }

    md-select {
      height: 30px;

      md-select-value.md-select-value {
        span.abx-select-placeholder {
          display: none;
        }
      }

      &[required]:not(.md-no-asterisk) {
        .md-select-value span:first-child:after {
          display: none;
        }
      }

      &[disabled] {
        pointer-events: none;

        md-select-value.md-select-value {
          margin-bottom: 0;
          color: inherit;
          border-color: transparent;
          background-image: none;

          .md-select-icon {
            display: none;
          }

          &.md-select-placeholder {
            span:first-child {
              display: none;
            }
            span.abx-select-placeholder {
              display: inline-block;
              color: $black-38;
            }
          }
        }
      }
    }

    md-autocomplete {
      md-autocomplete-wrap {
        md-input-container:not(.md-input-invalid) {
          label {
            color: $abx-section-font-color;
          }
        }
      }
      &[disabled] {
        md-autocomplete-wrap {
          label {
            color: $abx-section-font-color;
          }
          input.md-input[type="search"] {
            pointer-events: none;
          }
        }
      }
    }

    abx-simple-autocomplete {
      &.abx-readonly {
        md-input-container:not(.md-input-has-value) {
          input {
            @extend .text-placeholder;
          }
        }
      }
    }
  }

  // Readonly
  &.abx-section-readonly {
    .abx-section-content-wrap {
      max-width: 80%;
    }
    .abx-section-content {
      margin-right: $abx-section-edit-button-width;

      md-input-container {
        label.md-required:after,
        label.md-required:before {
          display: none;
        }
        input.md-input,
        textarea.md-input {
          &:read-only,
          &[disabled] {
            border-color: transparent !important;
            background-image: none;
          }
        }
        a {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        input.md-input {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.md-input-has-value {
          input.md-input,
          textarea.md-input {
            color: $abx-section-font-color;
          }
        }
      }
      md-autocomplete {
        .md-show-clear-button {
          input.md-input {
            padding-right: 2px;
          }
          button {
            display: none;
          }
        }
      }
      .abx-not-provided {
        @extend .text-placeholder;
      }
    }
  }

  // Editing
  &.abx-section-editing {
    .abx-section-content {
      md-input-container {
        input.md-input,
        textarea.md-input {
          &:read-only {
            border-color: transparent !important;
            background-image: none;
          }
        }
      }
      md-autocomplete {
        .md-show-clear-button {
          button {
            display: block;
          }
        }
      }
    }
  }

  // Slim
  &.abx-section-slim {
    .abx-section-content {
      md-input-container,
      .abx-readonly-input-container {
        min-height: 34px;
        margin-bottom: $abx-section-container-margin;
      }
      md-autocomplete {
        md-progress-linear.md-inline {
          bottom: $abx-section-container-margin;
        }
      }
      div.md-errors-spacer,
      ng-messages,
      div[ng-messages] {
        display: none;
      }
    }
  }

  // Header
  &.abx-section-with-header {
    .abx-section-content-wrap {
      flex: 1 0 auto;
      flex-direction: column;
      align-content: stretch;
      align-items: stretch;
      max-width: 100%;
      min-width: 100%;
      width: 100%;

      .abx-section-content {
        margin-right: 16px;
      }

      .abx-section-header {
        header {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &.abx-section-readonly {
      .abx-section-header.with-buttons {
        margin-right: $abx-section-edit-button-width;
      }
    }
    &.abx-section-editing {
      .abx-section-header.with-buttons {
        margin-right: $abx-section-submit-button-width;
      }
    }

    .abx-section-button-container {
      margin-top: 2px;

      .abx-section-submit-container {
        flex-wrap: nowrap;
      }
    }
  }

  // Inline
  &.abx-section-inline {
    .abx-section-content-wrap {
      max-width: 65%;
    }
    .abx-section-content {
      md-input-container,
      .abx-readonly-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 44px;
        margin-top: 0;

        label {
          position: relative;
          bottom: 0;
          max-width: 40%;
          margin-right: 16px;
          text-align: right;

          &.md-required {
            &:after {
              content: none;
            }
            &:before {
              content: "* ";
              vertical-align: top;
              font-size: $abx-section-font-size;
              color: $blue !important;
            }
          }
        }

        & > * {
          order: 2;
          &[abx-custom-input] {
            flex: 1;
          }
        }

        ng-messages,
        div[ng-messages] {
          position: absolute;
          top: 32px;
          left: 40%;
          margin-left: 16px;
        }
      }

      .abx-readonly-input-container {
        label {
          flex: 1;
        }
      }
    }
  }

  /* IE 10+ */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &.abx-section-with-header {
      flex: 1 0 auto;

      .abx-section-content-wrap:not(:last-child) {
        max-width: 55%;
        min-width: 55%;
        width: 55%;
        .abx-section-header {
          margin-right: 0 !important;
        }
      }
      .abx-section-button-container,
      .abx-section-submit-container {
        flex: 1 0 auto;
        float: right;
        display: block;
      }
      .abx-section-submit-container {
        float: right;
      }
    }
    &.abx-section-readonly {
      .abx-section-content {
        md-input-container {
          input.md-input,
          textarea.md-input {
            pointer-events: none !important;
            border-color: transparent !important;
            background-image: none !important;
          }
        }
        .abx-calendar-button {
          display: none;
        }
      }
    }
    .flex {
      flex: 1 0 auto;
    }
    .abx-section-header {
      flex: 1 0 auto;
    }
  }

  /* IE Edge 12+ */
  @supports (-ms-ime-align: auto) {
    &.abx-section-with-header {
      flex: 1 0 auto;

      .abx-section-content-wrap:not(:last-child) {
        max-width: 55%;
        min-width: 55%;
        width: 55%;
        .abx-section-header {
          margin-right: 0 !important;
        }
      }
      .abx-section-button-container,
      .abx-section-submit-container {
        flex: 1;
        float: right;
        display: block;
      }
      .abx-section-submit-container {
        float: right;
      }
    }
    .flex {
      flex: 1 0 auto;
    }
    .abx-section-header {
      flex: 1;
    }
  }
}
