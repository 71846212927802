abx-plan-view-content {
  md-backdrop.md-dialog-backdrop {
    z-index: 1;
  }

  .md-scroll-mask {
    z-index: 1;
  }

  .md-dialog-container {
    z-index: 3;
  }
}

.abx-file-preview-dialog {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;

  @include respond-to(gt-sm) {
    min-width: 90%;
    min-height: 90%;
  }
  @include respond-to(gt-md) {
    min-width: 80%;
  }
  @include respond-to(gt-lg) {
    min-width: 66%;
  }

  &__carousel {
    display: flex;
    white-space: nowrap;
    md-icon {
      color: $color-link;
      font-size: 20px;
      &:hover {
        color: $color-link-hover;
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: $white;

    md-icon {
      color: #009be2;
    }
    &__ocr-toggle,
    &__annotation-toggle {
      md-icon {
        color: $color-medium-gray;
      }
    }
    .toggle-active {
      md-icon {
        color: #009be2;
      }
    }
  }

  .abx-file-preview-dialog__subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: white;
    z-index: 1;
  }

  .abx-file-preview-dialog__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;

    .abx-file-preview-dialog__body {
      display: flex;
      flex: 1;
      flex-direction: row;
    }

    .abx-file-preview-dialog__media {
      flex: 1 1 60%;
      display: flex;
      flex-direction: column;
    }

    .abx-file-preview-dialog__image-data {
      display: flex;
      flex-direction: column;
      background-color: white;
      z-index: 1;
      padding: 8px 24px;

      &__controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        padding-bottom: 8px;

        &__media-viewer {
          display: flex;
          align-items: center;
          a {
            color: $color-dark-gray;
            font-weight: 400;
            font-size: 14px;
          }
          md-icon {
            color: #009be2;
          }
        }

        &__delete {
          display: flex;
          align-items: center;
          a {
            color: $color-dark-gray;
            font-weight: 400;
            font-size: 14px;
          }
          md-icon {
            color: #e4490b;
          }
        }
      }

      &__doc-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        min-height: 64px;

        &__label {
          *md-icon {
            color: #2d8dd9;
          }
        }
        &__edit {
          flex: 1;
          display: flex;
          flex-direction: row;
          .edit-button {
            height: 40px;
            min-width: 77.76px;
            margin-top: 8px;
          }
          :first-child {
            flex: 1;
            padding-top: 3px;
          }
        }
      }

      &__tags {
        &__header {
          font-weight: bold;
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          button.abx-button {
            margin: 0;
          }
        }
        &__empty {
          font-style: italic;
        }
        &__list {
          max-height: 90px;
          overflow: scroll;
        }
      }
    }

    .abx-file-preview-dialog__selected-text {
      flex: 1 1 40%;
      padding: 16px;
      background: white;
      z-index: 2;
      // display: none;

      .abx-file-preview-dialog__selected-text__header {
        font-weight: bold;
        padding: 8px;
        margin-top: 8px;
      }

      .abx-file-preview-dialog__selected-text__content {
        height: 95%;
        border: 2px solid #d9d9d9;
        border-radius: 2px;
        padding: 8px;
      }

      md-input-container > md-icon {
        color: rgb(35, 141, 217);
        top: 3px;
      }

      .abx-file-preview-field-row {
        padding: 8px 16px;
        border-bottom: 1px solid $color-border-gray;
        > div {
          flex: 1 0;
          color: #717171;
          font-weight: normal;
          font-style: italic;
          margin: 0px 5px;
        }
        > a {
          min-width: 108px;
          color: #2072b3;
          font-weight: bold;
        }
      }
    }

    .abx-file-preview-dialog__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 56px;
      min-height: 56px;
      padding: 0 16px;
      background-color: white;
      z-index: 1;
      padding-top: 4px;

      &__undo-message {
        md-icon {
          color: #54be6a;
        }
      }

      &__controls {
        display: flex;
        width: 100px;
        height: 20px;
      }
    }

    .abx-file-preview-dialog__extra-config {
      md-checkbox {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .abx-file-preview-field-list {
      height: 372px;
      overflow: scroll;
      border: 1px solid $color-border-gray;
    }

    .abx-file-preview-dialog__image {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: nowrap;

      &__spacer {
        width: 16px;
        background-color: white;
        z-index: 1;
      }

      .abx-file-preview-dialog__image-preloader {
        display: flex;
        flex: 1;
        border: 1px solid $color-border-gray;

        .image-preloader {
          flex: 1;
          height: 100%;
        }

        .control-container {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          .control-preloader {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin: 4px;
          }
        }
      }

      .media-viewer-wrapper {
        display: flex;
        width: 300px;
        height: auto;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        overflow: unset;
        background-color: transparent;
      }

      .media-viewer-image {
        background-color: $color-light-gray;
        border: 1px solid $color-border-gray;

        .control-container {
          position: absolute;
          top: 0;
          right: 0;
          left: unset;
          bottom: unset;
        }

        .control-container__button {
          width: 48px;
          height: 48px;
          font-size: 22px;
          visibility: hidden;
        }
      }

      .pre-process-ocr-text-container,
      .pre-process-ocr-text-canvas {
        transition: transform 0.5s ease-in-out;
      }

      .file-preview-dialog__image-viewer-button {
        @extend .elevation-frame-6dp;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-secondary;
        width: 48px;
        height: 48px;
        margin: 4px;

        md-icon {
          color: $white;
        }

        &.pre-process-ocr-text-button {
          top: 224px; // place beneath other buttons
        }
      }

      .pre-process-ocr-text-box {
        position: absolute;

        &.selected {
          box-shadow: 0 0 0 3px $ocr-text-outline-color;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .disabled {
    md-icon {
      color: $color-medium-gray !important;
    }
    color: $color-medium-gray;
    pointer-events: none;
  }

  .attach-file-icon-large {
    font-size: 150px;
    transform: translate(-300%);
  }
}
