.bulk-delete-asset-dialog {
  color: $color-text;

  &__list-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 8px;

    p {
      padding-left: 28px;
      margin-bottom: 0px;
    }
  }

  &__success {
    color: $color-success;
  }
  &__error {
    color: $color-error;
  }
}
