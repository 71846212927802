abx-y-axis-label {
  align-items: center;
  display: flex;

  .abx-y-axis-label__container {
    margin-left: -137px;
    margin-right: -118px;
    transform: rotate(-90deg);
    text-align: center;
    width: 305px;
  }
}
