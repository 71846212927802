.thumbnail-fit-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: $white-F9;

  abx-bldg-thumbnail {
    min-height: 176px;
  }

  .thumbnail-fit-dialog-content__fit-options {
    margin-top: 16px;
  }
}
