.decommission-dialog {
  color: $color-text;
  background-color: white;

  &__list {
    display: flex;
    flex-direction: column;

    * > {
      flex: 1 1 0;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;

    * > {
      flex: 1 1 0;
    }
  }

  &__list-item-detail {
    align-items: center;
    border-bottom: 1px solid #d9d9d9; // TODO: find the actual color var
    color: #2d8dd9;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: flex-start;
    padding: 8px 0;
    cursor: pointer;
  }

  &__list-item-title {
    margin-right: 3px;
  }

  &__nav-button {
    flex: 1 1 0;
    text-align: right;
  }

  &__date-input {
    display: flex;
    flex-direction: column;
    margin: 1em 0;

    * > {
      flex: 1 1 0;
    }
  }

  &__error {
    text-align: center;
    color: red;
  }
}
