/********************
Common style
********************/
.pin-icon {
  height: 2em;
  background-repeat: no-repeat;
  /*background-size: contain;*/
  background-size: 90%;
  background-position: center center;
}

/********************
White icons
********************/
.aed_w {
  background-image: url("../img/pin_icons/aed_w.svg");
}
.announce_w {
  background-image: url("../img/pin_icons/announce_w.svg");
}
.air_compressor_w {
  background-image: url("../img/pin_icons/air_compressor_w.svg");
}
.air_curtain_w {
  background-image: url("../img/pin_icons/air_curtain_w.svg");
}
.bed_w {
  background-image: url("../img/pin_icons/bed_w.svg");
}
.bell_w {
  background-image: url("../img/pin_icons/bell_w.svg");
}
.boiler_w {
  background-image: url("../img/pin_icons/boiler_w.svg");
}
.box_w {
  background-image: url("../img/pin_icons/box_w.svg");
}
.button_w {
  background-image: url("../img/pin_icons/button_w.svg");
}
.car_w {
  background-image: url("../img/pin_icons/car_w.svg");
}
.card_reader_w {
  background-image: url("../img/pin_icons/card_reader_w.svg");
}
.chiller_w {
  background-image: url("../img/pin_icons/chiller_w.svg");
}
.circle_w {
  background-image: url("../img/pin_icons/circle_w.svg");
}
.cooling_towers_w {
  background-image: url("../img/pin_icons/cooling_towers_w.svg");
}
.damper_w {
  background-image: url("../img/pin_icons/damper_w.svg");
}
.data_w {
  background-image: url("../img/pin_icons/data_w.svg");
}
.diamond_w {
  background-image: url("../img/pin_icons/diamond_w.svg");
}
.door_w {
  background-image: url("../img/pin_icons/door_w.svg");
  background-position-y: 1px;
}
.drinking_fountain_w {
  background-image: url("../img/pin_icons/drinking_fountain_w.svg");
}
.flag_w {
  background-image: url("../img/pin_icons/flag_w.svg");
}
.electrical_motor_w {
  background-image: url("../img/pin_icons/electrical_motor_w.svg");
}
.electrical_panel_w {
  background-image: url("../img/pin_icons/electrical_panel_w.svg");
}
.elevator_w {
  background-image: url("../img/pin_icons/elevator_w.svg");
}
.escalator_w {
  background-image: url("../img/pin_icons/escalator_w.svg");
}
.exit_light_w {
  background-image: url("../img/pin_icons/exit_light_w.svg");
}
.eye_wash_w {
  background-image: url("../img/pin_icons/eye_wash_w.svg");
}
.fan_w {
  background-image: url("../img/pin_icons/fan_w.svg");
}
.flammable_w {
  background-image: url("../img/pin_icons/flammable_w.svg");
}
.fire_extinguisher_w {
  background-image: url("../img/pin_icons/fire_extinguisher_w.svg");
  background-position-x: -1px;
  background-position-y: 1px;
}
.fire_protection_w {
  background-image: url("../img/pin_icons/fire_protection_w.svg");
}
.foundation_w {
  background-image: url("../img/pin_icons/foundation_w.svg");
}
.gear_w {
  background-image: url("../img/pin_icons/gear_w.svg");
}
.general_healthcare_w {
  background-image: url("../img/pin_icons/general_healthcare_w.svg");
}
.generator_w {
  background-image: url("../img/pin_icons/generator_w.svg");
}
.generator_symbol_w {
  background-image: url("../img/pin_icons/generator_symbol_w.svg");
}
.hammer_w {
  background-image: url("../img/pin_icons/hammer_w.svg");
}
.heat_exchanger_w {
  background-image: url("../img/pin_icons/heat_exchanger_w.svg");
}
.heater_w {
  background-image: url("../img/pin_icons/heater_w.svg");
}
.hexagon_w {
  background-image: url("../img/pin_icons/hexagon_w.svg");
}
.horn_strobe_w {
  background-image: url("../img/pin_icons/horn_strobe_w.svg");
}
.key_w {
  background-image: url("../img/pin_icons/key_w.svg");
}
.laptop_w {
  background-image: url("../img/pin_icons/laptop_w.svg");
}
.lift_w {
  background-image: url("../img/pin_icons/lift_w.svg");
}
.light_bulb_w {
  background-image: url("../img/pin_icons/light_bulb_w.svg");
}
.lightning_w {
  background-image: url("../img/pin_icons/lightning_w.svg");
}
.ventilator_w {
  background-image: url("../img/pin_icons/ventilator_w.svg");
}
.manifold_w {
  background-image: url("../img/pin_icons/manifold_w.svg");
}
.medical_gas_w {
  background-image: url("../img/pin_icons/medical_gas_w.svg");
}
.microphone_w {
  background-image: url("../img/pin_icons/microphone_w.svg");
}
.monitor_w {
  background-image: url("../img/pin_icons/monitor_w.svg");
}
.navigation_w {
  background-image: url("../img/pin_icons/navigation_w.svg");
}
.outlet_w {
  background-image: url("../img/pin_icons/outlet_w.svg");
}
.phone_w {
  background-image: url("../img/pin_icons/phone_w.svg");
}
.plug_w {
  background-image: url("../img/pin_icons/plug_w.svg");
}
.pull_station_w {
  background-image: url("../img/pin_icons/pull_station_w.svg");
}
.pump_w {
  background-image: url("../img/pin_icons/pump_w.svg");
}
.remote_w {
  background-image: url("../img/pin_icons/remote_w.svg");
}
.restroom_w {
  background-image: url("../img/pin_icons/restroom_w.svg");
}
.rolling_door_w {
  background-image: url("../img/pin_icons/rolling_door_w.svg");
}
.roof_w {
  background-image: url("../img/pin_icons/roof_w.svg");
}
.room_w {
  background-image: url("../img/pin_icons/room_w.svg");
  background-size: 80%;
}
.ro_unit_w {
  background-image: url("../img/pin_icons/ro_unit_w.svg");
}
.router_w {
  background-image: url("../img/pin_icons/router_w.svg");
}
.sliding_door_w {
  background-image: url("../img/pin_icons/sliding_door_w.svg");
}
.smoke_detector_w {
  background-image: url("../img/pin_icons/smoke_detector_w.svg");
}
.snowflake_w {
  background-image: url("../img/pin_icons/snowflake_w.svg");
}
.spa_w {
  background-image: url("../img/pin_icons/spa_w.svg");
}
.square_w {
  background-image: url("../img/pin_icons/square_w.svg");
}
.star_w {
  background-image: url("../img/pin_icons/star_w.svg");
}
.steam_w {
  background-image: url("../img/pin_icons/steam_w.svg");
}
.stairs_w {
  background-image: url("../img/pin_icons/stairs_w.svg");
}
.superstructure_w {
  background-image: url("../img/pin_icons/superstructure_w.svg");
}
.swatches_w {
  background-image: url("../img/pin_icons/swatches_w.svg");
}
.surgical_boom_w {
  background-image: url("../img/pin_icons/surgical_boom_w.svg");
}
.triangle_w {
  background-image: url("../img/pin_icons/triangle_w.svg");
  background-position-y: -1px;
}
.truck_w {
  background-image: url("../img/pin_icons/truck_w.svg");
}
.up_down_w {
  background-image: url("../img/pin_icons/up_down_w.svg");
}
.valve_w {
  background-image: url("../img/pin_icons/valve_w.svg");
}
.water_w {
  background-image: url("../img/pin_icons/water_w.svg");
}
.wheel_chair_w {
  background-image: url("../img/pin_icons/wheel_chair_w.svg");
}
.akitabox_w {
  background-image: url("../img/pin_icons/akitabox_w.svg");
  background-size: 125%;
}
.appliances_w {
  background-image: url("../img/pin_icons/appliances_w.svg");
  background-size: 100%;
}
.radioactive_w {
  background-image: url("../img/pin_icons/radioactive_w.svg");
  background-size: 100%;
}
.skull_w {
  background-image: url("../img/pin_icons/skull_w.svg");
  background-size: 100%;
}
.biohazard_w {
  background-image: url("../img/pin_icons/biohazard_w.svg");
  background-size: 120%;
}
.security_w {
  background-image: url("../img/pin_icons/security_w.svg");
  background-size: 100%;
}
.double_door_w {
  background-image: url("../img/pin_icons/double_door_w.svg");
  background-size: 100%;
}
.fire_door_w {
  background-image: url("../img/pin_icons/fire_door_w.svg");
  background-size: 100%;
}
.revolving_door_w {
  background-image: url("../img/pin_icons/revolving_door_w.svg");
  background-size: 100%;
}
.fire_pump_w {
  background-image: url("../img/pin_icons/fire_pump_w.svg");
  background-size: 100%;
}
.data_port_w {
  background-image: url("../img/pin_icons/data_port_w.svg");
  background-size: 100%;
}
.doccam_w {
  background-image: url("../img/pin_icons/doccam_w.svg");
  background-size: 100%;
}
.electronic_white_board_w {
  background-image: url("../img/pin_icons/electronic_white_board_w.svg");
  background-size: 100%;
}
.networking_w {
  background-image: url("../img/pin_icons/networking_w.svg");
  background-size: 100%;
}
.printer_w {
  background-image: url("../img/pin_icons/printer_w.svg");
  background-size: 100%;
}
.projector_w {
  background-image: url("../img/pin_icons/projector_w.svg");
  background-size: 100%;
}
.tub_w {
  background-image: url("../img/pin_icons/tub_w.svg");
}
.ups_w {
  background-image: url("../img/pin_icons/ups_w.svg");
  background-size: 100%;
}
.washer_w {
  background-image: url("../img/pin_icons/washer_w.svg");
}
.water_filter_w {
  background-image: url("../img/pin_icons/water_filter_w.svg");
}
.ahu_w {
  background-image: url("../img/pin_icons/ahu_w.svg");
  background-size: 100%;
}
.medgas_manifold_w {
  background-image: url("../img/pin_icons/medgas_manifold_w.svg");
  background-size: 100%;
}
.medgas_outlet_w {
  background-image: url("../img/pin_icons/medgas_outlet_w.svg");
  background-size: 100%;
}
.medgas_panel_w {
  background-image: url("../img/pin_icons/medgas_panel_w.svg");
  background-size: 100%;
}
.medgas_valve_box_w {
  background-image: url("../img/pin_icons/medgas_valve_box_w.svg");
  background-size: 100%;
}
.ice_machine_w {
  background-image: url("../img/pin_icons/ice_machine_w.svg");
  background-size: 100%;
}
.camera_w {
  background-image: url("../img/pin_icons/camera_w.svg");
  background-size: 100%;
}
.wifi_w {
  background-image: url("../img/pin_icons/wifi_w.svg");
  background-size: 100%;
}
.wrench_w {
  background-image: url("../img/pin_icons/wrench_w.svg");
}

/********************
Black icons
********************/
.aed_b {
  background-image: url("../img/pin_icons/aed_b.svg");
}
.announce_b {
  background-image: url("../img/pin_icons/announce_b.svg");
}
.air_compressor_b {
  background-image: url("../img/pin_icons/air_compressor_b.svg");
}
.air_curtain_b {
  background-image: url("../img/pin_icons/air_curtain_b.svg");
}
.bed_b {
  background-image: url("../img/pin_icons/bed_b.svg");
}
.bell_b {
  background-image: url("../img/pin_icons/bell_b.svg");
}
.boiler_b {
  background-image: url("../img/pin_icons/boiler_b.svg");
}
.box_b {
  background-image: url("../img/pin_icons/box_b.svg");
}
.button_b {
  background-image: url("../img/pin_icons/button_b.svg");
}
.car_b {
  background-image: url("../img/pin_icons/car_b.svg");
}
.card_reader_b {
  background-image: url("../img/pin_icons/card_reader_b.svg");
}
.chiller_b {
  background-image: url("../img/pin_icons/chiller_b.svg");
}
.circle_b {
  background-image: url("../img/pin_icons/circle_b.svg");
}
.cooling_towers_b {
  background-image: url("../img/pin_icons/cooling_towers_b.svg");
}
.damper_b {
  background-image: url("../img/pin_icons/damper_b.svg");
}
.data_b {
  background-image: url("../img/pin_icons/data_b.svg");
}
.diamond_b {
  background-image: url("../img/pin_icons/diamond_b.svg");
}
.door_b {
  background-image: url("../img/pin_icons/door_b.svg");
  background-position-y: 1px;
}
.drinking_fountain_b {
  background-image: url("../img/pin_icons/drinking_fountain_b.svg");
}
.flag_b {
  background-image: url("../img/pin_icons/flag_b.svg");
}
.electrical_motor_b {
  background-image: url("../img/pin_icons/electrical_motor_b.svg");
}
.electrical_panel_b {
  background-image: url("../img/pin_icons/electrical_panel_b.svg");
}
.elevator_b {
  background-image: url("../img/pin_icons/elevator_b.svg");
}
.escalator_b {
  background-image: url("../img/pin_icons/escalator_b.svg");
}
.exit_light_b {
  background-image: url("../img/pin_icons/exit_light_b.svg");
}
.eye_wash_b {
  background-image: url("../img/pin_icons/eye_wash_b.svg");
}
.fan_b {
  background-image: url("../img/pin_icons/fan_b.svg");
}
.flammable_b {
  background-image: url("../img/pin_icons/flammable_b.svg");
}
.fire_extinguisher_b {
  background-image: url("../img/pin_icons/fire_extinguisher_b.svg");
  background-position-x: -1px;
  background-position-y: 1px;
}
.fire_protection_b {
  background-image: url("../img/pin_icons/fire_protection_b.svg");
}
.foundation_b {
  background-image: url("../img/pin_icons/foundation_b.svg");
}
.gear_b {
  background-image: url("../img/pin_icons/gear_b.svg");
}
.general_healthcare_b {
  background-image: url("../img/pin_icons/general_healthcare_b.svg");
}
.generator_b {
  background-image: url("../img/pin_icons/generator_b.svg");
}
.generator_symbol_b {
  background-image: url("../img/pin_icons/generator_symbol_b.svg");
}
.hammer_b {
  background-image: url("../img/pin_icons/hammer_b.svg");
}
.heat_exchanger_b {
  background-image: url("../img/pin_icons/heat_exchanger_b.svg");
}
.heater_b {
  background-image: url("../img/pin_icons/heater_b.svg");
}
.hexagon_b {
  background-image: url("../img/pin_icons/hexagon_b.svg");
}
.horn_strobe_b {
  background-image: url("../img/pin_icons/horn_strobe_b.svg");
}
.key_b {
  background-image: url("../img/pin_icons/key_b.svg");
}
.laptop_b {
  background-image: url("../img/pin_icons/laptop_b.svg");
}
.lift_b {
  background-image: url("../img/pin_icons/lift_b.svg");
}
.light_bulb_b {
  background-image: url("../img/pin_icons/light_bulb_b.svg");
}
.lightning_b {
  background-image: url("../img/pin_icons/lightning_b.svg");
}
.ventilator_b {
  background-image: url("../img/pin_icons/ventilator_b.svg");
}
.manifold_b {
  background-image: url("../img/pin_icons/manifold_b.svg");
}
.medical_gas_b {
  background-image: url("../img/pin_icons/medical_gas_b.svg");
}
.microphone_b {
  background-image: url("../img/pin_icons/microphone_b.svg");
}
.monitor_b {
  background-image: url("../img/pin_icons/monitor_b.svg");
}
.navigation_b {
  background-image: url("../img/pin_icons/navigation_b.svg");
}
.outlet_b {
  background-image: url("../img/pin_icons/outlet_b.svg");
}
.phone_b {
  background-image: url("../img/pin_icons/phone_b.svg");
}
.plug_b {
  background-image: url("../img/pin_icons/plug_b.svg");
}
.pull_station_b {
  background-image: url("../img/pin_icons/pull_station_b.svg");
}
.pump_b {
  background-image: url("../img/pin_icons/pump_b.svg");
}
.remote_b {
  background-image: url("../img/pin_icons/remote_b.svg");
}
.restroom_b {
  background-image: url("../img/pin_icons/restroom_b.svg");
}
.rolling_door_b {
  background-image: url("../img/pin_icons/rolling_door_b.svg");
}
.roof_b {
  background-image: url("../img/pin_icons/roof_b.svg");
}
.room_b {
  background-image: url("../img/pin_icons/room_b.svg");
  background-size: 80%;
}
.ro_unit_b {
  background-image: url("../img/pin_icons/ro_unit_b.svg");
}
.router_b {
  background-image: url("../img/pin_icons/router_b.svg");
}
.sliding_door_b {
  background-image: url("../img/pin_icons/sliding_door_b.svg");
}
.smoke_detector_b {
  background-image: url("../img/pin_icons/smoke_detector_b.svg");
}
.snowflake_b {
  background-image: url("../img/pin_icons/snowflake_b.svg");
}
.spa_b {
  background-image: url("../img/pin_icons/spa_b.svg");
}
.square_b {
  background-image: url("../img/pin_icons/square_b.svg");
}
.star_b {
  background-image: url("../img/pin_icons/star_b.svg");
}
.steam_b {
  background-image: url("../img/pin_icons/steam_b.svg");
}
.stairs_b {
  background-image: url("../img/pin_icons/stairs_b.svg");
}
.superstructure_b {
  background-image: url("../img/pin_icons/superstructure_b.svg");
}
.swatches_b {
  background-image: url("../img/pin_icons/swatches_b.svg");
}
.surgical_boom_b {
  background-image: url("../img/pin_icons/surgical_boom_b.svg");
}
.triangle_b {
  background-image: url("../img/pin_icons/triangle_b.svg");
  background-position-y: -1px;
}
.truck_b {
  background-image: url("../img/pin_icons/truck_b.svg");
}
.up_down_b {
  background-image: url("../img/pin_icons/up_down_b.svg");
}
.valve_b {
  background-image: url("../img/pin_icons/valve_b.svg");
}
.water_b {
  background-image: url("../img/pin_icons/water_b.svg");
}
.wheel_chair_b {
  background-image: url("../img/pin_icons/wheel_chair_b.svg");
}
.akitabox_b {
  background-image: url("../img/pin_icons/akitabox_b.svg");
  background-size: 125%;
}
.appliances_b {
  background-image: url("../img/pin_icons/appliances_b.svg");
  background-size: 100%;
}
.radioactive_b {
  background-image: url("../img/pin_icons/radioactive_b.svg");
  background-size: 100%;
}
.skull_b {
  background-image: url("../img/pin_icons/skull_b.svg");
  background-size: 100%;
}
.biohazard_b {
  background-image: url("../img/pin_icons/biohazard_b.svg");
  background-size: 120%;
}
.security_b {
  background-image: url("../img/pin_icons/security_b.svg");
  background-size: 100%;
}
.double_door_b {
  background-image: url("../img/pin_icons/double_door_b.svg");
  background-size: 100%;
}
.fire_door_b {
  background-image: url("../img/pin_icons/fire_door_b.svg");
  background-size: 100%;
}
.revolving_door_b {
  background-image: url("../img/pin_icons/revolving_door_b.svg");
  background-size: 100%;
}
.fire_pump_b {
  background-image: url("../img/pin_icons/fire_pump_b.svg");
  background-size: 100%;
}
.data_port_b {
  background-image: url("../img/pin_icons/data_port_b.svg");
  background-size: 100%;
}
.doccam_b {
  background-image: url("../img/pin_icons/doccam_b.svg");
  background-size: 100%;
}
.electronic_white_board_b {
  background-image: url("../img/pin_icons/electronic_white_board_b.svg");
  background-size: 100%;
}
.networking_b {
  background-image: url("../img/pin_icons/networking_b.svg");
  background-size: 100%;
}
.printer_b {
  background-image: url("../img/pin_icons/printer_b.svg");
  background-size: 100%;
}
.projector_b {
  background-image: url("../img/pin_icons/projector_b.svg");
  background-size: 100%;
}
.tub_b {
  background-image: url("../img/pin_icons/tub_b.svg");
}
.ups_b {
  background-image: url("../img/pin_icons/ups_b.svg");
  background-size: 100%;
}
.washer_b {
  background-image: url("../img/pin_icons/washer_b.svg");
}
.water_filter_b {
  background-image: url("../img/pin_icons/water_filter_b.svg");
}
.ahu_b {
  background-image: url("../img/pin_icons/ahu_b.svg");
  background-size: 100%;
}
.medgas_manifold_b {
  background-image: url("../img/pin_icons/medgas_manifold_b.svg");
  background-size: 100%;
}
.medgas_outlet_b {
  background-image: url("../img/pin_icons/medgas_outlet_b.svg");
  background-size: 100%;
}
.medgas_panel_b {
  background-image: url("../img/pin_icons/medgas_panel_b.svg");
  background-size: 100%;
}
.medgas_valve_box_b {
  background-image: url("../img/pin_icons/medgas_valve_box_b.svg");
  background-size: 100%;
}
.ice_machine_b {
  background-image: url("../img/pin_icons/ice_machine_b.svg");
  background-size: 100%;
}
.camera_b {
  background-image: url("../img/pin_icons/camera_b.svg");
  background-size: 100%;
}
.wifi_b {
  background-image: url("../img/pin_icons/wifi_b.svg");
  background-size: 100%;
}
.wrench_b {
  background-image: url("../img/pin_icons/wrench_b.svg");
}
