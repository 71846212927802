abx-round-sidebar {
  display: flex;
  width: 100%;

  @include respond-to(gt-xs) {
    width: 50%;
  }
  @include respond-to(gt-md) {
    width: 33%;
  }
  @include respond-to(gt-lg) {
    width: 25%;
  }

  .abx-sidebar--round {
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: $white;
  }
  .abx-round-section {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .abx-round-header {
      padding: 8px 16px;
      font-size: 16px;
      display: flex;
      align-items: center;

      .abx-round-header__number {
        white-space: nowrap;
      }

      &__icons {
        margin-left: auto;

        button.md-button {
          margin: 0 -8px 0 0;
        }
        i {
          color: $color-link;
        }
      }
    }

    .abx-round-task-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      height: 48px;
      min-height: 48px;
      padding: 0 16px;
      font-size: 12px;

      .abx-round-task-info-item {
        display: flex;
        flex-direction: column;

        &__label {
          @extend .text-bold;
          @extend .text-uppercase;
        }
      }

      @include respond-to(gt-sm) {
        height: 32px;
        min-height: 32px;
        .abx-round-task-info-item {
          flex-direction: row;
          &__label {
            margin-right: 8px;
          }
        }
      }
    }

    .abx-round-stop-list-preloader {
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 42px;
        padding: 8px 16px;
        background-color: $color-light-gray;
        color: $color-text;
        border-top: 1px solid $color-border-gray;
        border-bottom: 1px solid $color-border-gray;
      }
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 54px;
        padding: 8px 16px;
        border-bottom: 1px solid $color-border-gray;

        .preloader-block {
          margin-right: 16px;
        }
      }
      .abx-round-stop-list-preloader-item-info {
        display: flex;
        flex-direction: column;
        .preloader:first-child {
          margin-bottom: 4px;
        }
      }
    }
  }

  .abx-round-task-complete-section {
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__notice {
      display: flex;
      font-size: 14px;
      color: $color-dark-gray;
      font-style: italic;
      margin-bottom: 8px;
    }
    abx-button {
      display: flex;
    }
  }

  abx-round-stop-list {
    flex-basis: 42px;
    transition: flex-basis 0.1s ease-out;

    /** the __expanded class is added by the child component itself **/
    &.abx-stop-list__expanded {
      transition: flex-basis 0.5s ease-in;
      flex-basis: 100%;
    }
  }
}
