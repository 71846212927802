.catalog-items-selection {
  display: flex;
  flex-direction: column;

  &__search-input {
    max-width: 30%;
  }

  &__items-list {
    margin-bottom: 16px;

    md-content.abx-list {
      max-height: 300px;
      padding-bottom: 0;

      abx-list-item {
        &:last-of-type {
          margin-bottom: 0;
        }

        & .text-placeholder {
          margin: 8px 0;
        }
      }
    }
  }

  &__details {
    gap: 16px;
    padding: 16px;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid $color-border-gray;

    &__description {
      p:last-of-type {
        margin: 0;
      }
    }

    &__cost {
      gap: 8px;
      display: flex;
      align-items: center;

      abx-input {
        flex: 1;
      }
    }
  }
}
