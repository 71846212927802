abx-simple-dropdown {
  @extend .elevation-frame-4dp;
  display: block;
  background-color: $white;
  z-index: 10;
  font-size: 0.875rem;

  /* Positioning should be handled on this element by the PARENT. Make sure the parent has 'position: relative' etc. */

  .abx-simple-dropdown__list {
    list-style: none;
    margin: 0 0 0.42em;
    min-width: 242px;
    padding: 0;

    &__item {
      &:not(:first-child) {
        border-top: 1px solid $color-border-gray;
      }

      &__button,
      &__icon-button {
        outline: none;
        border: none;
        font-family: $text-a-font-family;
      }
      &__icon-button {
        height: 3em;

        i {
          color: $color-dark-gray;
          margin-right: 10px;
        }
      }
      &__link,
      &__button {
        height: 2.5em;
      }
      &__link,
      &__button,
      &__icon-button {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        width: 100%;
        color: $color-text;
        font-weight: bold;
        letter-spacing: 0.26px !important;
        line-height: 17px;
        padding: 0 12px;

        /* Replicate transitions available in react-client */
        transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

        /* The colors explicity set here are to replicate the hover effects present on Buttons but not on links */
        &.selected {
          background-color: $secondary-1;
        }
        &:not(.selected) {
          background-color: $color-white;

          &:hover {
            background-color: rgba($color-link, 0.1);
          }
        }

        &::after,
        &::before {
          background-color: black;
          top: calc(50% - 50%);
          left: calc(50% - 50%);
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          pointer-events: none;
          content: "";
        }

        &::before {
          transition: opacity 15ms linear;
          z-index: 1;
        }
        &::after {
          transition: opacity 150ms linear;
        }

        &:hover::before {
          opacity: 0.04;
        }
        &:focus::before {
          transition-duration: 75ms;
          opacity: 0.12;
        }

        &:active::after {
          transition-duration: 75ms;
          opacity: 0.16;
        }
      }
    }
  }
}
