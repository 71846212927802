abx-step {
  display: flex;
  position: relative;
  max-height: 160px;
  flex-direction: column;
  align-content: center;
  flex: 1 0 48px;
  background-color: $white;

  & > div {
    max-height: 160px;
  }

  .success {
    color: $green-5C;
  }
  .error {
    color: $red-FF;
  }
  .canceled {
    color: $gray-light;
  }
  .pending {
    color: $color-medium-gray;
  }
  .no-changes {
    color: $color-dark-gray;
  }

  .step-info {
    height: 48px;
    min-height: 48px;
    max-height: 48px;

    .step-status {
      width: 72px;
      min-width: 72px;
      max-width: 72px;
      padding: 8px 16px;
    }
    .step-name {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 8px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 2em;
        height: 100%;
        background: linear-gradient(
          -90deg,
          #fff 0,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
    .step-duration {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 8px;

      md-icon {
        margin: 4px 8px 0 0;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 2em;
        height: 100%;
        background: linear-gradient(
          -90deg,
          #fff 0,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .step-error-preview {
    overflow-y: hidden;
    padding: 0 16px;
    margin-bottom: 8px;
    font-size: 12px;
    &.ng-hide-remove.ng-hide-remove-active {
      animation: fadeIn 1s;
    }
  }
  &.faded-log {
    .step-error-preview {
      // margin-bottom: 0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 32px;
        background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
      }
    }
  }
}
