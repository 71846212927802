abx-round-info {
  display: block;
  position: relative;

  .abx-round-info {
    &__empty,
    &__loaded,
    &__loading,
    &__placeholder {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-content: center;
    }
    &__empty,
    &__loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      height: 100%;
      margin: 6px 8px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      border: 2px solid $color-border-gray;
      border-radius: 2px;
    }
    &__loaded {
      z-index: 1;
      &--invisible {
        visibility: hidden;
      }
    }
  }

  .abx-round-info-view-all {
    text-align: center;
  }

  .abx-round-info-item-preloader {
    height: 44px; // height of button + border
    margin: 6px 8px;
  }

  .abx-round-info-item-preloader {
    @extend .preloader;
    @extend .preloader--pulse;
    border-radius: 2px;
  }

  .abx-round-info-item {
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 8px;

    .abx-round-info-stop {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }

    .material-icons {
      @extend .md-20;
      margin-right: 8px;
      color: $color-link;
      fill: $color-link;
    }
  }

  button.abx-button.btn-secondary,
  a.abx-button.btn-secondary {
    min-height: 40px;
    text-align: center;

    ng-transclude {
      max-width: 80%;
      word-break: keep-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .material-icons {
      @extend .md-20;
      margin-right: 8px;
      color: $color-medium-gray;
      fill: $color-medium-gray;
    }
  }
}
