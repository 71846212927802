abx-document-list-input {
  display: block;
}
.abx-document-list-input {
  display: block;
  padding: 8px 0;
  .abx-document-list-input__header {
    display: flex;
    justify-content: space-between;

    .md-button {
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      padding: 8px 4px 0px;
      min-height: initial;
      min-width: initial;
    }
  }

  .abx-document-list-input__label {
    @extend %abx-input-label;
  }

  .abx-document-list-input__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .abx-document-list-input__separator {
    background-color: $color-medium-gray;
    border-color: $color-medium-gray;
    color: $color-medium-gray;
    margin: 0;
  }

  .abx-document-list-input__no-documents-indicator {
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: $color-dark-gray;
  }

  .abx-document-list-input__list {
    list-style-type: none;
    padding-left: 8px;
    position: relative;

    .abx-document-list-input__overlay {
      background-color: $color-light-gray;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      min-height: 20px;
    }
    .abx-document-list-input__list-item {
      padding-top: 10px;
      padding-bottom: 10px;

      md-icon {
        color: #2196f3;
      }

      &:not(.abx-document-list-input__list-item--last) {
        border-bottom: 1px solid $color-border-gray;
      }
      .abx-document-list-input__list-text {
        padding-left: 8px;
        overflow: hidden;

        .abx-document-list-input__list-text--ocr {
          @extend .text-uppercase;
          margin-top: 2px;
          font-weight: bold;
        }

        .abx-document-list-input__list-text-item--name {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        .list-text-item-name__ocr {
          font-size: 8pt;
          margin-bottom: 2px;
        }

        .abx-document-list-input__list-text-item--archived {
          color: $color-medium-gray;
          font-size: 10px;
          font-weight: 600;
          display: block;
        }
      }

      .abx-document-list-input__list-archived {
        font-size: 12px;
      }
    }
  }
}
