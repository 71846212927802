.md-tooltip {
  color: $white !important;
  border-color: $color-medium-gray !important;
  background-color: $color-text !important;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  opacity: 0.95 !important;

  &::after {
    display: block;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    border-right-color: $color-text;
    border-bottom-color: $color-text;
    content: "";
    border-width: 6px;
    box-shadow: 1px 1px 0 0 $color-medium-gray;
  }

  &.md-origin-bottom::after,
  &.md-origin-bottom::before {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(50%) rotate(225deg);
  }

  &.md-origin-top::after,
  &.md-origin-top::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &.md-origin-left::after,
  &.md-origin-left::before {
    top: 50%;
    left: 100%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &.md-origin-right::after,
  &.md-origin-right::before {
    top: 50%;
    right: 100%;
    transform: translateX(50%) translateY(-50%) rotate(135deg);
  }
}
