abx-note-input {
  form.note-input-form {
    padding: 8px 16px;

    // For two-way disabled
    md-input-container.note-input-container {
      padding-top: 8px;

      ::-webkit-input-placeholder {
        /* Edge */
        font-style: italic;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 11 */
        font-style: italic;
      }

      ::placeholder {
        font-style: italic;
      }

      input.note-input-input {
        margin-bottom: 8px;
      }
    }

    .note-input-btns {
      .btn-text {
        vertical-align: super;
      }
      .btn-icon {
        color: $gray-666 !important; // in order to prevent hover fade
        margin-right: 8px;
      }
    }

    // For two-way enabled
    div.note-input-container {
      margin-right: 8px; // To force the text area to fit properly

      textarea::-webkit-input-placeholder {
        /* Edge */
        font-style: italic;
      }

      textarea:-ms-input-placeholder {
        /* Internet Explorer 11 */
        font-style: italic;
      }

      textarea::placeholder {
        font-style: italic;
      }

      .note-input-input {
        padding: 10px;
        font-size: 12pt;
        margin: 8px 8px;
        background-color: #eee;
        border: 2px solid #d9d9d9;
        border-radius: 2px;
        resize: none;
        width: 100%;
      }
    }

    md-checkbox {
      margin: 0;
    }

    .btnWidth {
      width: 50%;
    }
  }
}

#wrapper {
  width: 100%;
  margin: 15px auto;
  position: relative;
  background: #eee;
}

#input-box {
  min-height: 120px;
  padding: 10px;
  font-size: 12pt;
  // margin: 8px 8px;
  background-color: #eee;
  border: 2px solid #d9d9d9;
  border-radius: 2px;
  outline: none;

  &:focus {
    // outline: none;
    // border-color: $color-black;
    border: 2px solid $color-black;
    border-radius: 2px;
    transition: border-bottom 0.3s;
  }
}
.mention {
  display: inline-block;
  position: relative;
  z-index: 9990;
}
.mention:not(.ignore) {
  padding: 0px;
  color: $color-graph-2;
  z-index: 9991;
}
.mention:not(.ignore).focused {
  background-color: $color-graph-3;
}

.invalid {
  display: inline-block;
  position: relative;
  z-index: 9990;
}
// .mention.ignore {
// 	/*border: 1px solid black;*/
// }
.mention.ignore.focused {
  background-color: $color-graph-3;
}

.hidden {
  display: none;
}
#mention-select-choices {
  position: absolute;
  margin-top: 25px;
  margin-left: -10px;
  border: 1px solid $color-border-gray;
  background: $white;
  // border: solid;
  border-radius: 5px;
  user-select: none;
  min-width: 200px;
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
#mention-select-choices ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#mention-select-choices li {
  padding: 10px;
}
#mention-select-choices li.hover {
  cursor: pointer;
  background: #eeefee;
}

.mention-list-user {
  color: $color-dark-gray;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.mention-list-user-email {
  color: $color-medium-gray;
}
.mention-list-user-no-access {
  color: $color-dark-gray;
  // font-weight: 600;
  font-size: 14px;
  font-style: italic;
}
.mention-list-user-access-message {
  color: $color-medium-gray;
  font-style: normal;
}
.mention-list-no-permission {
  padding-bottom: 0px !important;
  span {
    color: $color-dark-gray;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    border: 0px;
  }
  hr {
    background-color: $color-medium-gray;
    color: $color-medium-gray;
    border-color: $color-medium-gray;
    margin: 0px;
  }
}

[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
  color: $color-dark-gray;
  font-style: italic;
  font-family: "Open Sans";
}
