.change-decommission-date-dialog {
  color: $color-text;
  background-color: white;

  &__date-input {
    display: flex;
    flex-direction: column;
    margin: 1em 0;

    * > {
      flex: 1 1 0;
    }
  }

  &__error {
    text-align: center;
    color: red;
  }
}
