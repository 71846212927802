abx-room-asset-relationship {
  display: block;

  .room-asset-relationship-loading {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding: 24px 16px;
  }

  .room-asset-relationship-header {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-left: 8px;

    header {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
    }

    &__edit {
      margin-left: auto;
      color: $color-link;
    }

    &__button {
      margin-left: auto;
    }
  }

  .room-asset-relationship-warning {
    padding: 8px;
  }

  .room-asset-relationship-content {
    padding: 8px;

    &__relationships {
      display: flex;
      flex-direction: row;
      width: 100%;

      .relationships__list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .relationships-list__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .content-info {
            display: flex;
            flex-direction: column;

            &__type {
              text-transform: uppercase;
              color: $color-dark-gray;
              font-size: 11px;
              width: 100%;
            }

            &__association {
              display: flex;
              align-items: center;
              margin: 4px;
            }
          }

          .content-button {
            color: $color-link;
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &__set-up {
      display: flex;
      flex-direction: column;

      .set-up__message {
        margin-top: 16px;
        margin-bottom: 24px;
      }
    }
  }
}
