.import-job-dialog {
  abx-import-file-select {
    padding-top: 16px;

    &.main-import-file-select {
      md-select {
        padding-right: 52px;
      }
    }

    .import-row > div {
      padding-left: 16px;
    }
  }

  .import-warning-asset-all-types {
    margin: 16px;
    padding: 16px;
    font-size: 14px;
    background-color: #f1f1f1;
  }
}
