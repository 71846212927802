.status-bar,
.wo-only {
  position: relative;
  min-height: 100px;
}

.status-date {
  font-family: OpenSans-SemiBold, Open Sans;
  font-size: 10px;
  font-weight: 500;
  fill: #666;
  padding: 10px;
  display: inline-block;
  text-align: center;
}

.status-text {
  font-family: OpenSans-Regular, Open Sans;
  font-size: 14px;
  font-weight: normal;
  fill: #212121;
}

.current-status {
  font-family: OpenSans-Bold, Open Sans;
  font-weight: bold;
}

.todo-text {
  font-style: italic;
  fill: #666 !important;
}

.overdue,
.done,
.todo,
.current,
.canceled {
  position: inherit;
  display: block;
  float: left;
  width: 25%;
  z-index: 2;
  margin-top: 8px;
}

.wo-overdue,
.wo-done,
.wo-todo,
.wo-current,
.wo-canceled {
  position: inherit;
  display: block;
  float: left;
  width: 33.33%;
  z-index: 2;
  margin-top: 8px;
}

.denied,
.request-submitted {
  position: inherit;
  display: block;
  float: left;
  width: 50%;
  z-index: 2;
  margin-top: 8px;
}

#spot-2 {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  animation: fadein 1.5s;
}

#spot-3 {
  -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2.5s; /* Firefox < 16 */
  -ms-animation: fadein 2.5s; /* Internet Explorer */
  animation: fadein 2.5s;
}

#spot-4 {
  -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 4s; /* Firefox < 16 */
  -ms-animation: fadein 4s; /* Internet Explorer */
  animation: fadein 4s;
}

.status-line-spot1,
.status-line-spot2,
.status-line-spot3,
.status-line-spot4,
.status-line2,
.status-line3-spot3,
.status-line3-spot2,
.status-line3-spot1 {
  position: absolute;
  clear: left;
  height: 1px;
  top: 14.5px;
  z-index: 1;
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  animation-fill-mode: forwards;
}

.status-line-spot1 {
  -webkit-animation-name: progress-line-spot1; /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation-duration: 1s; /* Safari, Chrome and Opera > 12.1 */
  animation-name: progress-line-spot1;
  animation-duration: 1s;
}

.status-line-spot2 {
  -webkit-animation-name: progress-line-spot2; /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation-duration: 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation-name: progress-line-spot2; /* Firefox < 16 */
  -moz-animation-duration: 2s; /* Firefox < 16 */
  -ms-animation-name: progress-line-spot2; /* Internet Explorer */
  -ms-animation-duration: 2s; /* Internet Explorer */
  animation-name: progress-line-spot2;
  animation-duration: 2s;
}

.status-line-spot3 {
  -webkit-animation-name: progress-line-spot3; /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation-duration: 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation-name: progress-line-spot3; /* Firefox < 16 */
  -moz-animation-duration: 2s; /* Firefox < 16 */
  -ms-animation-name: progress-line-spot3; /* Internet Explorer */
  -ms-animation-duration: 2s; /* Internet Explorer */
  animation-name: progress-line-spot3;
  animation-duration: 3s;
}

.status-line-spot4 {
  -webkit-animation-name: progress-line-spot4;
  -webkit-animation-duration: 3.5s;
  -moz-animation-name: progress-line-spot4;
  -moz-animation-duration: 3.5s;
  -ms-animation-name: progress-line-spot4;
  -ms-animation-duration: 3.5s;
  animation-name: progress-line-spot4;
  animation-duration: 3.5s;
}

.status-line2 {
  -webkit-animation-name: progress-line2;
  -webkit-animation-duration: 2s;
  -moz-animation-name: progress-line2;
  -moz-animation-duration: 2s;
  -ms-animation-name: progress-line2;
  -ms-animation-duration: 2s;
  animation-name: progress-line2;
  animation-duration: 2s;
}

.status-line3-spot1 {
  -webkit-animation-name: progress-line3-spot1;
  -webkit-animation-duration: 2s;
  -moz-animation-name: progress-line3-spot1;
  -moz-animation-duration: 2s;
  -ms-animation-name: progress-line3-spot1;
  -ms-animation-duration: 2s;
  animation-name: progress-line3-spot1;
  animation-duration: 2s;
}

.status-line3-spot2 {
  -webkit-animation-name: progress-line3-spot2;
  -webkit-animation-duration: 2s;
  -moz-animation-name: progress-line3-spot2;
  -moz-animation-duration: 2s;
  -ms-animation-name: progress-line3-spot2;
  -ms-animation-duration: 2s;
  animation-name: progress-line3-spot2;
  animation-duration: 2s;
}

.status-line3-spot3 {
  -webkit-animation-name: progress-line3-spot3;
  -webkit-animation-duration: 3s;
  -moz-animation-name: progress-line3-spot3;
  -moz-animation-duration: 3s;
  -ms-animation-name: progress-line3-spot3;
  -ms-animation-duration: 3s;
  animation-name: progress-line3-spot3;
  animation-duration: 3;
}

/* Standard syntax */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes progress-line3-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 16%;
  }
}

@keyframes progress-line3-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 49%;
  }
}

@keyframes progress-line3-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 83%;
  }
}

@keyframes progress-line2 {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}

@keyframes progress-line-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 12%;
  }
}

@keyframes progress-line-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 37%;
  }
}

@keyframes progress-line-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 62%;
  }
}

@keyframes progress-line-spot4 {
  0% {
    width: 0%;
  }
  25% {
    width: 37%;
  }
  50% {
    width: 62%;
  }
  100% {
    width: 87.5%;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes progress-line3-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 16%;
  }
}

@-moz-keyframes progress-line3-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 49%;
  }
}

@-moz-keyframes progress-line3-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 83%;
  }
}

@-moz-keyframes progress-line2 {
  0% {
    width: 0%;
  }
  100% {
    width: 74%;
  }
}

@-moz-keyframes progress-line-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 12%;
  }
}

@-moz-keyframes progress-line-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 37%;
  }
}

@-moz-keyframes progress-line-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 62%;
  }
}

@-moz-keyframes progress-line-spot4 {
  0% {
    width: 0%;
  }
  25% {
    width: 37%;
  }
  50% {
    width: 62%;
  }
  100% {
    width: 87.5%;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes progress-line3-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 16%;
  }
}

@-webkit-keyframes progress-line3-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 49%;
  }
}

@-webkit-keyframes progress-line3-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 82%;
  }
}

@-webkit-keyframes progress-line2 {
  0% {
    width: 0%;
  }
  100% {
    width: 74%;
  }
}

@-webkit-keyframes progress-line-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 12%;
  }
}

@-webkit-keyframes progress-line-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 37%;
  }
}

@-webkit-keyframes progress-line-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 62%;
  }
}

@-webkit-keyframes progress-line-spot4 {
  0% {
    width: 0%;
  }
  25% {
    width: 37%;
  }
  50% {
    width: 62%;
  }
  100% {
    width: 87.5%;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes progress-line3-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 16%;
  }
}

@-ms-keyframes progress-line3-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 49%;
  }
}

@-ms-keyframes progress-line3-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 83%;
  }
}

@-ms-keyframes progress-line2 {
  0% {
    width: 0%;
  }
  100% {
    width: 74%;
  }
}

@-ms-keyframes progress-line-spot1 {
  0% {
    width: 0%;
  }
  100% {
    width: 12%;
  }
}

@-ms-keyframes progress-line-spot2 {
  0% {
    width: 0%;
  }
  100% {
    width: 37%;
  }
}

@-ms-keyframes progress-line-spot3 {
  0% {
    width: 0%;
  }
  100% {
    width: 62%;
  }
}

@-ms-keyframes progress-line-spot4 {
  0% {
    width: 0%;
  }
  25% {
    width: 37%;
  }
  50% {
    width: 62%;
  }
  100% {
    width: 87%;
  }
}
