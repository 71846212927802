.abx-asset-export-job-dialog {
  &__help-text {
    text-align: center;
    padding: 0px 0 20px 0;

    p {
      margin: 0;
    }
  }
  &__options {
    gap: 30px;
  }
  &__option {
    ng-transclude {
      display: flex;
      flex-direction: column;

      md-icon {
        color: $color-link;
        font-size: 32px;
        margin-bottom: 12px;
      }
    }
  }
  &__job-created {
    text-align: center;

    p:nth-child(2) {
      padding: 8px 0;
    }
  }
  &__content {
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
