abx-canvas-control {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  pointer-events: none;

  .toggle-and-control-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-16px);
    transition: transform 0.3s;
    pointer-events: auto;

    &.collapsed {
      transform: translateY(100%);
      pointer-events: none;
    }
  }

  .control-wrapper {
    flex: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0;

    @extend .card-4dp;
    margin: 0px;
    border-radius: 4px;

    .dropdown-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;

      &:first-of-type {
        justify-content: flex-start;
      }
      &:last-of-type {
        justify-content: flex-end;
      }

      .dropdown-container__label {
        margin-right: 8px;
      }

      .dropdown-container__select {
        margin: 0;
        max-width: unset;

        md-select-value.md-select-value {
          min-width: 32px;
          .md-select-icon {
            width: 14px;
          }
        }
      }
    }

    .canvas-control {
      flex: 1 auto;

      height: 40px;

      display: flex;
      align-items: center;
      user-select: none;

      @extend .elevation-frame-2dp;

      md-icon {
        &.selected {
          color: $secondary;
          fill: $secondary;
        }
        &[disabled="disabled"] {
          color: $color-medium-gray;
          fill: $color-medium-gray;
        }
      }
      .material-icons {
        flex: 1;
        color: $color-text;
        padding: 0 12px;
        min-width: 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .document-control {
      flex: 1 auto;
      height: 40px;
      padding: 0 8px;

      display: flex;
      align-items: center;
      border-radius: 4px;

      .single-option {
        min-width: 16px;
      }
    }
  }

  .toggle-wrapper {
    background-color: $color-secondary;
    width: 48px;
    height: 24px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    margin: 0 auto;
    pointer-events: auto;

    .canvas-control-toggler {
      color: $white;
    }
  }
}
