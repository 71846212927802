.abx-type-ahead-component {
  position: relative;
  &:focus-within input {
    // Necessary for when the user tabs through the inputs (since the `input`)
    // technically loses focus
    background-color: $white;
  }

  // Set a max height to avoid brief flicker of components below typeaheads
  // that happen when the gray bottom border and loading indicator are briefly
  // both visible
  max-height: 32px;

  &__group {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin: 0 0 16px;
    }
  }

  &__option-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.secondary-results {
      font-style: italic;
      color: $color-light-gray;
    }
  }

  .abx-input__clear-button {
    position: absolute;
    top: 4px;
    right: 10px;
  }

  .abx-input__field--with-clear {
    padding-right: 30px;
  }

  .abx-input__secondary-icon {
    position: absolute;
    top: 4px;
  }

  .abx-input__field--with-secondary {
    padding-left: 30px;
    font-style: italic;
    color: $color-medium-gray;
  }
}

.abx-input--editing {
  .abx-input__clear-button {
    display: none;
  }
  .abx-input__field--with-clear {
    padding-right: initial;
  }
}

.abx-type-ahead-list {
  display: block;
  max-height: 200px;
  overflow: auto;
  margin: 0 1px;
  padding: 0;
  background-color: $white;
  z-index: 100;
  overflow-anchor: none;

  .no-results {
    font-style: italic;
    color: $color-medium-gray;
    font-size: 14px;
    height: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: not-allowed;
  }

  .limited-results {
    background-color: $color-light-gray;
    font-size: 14px;
    color: $color-medium-gray;
    font-style: italic;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &__see-all.btn-link-no-bg {
      font-style: normal;
      padding: 0 2px;
      border: none;
      margin: 0 2px;
      height: 32px;
      min-height: 32px;
    }
  }

  .secondary-results {
    display: flex;
    font-style: italic;
    color: $color-medium-gray;
    padding-right: 4px;
  }

  .abx-type-ahead-component__group-header {
    background-color: $white;
    color: inherit;
    font-size: 12px;
    font-weight: 600 !important;
    border-bottom: 1px solid $color-border-gray;
    padding: 8px 0 4px 16px;
  }

  md-list-item {
    @extend .abx-list-item;
    height: 36px;
    min-height: 36px;

    &.focused {
      background-color: $secondary-3 !important;

      > * {
        font-weight: 700 !important;
      }
    }

    ::before,
    div,
    button {
      min-height: 32px;
    }

    &:not(.focused):hover {
      background-color: transparent !important;

      > * {
        font-weight: inherit !important;
      }
    }
  }
}
