.recommission-dialog {
  md-dialog-content {
    color: $black;
    background-color: white;
  }

  &__entity-name {
    margin-top: 0;
  }

  md-dialog-actions {
    color: $black;
    background-color: white;
  }

  &__error-text {
    font-size: 14px;
    margin: 16px;
    color: $color-error;
    display: block;
    min-height: 16px;
  }

  .btn-primary.abx-button {
    min-width: 144px;
  }
}
