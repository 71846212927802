.abx-banner-message {
  background-color: $color-error;
  display: flex;
  flex-direction: column;

  .abx-banner-message-container {
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;

    .material-icons {
      color: $white;
      font-size: 16px;
      margin-right: 4px;
      align-self: center;
    }

    .abx-banner-message-text {
      color: $white;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 12px;

      // needed to keep banner size from changing after rendering markdown
      * {
        margin: 0 0 0;
      }
    }
  }
  .abx-banner-message-divider {
    display: block;
    border-bottom: 1px solid $color-black;
  }
}
