.abx-action-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 4px;
  color: $secondary;
}

button.md-button.abx-action-row__item,
a.md-button.abx-action-row__item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 6px 4px;
  padding: 0 4px;
  font-size: 11px;
  min-width: 0;
  min-height: 32px;
  justify-content: space-around;

  .material-icons {
    @extend .md-16;
    margin-right: 4px;
    margin-left: 4px;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.abx-pin-detail-sidebar__content {
  overflow-x: hidden;
  padding: 16px;
}

.abx-pin-detail-sidebar__details {
  display: flex;
  flex-direction: column;
}

.md-button.abx-pin-detail-sidebar__delete-button {
  color: $color-error;
  text-transform: uppercase;
  margin: 0;
  margin-top: 16px;
  border: 2px solid $color-border-gray;
  span {
    font-weight: 600;
  }

  &[disabled] {
    color: $color-medium-gray;
    cursor: not-allowed;
  }
}

.abx-pin-detail-sidebar__delete-icon {
  color: $color-error;
  font-size: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 5px;
}

.abx-pin-detail-sidebar__preloaders {
  margin: 8px 0 0;
}
