.building-header {
  width: 100%;
  height: 72px;
  min-height: 72px;
  background-color: $white !important;
  border-bottom: none !important;
  @extend .elevation-frame-bot-2dp;

  .building-header__title {
    color: $color-text;
  }

  h3 {
    padding-right: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 31.5px;
    color: $white;
    text-transform: capitalize;
  }

  small {
    font-weight: 400;
    font-family: Open Sans, sans-serif;
    color: $black-54;
  }

  .mr10 {
    margin-right: 10px;
  }

  .header-name {
    color: $black-38;
    text-transform: capitalize;
  }

  .header-buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding-left: 8px;
    white-space: nowrap;
  }

  .building-name {
    padding: 8px 16px;
  }

  .building-name,
  .building-name h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn {
    outline: none !important;
    box-shadow: none;
    transition: background-color 0.4s, color 0.4s;
  }

  .btn-lg {
    height: 72px;
    width: 72px;
    margin-left: 8px;
    padding: 20px;
    line-height: 0;
    color: $black-38;
    border-left: 1px solid $black-10;
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;

    &:hover {
      color: $color-black;
    }
  }
}

.building-header.md-dark-theme {
  background-color: $color-secondary;

  h3 {
    color: $white;

    .link {
      a {
        color: $white !important;
      }
    }
  }

  small {
    color: $white;
  }

  .slash {
    color: $white;
  }

  .header-name {
    color: $white-87;
    text-transform: capitalize;
  }

  .btn-link {
    border-color: $white-10;

    &:hover {
      color: $white-87;
    }
  }

  .btn-lg {
    color: $white;
    border-left: 2px solid $white-54;

    &:hover {
      color: $white;
      background-color: $white-10;
    }
  }
}
