abx-plan-view-app {
  display: block;
  flex: 1;
  width: 100%;
  height: 100%;
  /* Fixes bug with scrolling on FireFox */
  min-height: 0;
}

.abx-plan-view {
  height: 100%;
  &__content {
    overflow: hidden;
  }
}

.abx-sidebar {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @extend .elevation-frame-4dp;

  &--locked-open {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0);
  }
}
