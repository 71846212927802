abx-location {
  display: block;

  .location-header {
    display: flex;
    align-items: center;

    header {
      font-size: 18px;
      font-weight: 400;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .location-header__title {
      display: flex;
      align-items: center;
      min-height: 52px;
    }

    .location-header__button {
      margin-left: auto;
    }
  }
}
