abx-tabs {
  display: block;

  ng-transclude {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  abx-tab-spacer {
    display: block;
    width: 16px;
    border-bottom: 2px solid $gray-CC;
  }

  abx-tab {
    display: block;
    flex: 1;
    text-align: center;
    padding: 4px 8px;
    background: $color-light-gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 2px solid $gray-CC;
    border-bottom: 2px solid $gray-CC;
    border-left: 2px solid $gray-CC;
    border-right: 2px solid $gray-CC;

    &.abx-selected {
      background: none;
      border-bottom: none;
    }
  }
}

abx-tab-content {
  display: block;
  padding: 16px;
  border-left: 2px solid $gray-CC;
  border-right: 2px solid $gray-CC;
  border-bottom: 2px solid $gray-CC;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
