abx-link-button {
  color: #2196f3;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  i {
    margin-right: 5px;
  }
}
