abx-pin-type {
  display: block;

  .abx-pin-type-wrap {
    height: 100%;
  }
}

.abx-pin-type-icon-wrap {
  border-radius: 100%;
  margin: 0 8px 0 0;
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abx-pin-type-icon {
  height: 95%;
  width: 95%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
