md-dialog {
  width: 80%;

  @include respond-to(gt-md) {
    width: 50%;
  }
  @include respond-to(gt-lg) {
    width: 33%;
  }

  &.abx-dialog,
  &.abx-condition-dialog {
    width: 680px;
    max-width: 680px;
  }

  &.dialog-height-90 {
    min-height: 90%;
    height: 90%;
  }

  md-toolbar {
    div.md-toolbar-tools > h2 {
      font-weight: 500;
    }

    div.md-toolbar-tools > button.md-button.dialog-close-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-right: -6px;
    }
  }
  md-dialog-actions {
    flex: 0 0 auto;
    padding-right: 16px;

    .abx-dialog__actions {
      flex: 1 1 0px;
    }

    @include respond-to(gt-sm) {
      padding-right: 8px;
    }
  }
  md-dialog-content {
    flex-direction: column;

    md-progress-circular.inline {
      display: inline;
    }

    .md-dialog-content {
      padding: 8px;

      .md-title {
        font-weight: bold;
      }

      @include respond-to(gt-sm) {
        padding: 24px;
      }
    }
  }

  .abx-dialog__help-text {
    margin-bottom: 16px;
    text-align: center;
  }
  .abx-dialog__inputs {
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: 16px;

    .abx-dialog__inputs__form-row {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      &--half {
        max-width: 50%;
        flex: 1 1 50%;
      }

      .first-input {
        padding-right: 8px;
      }
      .second-input {
        padding-left: 8px;
      }
    }
  }
  .abx-dialog__actions {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: stretch;
    max-width: 100%;
  }
  .abx-dialog-buttons {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: space-around;
    align-content: stretch;

    & > button {
      width: 100%;

      // Angular does not have a right margin in md-button
      // Setting it here to make the dialogs while in mobile view centered
      margin-right: 8px !important;
    }

    @include respond-to(gt-sm) {
      justify-content: flex-end;

      & > button {
        width: auto;
      }
    }
  }
  .abx-dialog-bulk-add-buttons {
    display: flex;
    margin-right: auto;
  }
  .abx-input-section {
    display: block;
    md-input-container {
      display: block;
    }
  }

  .abx-work-order-location-section {
    display: flex;
    flex-direction: row;
  }

  .checklist-template-error {
    display: flex;
    justify-content: center;
    color: $color-error;
  }

  .remove-location {
    margin-left: 16px;
  }

  .abx-attachment-container .label .attachment-container__label {
    text-transform: none;
  }

  .abx-external-work-order-dialog {
    min-height: 0px;
  }
}
