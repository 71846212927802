abx-inspection-program-drawer {
  display: block;
  z-index: 2;
  $button-height: 40px;
  $drawer-height: 40px;

  .abx-inspection-program-drawer {
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    pointer-events: none;
  }

  .abx-inspection-program-drawer-container {
    margin-top: 12px;
    margin-bottom: 24px;
    height: $button-height;
    pointer-events: all;
  }
}
