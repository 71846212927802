abx-import-file-select {
  display: block;
  min-height: 56px;

  md-select {
    padding-top: 4px;
  }

  .file-upload-btn {
    padding: 8px;
    &:hover,
    label:hover {
      cursor: pointer !important;
    }
    .filename {
      padding: 0 6px;
      word-break: break-word;
      font-size: 16px;
    }
    .choose-file {
      margin: -4px 0 0;
      min-height: 0;
      line-height: 34px;
      text-transform: none;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .dupe-warning {
    margin: 0 8px;
    color: #fdd835;
  }

  .import-type-select {
    padding-right: 8px;
  }
}
