.abx-inspection-program-overview {
  &__summary {
    .abx-collapsible-card {
      .card-header-title {
        width: 100%;

        abx-collapsible-card-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: inherit;

          .summary-header {
            &__inputs {
              display: flex;
              flex-direction: row-reverse;

              .input-field {
                width: 150px;
                padding-right: 8px;
                text-transform: none;
              }
            }
          }
        }
      }

      .summary-content {
        .details-link {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;

          &__button {
            background-color: $color-primary;
            border: 2px solid $color-primary;
            display: flex;

            &:hover {
              color: inherit;
            }
          }
        }
      }
    }
  }
  /* TODO: Remove this style once the last placeholder section is removed */
  &--placeholder {
    border: 1px solid red;
  }

  &--active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .open-work-orders {
      width: 50%;
    }
    .scheduled-work-orders {
      width: 50%;
    }
  }

  &--closed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .completed-work-orders {
      width: 50%;
    }
    .canceled-work-orders {
      width: 50%;
    }
  }

  &__loading-spinner {
    display: flex;
    justify-content: center;
    margin: 32px;
  }
}
