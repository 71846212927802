/* Any pertinent updates to this file should be duplicated in 
   `../react/colors.ts`, which will eventually deprecate this file. */

// -------------------------------------
//   Colors
// -------------------------------------

/* NEW COLORS */

// PRIMARY
$color-primary: #f1c31a;

// SECONDARY
$color-secondary: #21263a;
$color-link: #2d8dd9;
$color-link-hover: #2072b3;
$color-error: #d0021b;
$color-error-hover: #9d0214;
$color-success: #4ca54c;
$color-pass: #8bc34a;
$color-fail: #f54337;

// NEUTRALS
$color-black: #000000;
$color-text: #212121;
$color-dark-gray: #666666;
$color-medium-gray: #999999;
$color-border-gray: #d9d9d9;
$color-light-gray: #eeeeee;
$color-white: #ffffff;
$color-black-87: rgba(0, 0, 0, 0.87);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-54: rgba(0, 0, 0, 0.54);
$color-black-38: rgba(0, 0, 0, 0.38);
$color-black-26: rgba(0, 0, 0, 0.26);
$color-black-12: rgba(0, 0, 0, 0.12);
$color-black-10: rgba(0, 0, 0, 0.1);
$color-white-87: rgba(255, 255, 255, 0.87);
$color-white-54: rgba(255, 255, 255, 0.54);
$color-white-38: rgba(255, 255, 255, 0.38);
$color-white-26: rgba(255, 255, 255, 0.26);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-10: rgba(255, 255, 255, 0.1);

// GRAPH COLORS
$color-graph-1: #285d85;
$color-graph-2: #2d8dd9;
$color-graph-3: #99cfea;
$color-graph-4: #2f9462;
$color-graph-5: #94cb17;
$color-graph-6: #aab96d;
$color-graph-7: #fdd835;
$color-graph-8: #ff960b;
$color-graph-9: #ff4f00;
$color-graph-10: #be0000;
$color-graph-11: #f094aa;
$color-graph-12: #a67fd1;
$color-graph-13: #6b3793;
$color-graph-14: #999999;
$color-graph-15: #d9d9d9;

$graphColors: $color-graph-1 $color-graph-2 $color-graph-3 $color-graph-4
  $color-graph-5 $color-graph-6 $color-graph-7 $color-graph-8 $color-graph-9
  $color-graph-10 $color-graph-11 $color-graph-12 $color-graph-13
  $color-graph-14 $color-graph-15;

// PIN ICON COLORS
$color-pin-1: #e91e63;
$color-pin-2: #f54337;
$color-pin-3: #9c27b0;
$color-pin-4: #673bb7;
$color-pin-5: #3f51b5;
$color-pin-6: #2296f3;
$color-pin-7: #04a9f4;
$color-pin-8: #01bcd4;
$color-pin-9: #009688;
$color-pin-10: #4cae51;
$color-pin-11: #8bc34a;
$color-pin-12: #cddc39;
$color-pin-13: #fdd735;
$color-pin-14: #ffc009;
$color-pin-15: #ff9801;
$color-pin-15: #ff5722;

// OCR
$ocr-text-outline-color: #6cd91e;

/* OLD COLORS */

$white: #ffffff;
$white-F1: #f1f1f1;
$white-F5: #f5f5f5;
$white-F9: #f9f9f9;
$black: $color-black;
$green: #8bc34a;
$green-3C: #3c763d;
$green-5C: #5cb85c;
$green-DF: #dff0d8;
$green-D6: #d6e9c6;
$red: #f44336;
$red-EB: #ebccd1;
$red-FF: #ff0000;
$red-F2: #f2dede;
$red-D9: #d9534f;
$red-A9: #a94442;
$akita-red: $color-error;
$yellow: $color-primary;
$yellow-FA: #faebcc;
$yellow-FC: #fcf8e3;
$yellow-8A: #8a6d3b;
$akita-blue: $color-link;
$blue: #2196f3;
$blue-2E: #2e303c;
$blue-dark: $color-secondary;
$gray-light: #292929;
$gray: $color-text;
$gray-333: #333333;
$gray-666: $color-dark-gray;
$gray-999: $color-medium-gray;
$gray-E5: #e5e5e5;
$gray-EE: $color-light-gray;
$gray-EF: #efefef;
$gray-DD: #dddddd;
$gray-CC: #cccccc;
$gray-D9: $color-border-gray;
$gray-dark: #111111;
$black-87: rgba(0, 0, 0, 0.87);
$black-75: rgba(0, 0, 0, 0.75);
$black-54: rgba(0, 0, 0, 0.54);
$black-38: rgba(0, 0, 0, 0.38);
$black-26: rgba(0, 0, 0, 0.26);
$black-12: rgba(0, 0, 0, 0.12);
$black-10: rgba(0, 0, 0, 0.1);
$white-87: rgba(255, 255, 255, 0.87);
$white-54: rgba(255, 255, 255, 0.54);
$white-38: rgba(255, 255, 255, 0.38);
$white-26: rgba(255, 255, 255, 0.26);
$white-10: rgba(255, 255, 255, 0.1);
$blue-20: rgba(33, 150, 243, 0.2);
$yellow-87: rgba(255, 216, 53, 0.87);
$red-10: rgba(208, 2, 27, 0.1);

$divider-light: rgba(0, 0, 0, 0.12);

// Default theme
$secondary: #238dd9;
$secondary-1: #d5e8f7;
$secondary-2: $color-link-hover;
$secondary-3: #b2dbfb;
