// -------------------------------------
//   Flex
// -------------------------------------

$i: 0;
$j: 0;
.layout-row.layout-wrap {
  // Multiples of five
  @while $i <= 100 {
    & > .flex-#{$i} {
      flex-basis: #{$i} !important;
    }
    $i: $i + 5;
  }

  // Multiples of thirty-three
  & > .flex-33 {
    flex-basis: 33.33% !important;
  }
  & > .flex-66 {
    flex-basis: 66.66% !important;
  }
}

// ----------------------------------------
//   Non-flexbox browser fallback
// ----------------------------------------
.no-flexbox {
  md-toolbar {
    .md-toolbar-tools {
      > * {
        margin: 14px 0;
      }
    }
  }

  .layout-row {
    > * {
      display: inline-block;
    }
    .layout-align-center-stretch {
      text-align: center;
      > * {
        display: inline-block;
        text-align: center;
      }
    }
  }

  .layout-column {
    display: table-cell;
    > * {
      display: block;
    }
    .layout-align-start-center {
      text-align: center;
    }
    .layout-align-center-stretch {
      margin: 0 auto;
      text-align: center;
    }
  }

  .layout-row {
    // Multiples of five
    @while $j <= 100 {
      & > .flex-#{$j} {
        margin-left: auto;
        margin-right: auto;
        width: #{$j}vw;
      }
      $j: $j + 5;
    }

    // Multiples of thirty-three
    & > .flex-33 {
      margin-left: auto;
      margin-right: auto;
      width: 33vw;
    }
    & > .flex-66 {
      margin-left: auto;
      margin-right: auto;
      width: 66vw;
    }
  }
}

.layout-space-between-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
  }

  @include respond-to(gt-sm) {
    & > * {
      flex-basis: calc(1 / 2 * 100% - (1 - 1 / 2) * 2em);
    }
  }
}
