.work-order-overview {
  .work-order-overview__description {
    .abx-section-content md-input-container {
      margin: 0;
    }
    .abx-header {
      font-weight: 600;
    }
    .abx-input-section {
      width: 100%;
      overflow: hidden;
    }
    .abx-input--editable,
    .abx-input--saving {
      width: 100%;
    }
    .abx-input__readonly--value {
      line-height: inherit;
      padding-left: 0;

      // Forcing span to respect newline characters
      span > span {
        white-space: pre-line;
      }
    }
  }
  .floor-plan-preview {
    overflow: hidden;
  }
  .wo-assignee-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-weight: 700;
    font-size: 12px;

    .material-icons {
      margin: 0 8px;
    }
  }
  .wo-location-header {
    display: flex;
    align-items: center;

    header {
      font-size: 18px;
      font-weight: 400;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .wo-assignee-header__button,
    .wo-location-header__button {
      margin-left: auto;
    }
  }

  .wo-relationships {
    max-height: 400px;

    .abx-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__number {
        margin-left: 6px;
        font-size: 14px;
      }
    }

    &__loading {
      display: flex;
      justify-content: center;
      margin: 20px 0;
      width: 100%;
    }

    &__entities {
      display: flex;
      flex-direction: column !important;
      align-items: flex-start !important;
      margin: 8px 0;
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 300px;

      .entities {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__header {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid $color-black;
          padding: 4px;
          margin-bottom: 8px;
          width: 100%;

          .header-icon {
            padding-left: 12px;
          }

          .header-name {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
            padding: 8px 0;
          }
        }

        &__body {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
          margin: 4px;

          .body-relationships {
            display: flex;
            flex-direction: column;
            width: 48%;

            &__header {
              display: flex;
              flex-direction: row;

              .header-title {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;
              }

              .header-count {
                font-size: 10px;
                padding-left: 8px;
              }
            }

            &__description {
              font-size: 12px;
              padding-bottom: 8px;
              border-bottom: 1px solid $color-border-gray;
            }

            &__list {
              .relationships-list {
                &__content {
                  padding: 4px;

                  .content-info {
                    &__type {
                      text-transform: uppercase;
                      font-size: 12px;
                      padding-top: 4px;
                    }

                    &__association {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    }
                  }
                }

                &__none {
                  padding-left: 12px;
                  font-style: italic;
                  color: $color-medium-gray;
                }
              }
            }
          }
        }
      }

      .no-entities {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 18px;
        font-style: italic;
        color: $color-medium-gray;
        margin: 12px;
      }
    }
  }

  .wo-mult-assignee-input {
    margin-left: 36px;
  }

  .wo-assignee-input {
    margin-top: 12px;
  }

  .requester-phone-number {
    display: flex;

    .phone-number {
      margin-right: 16px;
    }
  }

  .property {
    padding: 3px;
    margin-bottom: 18px;
    font-size: 16px;

    &__label {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__name {
      padding-left: 2px;
    }
  }

  .abx-card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .abx-card-grow {
      flex-grow: 1;
    }

    .abx-button {
      flex-basis: 200px;
    }

    .material-icons {
      margin: 0 8px;
    }

    &__buttons .abx-button {
      flex-grow: 1;
    }
  }

  abx-activity-list {
    padding: 0;
    .show-more-button {
      padding-bottom: 16px;
    }
  }

  .work-order-logs {
    &__log {
      display: flex;
      flex-direction: row;
      line-height: 2em;

      &.work-minutes {
        font-weight: bold;
        flex-basis: 30%;
      }

      &.work-performed-by {
        display: inline-block;
        flex-basis: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__total {
      margin-top: 10px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .inspection-program {
    margin-top: 16px;

    label {
      margin-bottom: 0px;
    }
  }

  .round-template {
    margin-top: 16px;
    padding-left: 8px;
    font-size: 12px;

    &__header {
      margin-bottom: 0px;
      font-weight: 600;
      color: $color-text;
    }

    &__name {
      margin-bottom: 16px;
    }
  }

  .wo-location-card {
    margin-top: 8px;
  }

  abx-range-input {
    width: 100%;
    padding: 8px;

    .abx-range-input__wrapper {
      .abx-date-input__datepicker-button {
        > md-icon.material-icons {
          margin: 0;
        }
      }
    }

    .abx-range-input__separator {
      flex: unset;
    }
  }
}
