abx-asset-input,
abx-floor-input,
abx-room-input {
  a.abx-location-link {
    display: none;
    position: absolute;
    top: 0;
    left: 2px;
    padding-top: 4px;
    padding-left: 2px;
    font-size: 16px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-color: white;
    }
  }

  &.abx-readonly {
    button {
      display: none;
    }
    a.abx-location-link {
      display: block;
    }
    input.md-input,
    textarea.md-input,
    md-select {
      @extend .text-placeholder;
    }
  }
}

abx-tree-input {
  &.abx-readonly {
    md-autocomplete md-input-container input {
      @extend .text-placeholder;
    }
  }
}

.abx-2-line {
  li {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: center;
    height: 72px;
    border-bottom: 1px solid #ccc;
    white-space: normal;

    md-autocomplete-parent-scope {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      align-content: center;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.abx-menu-item-text {
  flex: 1;
}
.abx-menu-item-text,
.abx-menu-item-header {
  padding: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
}
.abx-menu-item-header {
  height: 28px;
  font-size: 12px;
}
.abx-scroll-list {
  overflow: auto;
  max-height: 250px;
}
