/* List */
abx-pin-type-filters {
  md-list.abx-pin-type-list {
    padding: 0;
  }

  .has-pins {
    &.last {
      margin-bottom: 12px;
    }
  }

  .no-pins {
    .title {
      font-weight: 700;
      font-size: 12px;
      padding: 8px;
      text-transform: uppercase;
    }
  }
}

/* List items */
abx-pin-type-filter-item .list-item {
  position: relative;

  .abx-pin-type-filter-item__main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    align-content: flex-start;

    // when the filter is not active, the entire main content should be light blue on hover
    &:not(.abx-filter--active):hover {
      background-color: $secondary-1 !important;
    }

    .abx-pin-type-filter-item__icon-and-label {
      &.abx-filter--active {
        background-color: $secondary-1 !important;
        .abx-pin-type-filter-item__name-and-number {
          font-weight: 600;
        }
        &:hover {
          background-color: $color-link-hover !important;
          .abx-pin-type-filter-item__name-and-number {
            color: $white;
          }
        }
      }

      // not active, hovering over icon & label
      &:hover {
        background-color: $secondary-1 !important;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: calc(100% - 36px); // to allow room for the filter icon

      .abx-pin-type-filter-item__icon-or-spinner {
        padding-left: 8px;
        margin-right: 8px;
        // to control icon display in IE
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        md-progress-circular {
          position: absolute;
          top: 5px !important;
          bottom: 0;
          margin: 0 auto !important;
        }

        abx-pin-type {
          .abx-pin-type-icon-wrap {
            margin: 0;
          }
        }
      }

      .abx-pin-type-filter-item__name-and-number {
        height: 100%;
        overflow: hidden;
        line-height: 1.2;
        display: flex;
        flex-wrap: nowrap;
        padding-right: 5px;
        font-size: 12px;
        &.abx-pin-type-name {
          flex-basis: 100%;
        }
      }
    }

    button.md-button.abx-pin-type-filter-btn {
      min-height: 36px;
      min-width: 36px;
      margin: 0;
      transition: background-color 0s;
      padding: 0;
      border-radius: 0;
      &.abx-filter--active {
        background-color: $secondary-1 !important;
        &:hover {
          background-color: $color-link-hover !important;
        }
      }

      .abx-pin-type-filter-icon {
        color: $secondary;
      }

      // hovering over tune icon (only visible when active)
      &:not([disabled]):hover {
        md-icon.abx-pin-type-filter-icon {
          color: $white !important;
        }
      }
    }
  }

  .abx-pin-type-filter-item__chip-bag {
    flex: 1;
    &.abx-tune-icon-hover {
      background-color: $color-link-hover !important;
    }
    .abx-chips {
      padding: 0 5px;

      .abx-pin-type-filter-item__subfilter {
        text-transform: uppercase;
        background-color: $white;
        border: 1px solid $color-light-gray;
        border-radius: 1px;
        font-weight: normal !important;
        font-size: 10px;
        line-height: 12px;
        padding: 2px 4px;
        margin: 3px;
        word-break: break-word;
        // Required to make the subfilter chips clickable due to md stuff
        z-index: 1;
      }
    }
  }
}
