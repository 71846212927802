.abx-dashboard-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100%;

  .abx-dashboard-preloader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .elevation-frame-2dp;
  }
}
