abx-qr-code-input {
  display: block;
  margin-bottom: 14px;

  .flex-container {
    display: flex;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .abx-qr-code-input {
      width: 100%;
    }

    /* High specificity selector to override md styles */
    #{repeatString(".abx-qr-code-input__scan-button", 10)} {
      @include abxInputCompanionButton;

      // To align with the string input box
      margin-top: 6px;
    }
  }

  .abx-qr-code-input__save-button {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
}
