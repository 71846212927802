abx-asset-life-cycle {
  display: block;

  .asset-life-cycle-header {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-left: 8px;

    header {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__button {
      margin-left: auto;
    }
    &__warning {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 4px;

      md-icon {
        color: $color-link;
        fill: $color-link;
      }
    }
  }

  .asset-life-cycle-content {
    display: flex;
    flex-direction: column;
    padding: 8px;

    &__start-tracking-message {
      margin-bottom: 24px;
    }

    &__item {
      margin-bottom: 16px;

      &--not-provided {
        font-style: italic;
        color: $color-dark-gray;
      }

      &--condition-date {
        margin-left: 8px;
        font-style: italic;
        color: $color-medium-gray;
      }
    }
  }

  .asset-life-cycle-loading {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding: 24px 16px;
  }

  .abx-input {
    .abx-input__label::after {
      content: none !important;
    }
  }

  abx-life-cycle {
    margin-bottom: 16px;
  }
}
