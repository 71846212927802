abx-plan-view-content {
  display: flex;
  height: 100%;
  justify-content: center;
}
.abx-top-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .abx-icon-text-row {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    md-icon {
      margin: 0px;
    }
    span {
      padding-left: 10px;
      font-size: 16px;
    }
  }
  .abx-cancel-row {
    font-weight: bold;
    color: $blue;
  }
}
.abx-floor-dropdown-select {
  position: fixed;
  right: 8px;
  top: 74px;
  z-index: 20;
}
