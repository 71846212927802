abx-life-cycle-chart {
  display: block;
  position: relative;

  .abx-life-cycle-chart__tooltip {
    white-space: nowrap;
    background-color: rgba($color-secondary, 0.8);
    color: $white;
    text-align: center;
    position: absolute;
    text-overflow: ellipsis;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 10px);
    font-size: 12px;
    padding: 8px;
    border-radius: 2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: none;
    z-index: 99;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      border: 10px solid transparent;
      border-top-color: rgba($color-secondary, 0.8);
      transform: translateX(-50%);
    }
  }

  path.today-path {
    stroke: $color-error;
    fill: none;
  }
  path.projected-path {
    stroke: $color-link;
    fill: none;
  }
  path.original-path {
    stroke: $color-medium-gray;
    fill: none;
    stroke-dasharray: 6 6;
  }

  text.today-label {
    font-size: 10px;
  }

  circle.dot-condition {
    stroke: $color-link;
    stroke-width: 1.5;
    &.dot-condition--open {
      fill: transparent;
    }
    &.dot-condition--closed {
      fill: $color-link;
    }
  }
}
