.qr-pin-search-dialog {
  width: 480px;
  min-width: initial;
  max-width: 90%;

  .toolbar-tools {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .code-number {
      margin-bottom: 8px;
    }
    .message-text {
      margin-bottom: 16px;
    }
  }

  .input-container {
    padding-top: 16px;
  }

  .pin-model-radio {
    width: 160px;
    margin-bottom: 2px;
  }

  .already-assigned {
    color: $color-error;
    margin-top: 8px;
  }

  .actions-container {
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
  }

  .btn-wrapper {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
