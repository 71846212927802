// -------------------------------------
//   Preloaders
// -------------------------------------
.preloader {
  background: rgba(178, 181, 186, 0.5);

  @media print {
    display: none;
  }
}
.preloader-line {
  @extend .preloader;
  height: 8px;

  &.rounded {
    border-radius: 4px;
  }

  &.line-text {
    margin: 1px 0;
  }

  // Height
  &.line-12h {
    height: 12px;
  }
  &.line-14h {
    height: 14px;
  }
  &.line-16h {
    height: 16px;
  }
  &.line-18h {
    height: 18px;
  }
  &.line-20h {
    height: 20px;
  }
  &.line-24h {
    height: 24px;
  }
  &.line-36h {
    height: 36px;
  }
  &.line-48h {
    height: 48px;
  }

  // Width
  &.line-40w {
    width: 40px;
  }
  &.line-60w {
    width: 60px;
  }
  &.line-80w {
    width: 80px;
  }
  &.line-120w {
    width: 120px;
  }
  &.line-160w {
    width: 160px;
  }
  &.line-200w {
    width: 200px;
  }
  &.line-240w {
    width: 240px;
  }
  &.line-360w {
    width: 360px;
  }
}
.preloader-block {
  @extend .preloader;
  width: 8px;
  height: 8px;

  &.block-circle,
  &.preloader-block--circle {
    border-radius: 50%;
  }

  &.block-16 {
    width: 16px;
    height: 16px;
  }
  &.block-24 {
    width: 24px;
    height: 24px;
  }
  &.block-36 {
    width: 36px;
    height: 36px;
  }
  &.block-48 {
    width: 48px;
    height: 48px;
  }
}
.preloader-pulse,
.preloader--pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  &.ng-hide {
    animation: none;
  }
}

.preloader-flex {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  @include respond-to(gt-sm) {
    flex-wrap: nowrap;
    align-items: flex-start;
    .preloader-donut-legend {
      margin: 32px 18px;
    }
  }
}

.preloader-button {
  width: 96px;
  height: 36px;
  margin: 6px 8px;
  border-radius: 2px;

  &--120w {
    width: 120px;
  }
}

.preloader-graph,
.preloader-chart {
  display: flex;
  height: 100%;
  width: 100%;
}

.preloader-graph {
  flex-direction: column;
}

.preloader-chart {
  flex-direction: column;
  align-items: center;

  @include respond-to(gt-sm) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.preloader-graph-chart {
  @extend .preloader;
  @extend .preloader-block;
  @extend .preloader-pulse;
  width: 100%;
  height: 289px; // 305 (chart height) - 16 (margin-bottom)
}

.preloader-graph-legend {
  @extend .preloader;
  @extend .preloader-block;
  @extend .preloader-pulse;
  width: 100%;
  height: 56px;
  margin-top: 16px;
}

.preloader-donut-chart {
  @extend .preloader;
  @extend .preloader-block;
  @extend .preloader-pulse;
  margin: 15px;
  min-width: 260px;
  min-height: 260px;
  max-height: 260px;
  max-width: 260px;
  border-radius: 50%;
  border: 50px #dadbdd solid;
  background-color: white;
}

.preloader-donut-legend {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 216px;
  margin: 32px 0;

  @include respond-to(gt-md) {
    max-height: 144px;
  }

  .legend {
    height: 36px;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include respond-to(gt-md) {
      width: 33%;
    }

    @include respond-to(gt-lg) {
      width: 25%;
    }

    .legend-circle {
      @extend .preloader;
      @extend .preloader-block;
      @extend .preloader-pulse;
      border-radius: 50%;
      margin: 5px;
      width: 24px;
      height: 24px;
    }

    .legend-value {
      @extend .preloader;
      @extend .preloader-block;
      @extend .preloader-pulse;
      border-radius: 2px;
      margin: 5px;
      height: 24px;
      width: 120px;
    }

    &.legend--child {
      padding-left: 32px;
      .legend-value {
        width: 80px;
      }
    }
  }
}
