abx-work-order-location-section {
  flex-grow: 1;

  .abx-work-order-location-input__form-row {
    display: flex;
    flex-direction: row;

    &--remove-pin {
      display: flex;
      align-items: center;
      margin-top: 8px;
      justify-content: center;
      margin-left: 4px;
    }

    .abx-work-order-location-input__form-cell {
      &--half {
        max-width: 50%;
        flex: 1 1 50%;
      }
      flex-grow: 1;
    }

    .first-input {
      padding-right: 8px;
    }
    .second-input {
      padding-left: 8px;
    }
  }
}
