abx-attachment-container {
  margin: 18px 0;
  min-height: 58px;
  display: block;

  .attachment-list-empty {
    @extend .text-placeholder;
    padding: 8px 16px;
  }

  .attachment-wrapper {
    display: flex;
    flex-direction: row;
    padding-right: 8px;
    &__description {
      margin: 8px;
      width: 158px;
      > * {
        color: $color-link;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .abx-label {
    font-size: inherit !important;
    border-radius: 0;

    .attachment-container__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &--uppercase {
        text-transform: uppercase;
      }
    }

    .attachment-upload-container {
      display: flex;
      align-items: center;
    }

    .attachment-upload {
      height: 20px;
      display: flex;
      align-items: center;

      .attachment-upload__label {
        margin: 0; // Override bootstrap :[
      }

      .attachment-upload-icon {
        display: inline-block;
        font-weight: normal;
        font-size: 20px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        color: $blue;
      }

      .attachment-upload__label-icon {
        font-size: 16px;
        margin-right: 4px;
      }

      .attachment-upload-text-button {
        cursor: pointer;
        color: $blue;
      }

      > input {
        display: none;
        visibility: hidden;
        height: 0px;
        width: 0px;
        overflow: hidden;
        position: absolute;
      }

      &--disabled {
        &::after {
          color: gray;
        }
      }
    }
  }

  .attachment-input-max {
    font-size: 12px;
    font-style: italic;
    margin: 0 12px;
    white-space: nowrap;
  }

  .attachment-list {
    display: flex;
    margin: -8px -8px 0 0;
    flex-wrap: wrap;

    > * {
      margin-top: 8px;
    }
  }
}
