.abx-work-order-header {
  .breadcrumb-header__left__prefix {
    .work-order-intent__reactive {
      display: flex;
      justify-content: center;
      position: relative;
      .reactive-triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12.5px 21.7px 12.5px;
        border-color: transparent transparent $white transparent;
        line-height: 0px;
        _border-color: $color-black $color-black $white $color-black;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }
      .reactive-label {
        position: absolute;
        top: 5px;
        left: 0;
        width: 100%;
        text-align: center;
        color: $blue-2E;
      }
    }
    .work-order-intent__preventive {
      display: flex;
      justify-content: center;
      border: 2px solid white;
      border-radius: 50%;
      min-width: 25px;
      color: $white;
    }
  }

  .breadcrumb-header__right {
    display: flex;
    flex-direction: row;

    .abx-button {
      margin: 0px 16px;
    }

    .abx-work-order-header-kebab-menu {
      display: inline-block;
      position: relative;

      .md-icon-button {
        z-index: 12;
        margin: 0;
        font-size: 12px;
        color: $color-white;
      }

      abx-simple-dropdown {
        position: absolute;
        top: 50%;
        right: 50%;
        border: 1px solid $color-border-gray;

        .abx-simple-dropdown,
        .abx-simple-dropdown__list {
          width: 225px;
          min-width: 225px;
        }

        .abx-simple-dropdown__list__item__icon-button span {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}
