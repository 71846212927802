.request-overview {
  .floor-plan-preview {
    overflow: hidden;
  }

  .building-property {
    padding: 3px;
    margin-bottom: 18px;

    &__label {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__name {
      padding-left: 2px;
    }
  }

  abx-activity-list {
    padding: 0;
    .show-more-button {
      padding-bottom: 16px;
    }
  }

  &__show-more-button {
    margin: 0 auto;
  }

  &__assignees-list {
    list-style: none;
    margin: 0;

    &.short-list {
      height: 300px;
      overflow: hidden;
    }

    li {
      min-height: 30px;
      padding: 2px 0;
    }
  }
}

.abx-section-with-header,
.abx-activity-section {
  padding-top: 0px;
}
