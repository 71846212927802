abx-header-switcher {
  @extend .elevation-frame-4dp;
  display: block;
  background-color: $white;
  z-index: 10;

  // Position the switcher below the md-toolbar.
  // Since it's dependant on MD's height rules for md-toolbar,
  // mirror their media queries for it
  position: absolute;
  top: 64px;
  left: 0;
  // Width needed for IE 11 to respect the box model when position absolute
  width: 100%;

  .header-switcher-container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @media (min-height: 664px) {
    height: 600px;
  }

  @media (max-height: 663px) {
    height: calc(100% - 64px);
  }

  .abx-header-switcher__building-list {
    padding-bottom: 48px;
    background: white;
  }

  .abx-header-switcher-building-groups {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    border-right: 1px solid $gray-CC;
    background-color: $white;

    .abx-header-switcher__building-search {
      width: 100%;
      min-height: 48px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      background-color: $white-F5;
      border-bottom: 1px solid $gray-CC;

      .building-search__input {
        background: none;
        border: none;
        padding: 0 16px;
        flex: 1;

        &:focus {
          outline: none;
        }
      }
    }

    .abx-header-switcher__search-placeholder {
      width: 100%;
      min-height: 64px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }

    // Bold "All Buildings" group
    abx-header-list.abx-header-switcher__group-list {
      .abx-header-list__list {
        .abx-header-list-item:first-of-type {
          .abx-header-list-item__text {
            @extend .text-bold;
          }
        }
      }
    }
  }

  .abx-header-switcher__list {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 33%;
    border-right: 1px solid $gray-CC;
    background-color: $white;

    .abx-header-switcher__no-scroll {
      overflow: unset;
    }

    .abx-header-switcher__building-search {
      width: 100%;
      min-height: 48px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      background-color: $white-F5;
      border-bottom: 1px solid $gray-CC;

      .building-search__input {
        background: none;
        border: none;
        padding: 0 16px;
        flex: 1;

        &:focus {
          outline: none;
        }
      }
    }

    .abx-header-switcher__search-placeholder {
      width: 100%;
      min-height: 64px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }

    // Bold "All Buildings" group
    abx-header-list.abx-header-switcher__group-list {
      .abx-header-list__list {
        .abx-header-list-item:first-of-type {
          .abx-header-list-item__text {
            @extend .text-bold;
          }
        }
      }
    }
  }

  .abx-header-switcher__org-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    height: 48px;
    color: $color-link;
    background: $color-light-gray;

    &:hover {
      color: $color-link-hover;
      background: $color-border-gray;
    }

    .org-link-icon {
      margin: 4px;
    }

    .org-link-text {
      font-size: 14px;
      text-transform: uppercase;
      margin: 4px;
      font-weight: bold;
    }
  }

  abx-map {
    width: 100%;
    height: 100%;
  }

  abx-floor-card-container {
    position: relative;
    flex: 1;
  }

  .abx-header-switcher__content {
    position: relative;
    flex: 1;
  }

  .abx-header-switcher-floors {
    display: flex;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    flex-direction: row;
    background-color: $black-75;

    abx-floor-card-container {
      #abx-floor-card-container {
        padding-right: 56px;
      }

      .abx-floor-card-container__placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 56px 0 64px 56px;
        font-size: 24px;
      }
    }

    .abx-header-switcher-floors__close-button.md-button.md-icon-button {
      margin: 8px;
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        background-color: $white-10;
      }

      md-icon {
        color: $white;
      }
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 600px);
    top: 600px;
    background-color: $black-26;
  }

  @include respond-to(gt-sm) {
    .abx-header-switcher__list {
      max-width: 20%;
      &::only-child {
        max-width: 25%;
      }
    }
  }
}
