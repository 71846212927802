// -------------------------------------
//   Rotation
// -------------------------------------

// Usage:
//  class="rotate-{n}"
//  @xtend .rotate-{n}
//     where {n} is an integer from 0 to 359

@for $i from 0 through 359 {
  .rotate-#{$i} {
    transform: rotate(#{$i}deg);
  }
}
