.ip-subject {
  word-break: break-all;
}

.inspection-program-list {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;

  &__assignees {
    display: flex;
    align-items: center;

    span {
      display: block;
    }

    &__preview {
      height: 100%;
      max-width: calc(100% - 51px);
    }

    &__chip {
      padding-left: 5px;
      min-width: 46px;
    }
  }
}

.md-tooltip.multiline {
  height: auto !important;
}
