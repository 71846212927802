abx-priority-icon {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  md-icon {
    color: $black-26;
    fill: $black-26;
    &.work-order-priority-icon--emergency {
      color: $color-error !important;
      fill: $color-error !important;
    }
  }
}
