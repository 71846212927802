abx-filter-bar {
  display: flex;
  flex-direction: column;
}

.filter-bar {
  height: 64px;
  min-height: 64px;
  background-color: $white;
  border-bottom: 1px solid $black-10;

  #filter-key,
  #filter-value {
    padding: 8px 16px;
  }

  #filter-key {
    border-right: 1px solid $black-10;
  }

  label {
    color: $black-54;
  }

  select,
  input {
    width: 100%;
    padding: 0;
    background-color: $color-light-gray;
    border: none;
    box-shadow: none;
    outline-style: none;
  }

  select {
    margin-left: -8px;
  }

  input {
    height: 100%;
    border-bottom: 1px solid $black-54;
    &:active,
    &:focus {
      border-bottom: 1px solid $blue;
    }
  }

  abx-date-field input {
    border-radius: 0;
  }

  .date-separator {
    padding-top: 4px;
  }

  &__id-input-container {
    display: flex;
    width: 100%;
  }

  &__id-prefix {
    border-bottom: 1px solid $black-54;
    background-color: $color-light-gray;
    padding-left: 2px;
    line-height: 1.4; // Matches text up with input text

    &.focused {
      border-bottom-color: $blue;
    }
  }

  // Turn off increment/decrement arrows on ID inputs
  &__id-input::-webkit-inner-spin-button,
  &__id-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.active-filters {
  min-height: 72px;
  padding: 8px 16px;
  background-color: $white;
  border-bottom: 1px solid $black-10;

  .active-filters__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: bold;
  }
  .active-filters__chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin-top: 8px;
  }
  .active-filter {
    margin: 4px 16px 4px 0;
    padding: 4px 8px;
    font-size: 13px;
    font-weight: normal;
    color: $gray-dark;
    background-color: $color-light-gray;

    i {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
}
