// -------------------------------------
//   Buttons & Links
// -------------------------------------

$button-darken-percent: 2%;
$button-lighten-percent: 5%;

button,
[ng-click],
[data-ng-click],
[x-ng-click],
[href],
[ui-sref] {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

a.no-style {
  color: $gray-light !important;
  text-decoration: none !important;
}
a.hover-underline {
  position: relative;
  color: $black-54;
  text-decoration: none;
  &.fg-accent {
    &:before {
      background-color: $blue;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: $color-black;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover {
    color: $color-black;
    text-decoration: none;
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

a.md-button,
button.md-button {
  min-height: 40px;
  height: auto;
  line-height: 16px;
  padding: 10px 12px;
  font-weight: bold;
  border-radius: 2px;
  white-space: inherit;

  &:not([disabled]):not(.md-fab) {
    &:hover,
    &:focus,
    &.md-focused {
      &.md-primary {
        background-color: lighten($color-primary, 35%);
      }
      &.md-accent {
        background-color: lighten($blue, 43%);
      }
      &.md-warn {
        background-color: lighten($red-A9, 43%);
      }
      &.md-dark-theme {
        &.md-primary {
          background-color: lighten($color-secondary, 43%);
        }
        &.md-accent {
          background-color: lighten($color-primary, 35%);
        }
      }
      &.md-black-theme {
        &.md-primary {
          background-color: darken($gray, $button-darken-percent);
        }
        &.md-accent {
          background-color: darken($white, $button-darken-percent);
        }
      }
    }
  }

  &.md-raised {
    &[disabled],
    &:not([disabled]) {
      box-shadow: none;
    }
    &:not([disabled]) {
      &:hover,
      &:focus,
      &.md-focused {
        @extend .elevation-frame-2dp;
        &:active {
          @extend .elevation-frame-4dp;
        }
        &.md-primary {
          background-color: lighten($color-primary, $button-lighten-percent);
        }
        &.md-accent {
          background-color: lighten($blue, $button-lighten-percent);
        }
        &.md-warn {
          background-color: lighten($red-A9, $button-lighten-percent);
        }
        &.md-dark-theme {
          &.md-primary {
            background-color: lighten(
              $color-secondary,
              $button-lighten-percent
            );
          }
          &.md-accent {
            background-color: lighten($color-primary, $button-lighten-percent);
          }
        }
        &.md-black-theme {
          &.md-primary {
            background-color: lighten($gray, $button-lighten-percent);
          }
          &.md-accent {
            background-color: lighten($white, $button-lighten-percent);
          }
        }
      }
    }
    &[disabled],
    &[disabled="disabled"] {
      color: $black-38;
      background: $white;
      border: 2px solid $color-border-gray;

      i.material-icons,
      md-icon.material-icons {
        color: $black-38;
      }
    }
  }
  &.md-stroked {
    background-color: $color-light-gray;
    &:not([disabled]) {
      background-color: $white;
      border: 2px solid currentColor;

      &:hover,
      &:focus,
      &.md-focused {
        background-color: darken($white, $button-darken-percent);
      }
      &.md-muted {
        border: 2px solid $color-border-gray;
      }
      &.md-primary {
        color: $color-text;
        border: 2px solid $color-primary;
      }
      &.md-dark-theme {
        &.md-primary {
          border: 2px solid $color-secondary;
        }
      }
      &.md-black-theme {
        &.md-primary {
          border: 2px solid $color-text;
        }
      }
    }
  }
  &.flat {
    box-shadow: none !important;
  }
  &.no-caps {
    text-transform: none;
  }
  &.slim {
    min-height: inherit;
    height: inherit;
  }
  &.light {
    font-weight: normal;
  }
  &.button-sm {
    min-width: 0;
  }
  &:not(.md-icon-button) > md-icon {
    padding-bottom: 2px;
  }
}

md-menu-item {
  a.md-button {
    padding: 16px;
    &:focus,
    &:hover {
      color: unset;
    }
  }
}

md-fab-speed-dial {
  md-fab-actions {
    button.md-button.md-fab {
      padding: inherit;
    }
  }
}

md-dialog-actions {
  button.md-button {
    &:not([disabled]) {
      &:hover,
      &:focus,
      &.md-focused {
        &.md-default-theme,
        &.md-dark-theme,
        &.md-black-theme {
          &.md-primary,
          &.md-accent {
            background-color: rgba(158, 158, 158, 0.2);
          }
        }
      }
    }
  }
}
