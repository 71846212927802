abx-date-time-input {
  display: block;
}

.abx-date-time-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.abx-date-time-input__field {
  flex: 1 1 auto;
}
