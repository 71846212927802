abx-side-menu {
  display: block;
  color: lighten($gray, 30%);
  background-color: $white;
  font-weight: bold;
  overflow-x: hidden;
  overflow-y: auto;

  ul {
    list-style: none;
    padding: 0;
  }

  .side-menu {
    li,
    .side-menu-item {
      line-height: 32px;
    }

    .side-menu-item,
    .side-menu-item-child {
      &:hover,
      &.active {
        color: $color-text;
        background-color: $blue-20;
        cursor: pointer;
      }

      &.active-child {
        color: $color-text;
      }

      a,
      a:focus,
      a:hover {
        color: inherit;
        text-decoration: none;
      }
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: stretch;
      }
      .side-menu-section__name {
        margin-left: 8px;
      }
    }

    .side-menu-section {
      a {
        padding: 4px 16px;
      }
      ul.ng-hide-remove {
        animation: 0.2s fadeInDown;
      }
    }

    md-icon.side-menu-section__icon {
      margin: 0;
      color: $color-dark-gray !important;
      fill: $color-dark-gray !important;
      svg path {
        color: $color-dark-gray !important;
        fill: $color-dark-gray !important;
      }
    }

    .side-menu-section__children {
      background: $white-F9;
      border-top: 1px solid $divider-light;
      border-bottom: 1px solid $divider-light;
    }

    .side-menu-item-child {
      a {
        margin-left: 48px;

        .side-menu-section__icon {
          display: none;
        }
      }
    }
  }

  @include respond-to("sm") {
    .side-menu {
      .side-menu-item-child {
        a {
          margin-left: 0;
          padding: 12px 0;
          .side-menu-section__icon {
            display: block;
          }
        }
      }
    }
  }

  @include respond-to("gt-sm") {
    .side-menu {
      .side-menu-section__children {
        background: $white;
        border: none;
      }
    }
  }
}
