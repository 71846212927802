// -------------------------------------
//   Lists
// -------------------------------------

.abx-list {
  background: $white;
}
.abx-list-item {
  &--active,
  &[selected],
  &[selected]:focus {
    background-color: $secondary-1 !important;
    color: inherit;
  }

  &:hover {
    background-color: $secondary-3 !important;
  }

  &:hover,
  &--active,
  &[selected],
  &[selected]:focus {
    > * {
      color: $color-text !important;
    }
  }

  // Override clickable md-list item styles
  .md-button:not([disabled]):hover {
    background-color: initial;
  }
}
.abx-org-list-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
