abx-fab {
  display: block;
  position: absolute;
  margin: 16px;
  z-index: 9;

  md-fab-speed-dial {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.md-fab-top-left,
    &.md-fab-top-right,
    &.md-fab-bottom-left,
    &.md-fab-bottom-right {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
    }
    &.md-fab-top-left,
    &.md-fab-bottom-left {
      md-fab-trigger {
        align-self: flex-start;
      }
    }
    &.md-fab-top-right,
    &.md-fab-bottom-right {
      md-fab-trigger {
        align-self: flex-end;
      }
    }
    &.md-fab-top-left {
      justify-content: flex-start;
    }
    &.md-fab-top-right {
      justify-content: flex-start;
    }
    &.md-fab-bottom-left {
      justify-content: flex-end;
    }
    &.md-fab-bottom-right {
      justify-content: flex-end;
    }
  }

  md-fab-trigger > button.md-button.md-fab,
  button.md-button.md-fab.abx-primary-fab {
    margin: 8px 0 0;
  }

  .abx-fab-action {
    // Container
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // Properties
    min-width: 175px;
    padding: 14px;
    margin-top: 12px;
    background-color: $color-text;
    border-radius: 4px;

    // Font
    color: $color-white;
    font-weight: 800;
    font-size: 16px;

    &:focus,
    &:hover {
      background-color: lighten($color-text, 15%);
    }

    &__icon {
      & > md-icon {
        color: $color-white !important;
      }
    }

    &__label {
      padding-left: 25px;
      flex-grow: 1;
      text-align: left;
    }
  }

  &.left {
    left: 240px;

    @include respond-to(xs) {
      left: 0;
    }

    @include respond-to(sm) {
      left: 64px;
    }

    .abx-fab-action {
      button.md-button.md-fab.md-mini {
        order: 1;
      }
      .abx-fab-tooltip-container {
        margin-left: 8px;
        order: 2;
      }
    }
  }
  &.right {
    right: 0;
  }
  &.top {
    // Adjust the top position once it is needed
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }

  // TODO: optional
  md-fab-trigger,
  button:first-child {
    transform: scale(1);
    transition: transform 0.2s;

    &.hidden {
      transform: scale(0);
    }
  }

  @include respond-to(xs) {
    margin: 0;

    &.top {
      top: 8px;
      md-fab-speed-dial {
        top: 16px;
      }
    }
    &.left {
      left: 16px;
      md-fab-speed-dial {
        left: 0;
      }
    }
    &.right {
      right: 16px;
      md-fab-speed-dial {
        right: 0;
      }
    }
    &.bottom {
      bottom: 8px;
      md-fab-speed-dial {
        bottom: 16px;
      }
    }
    &.active {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0px;
      bottom: 0px;

      md-fab-speed-dial {
        position: absolute;
        padding: 8px 16px;
      }
    }
  }
}
