.app-building {
  width: 100%;
  max-width: 100%;

  .building-side-menu-container {
    @extend .elevation-frame-4dp;
    width: 240px;
    height: 100%;
    background-color: $white;
    z-index: 5; // TODO: (ORGS) remove, temporary hack

    abx-bldg-thumbnail {
      margin: 16px 0;
    }
  }

  .building-content {
    width: 100%;
  }
}
