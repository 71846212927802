abx-sr-list {
  display: block;

  .service-request-list-header .header-label {
    &.quick-action-arrow {
      max-width: 56px;
    }
  }

  .service-request-list-header .header-label,
  .service-request-list .service-request-list-row .service-request-list-cell {
    &.quick-action-arrow {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      user-select: none;
      width: 56px;
      min-width: 56px;
      max-width: 56px;
      padding: 8px 16px;

      .material-icons {
        font-size: 24px;
        color: $blue;
      }

      .caret-placeholder {
        &.material-icons {
          font-size: 24px;
          color: rgba(#fff, 0);
        }
      }
    }
  }

  .service-request-list-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 32px;
    height: 32px;

    .header-label {
      @extend .text-bold;
      @extend .text-uppercase;
      padding: 0 8px;
      justify-content: center;
      font-size: 14px;
    }
  }

  .service-request-location {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;

      i.material-icons {
        margin-right: 4px;
        color: $color-dark-gray;
      }
    }
  }

  .work-order-assignees {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 4px !important;

    i {
      font-size: 18px;
      color: $color-dark-gray;
    }

    &__unassigned {
      height: 100%;
      display: flex;
      font-style: italic;
      color: $color-dark-gray;
    }

    &__preview {
      height: 100%;
      max-width: calc(100% - 51px);

      &.full-preview {
        max-width: 100%;
      }

      div {
        display: flex;
      }
    }

    &__chip {
      padding-left: 5px;
      min-width: 46px;
    }
  }

  .service-request-list {
    display: flex;
    align-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    border: 2px solid $gray-DD;

    .service-request-list-loading-spinner {
      margin: 32px;
      align-self: center;
    }

    .row-divider {
      margin: 0;
    }

    .service-request-list-row {
      display: flex;
      align-items: center;
      flex: 1 0 0%;
      flex-direction: row;
      min-height: 48px;

      .service-request-list-cell {
        padding: 8px;

        &:nth-last-child(2) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .text-link {
          @extend .text-bold;
        }
      }
    }

    .empty-list-placeholder {
      @extend .text-placeholder;
      font-size: 24px;
      padding: 32px;
      text-align: center;
    }
  }
}
