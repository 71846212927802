abx-field-preloader {
  display: block;
}

%abx-field-preloader {
  background-color: $gray-EF;
}

.abx-field-preloader__dialog-list-label {
  height: 24px;
  margin: 4px 0;
}
.abx-field-preloader__dialog-list-item-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 8px;
}
.abx-field-preloader__dialog-list-item-label {
  height: 22px;
  margin: 4px 0;
}
.abx-field-preloader__label,
.abx-field-preloader__dialog-list-label,
.abx-field-preloader__dialog-list-item-label {
  @extend %abx-field-preloader;
  @extend .preloader--pulse;
  animation-name: pulse-darken;
  width: 120px;

  &.sm {
    width: 84px;
  }
  &.md {
    width: 120px;
  }
  &.lg {
    width: 176px;
  }
}
.abx-field-preloader__label {
  height: 12px;
  margin: 0 0 4px 10px;
}

.abx-field-preloader__field {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
}

.abx-field-preloader__text {
  @extend %abx-field-preloader;
  @extend .preloader--pulse;
  animation-name: pulse-darken;
  height: 100%;
  width: 100%;
}

.abx-field-preloader__circle-field,
.abx-field-preloader__pin-field {
  flex: 1;
  display: flex;
  align-items: center;
}

.abx-field-preloader__circle {
  @extend %abx-field-preloader;
  @extend .preloader--pulse;
  animation-name: pulse-darken;
  border-radius: 50%;
  margin-right: 12px;
  height: 20px;
  width: 20px;
}

.abx-field-preloader__circle-text {
  @extend %abx-field-preloader;
  @extend .preloader--pulse;
  animation-name: pulse-darken;
  height: 16px;

  &--first {
    width: 60px;
  }
  &--second {
    width: 104px;
  }
}

.abx-field-preloader__pin-text {
  @extend %abx-field-preloader;
  @extend .preloader--pulse;
  animation-name: pulse-darken;
  height: 16px;

  &--medium {
    width: 90px;
  }
  &--large {
    width: 160px;
  }
}
