@import "~material-design-icons-iconfont/dist/material-design-icons.css";

// Standard AkitaBox Components
@import "../../node_modules/@akitabox/design-components/src/sass/main.scss";
// ****************************************
//
//   Base Styles
//
// ****************************************

// -------------------------------------
//   Imports
// -------------------------------------
@import "./style/_colors";
@import "./style/_constants";
@import "./style/spacing";
@import "./style/_utils.scss";
@import "./style/_links.scss";
@import "./style/_text";
@import "./style/_elevation-frame";
@import "./style/_fonts";
@import "./style/_padding";
@import "./style/_margins";
@import "./style/_flex";
@import "./style/_icons";
@import "./style/_buttons";
@import "./style/_input";
@import "./style/_tabs";
@import "./style/_cards";
@import "./style/_dialogs";
@import "./style/_preloaders";
@import "./style/_rotation";
@import "./style/_animation";
@import "./style/_lists";
@import "./style/_charts";
@import "./style/_hr";
@import "./style/_tooltip";

@import "~font-awesome/scss/font-awesome.scss";
@import "~animate.css";
@import "~angular-tooltips/lib/angular-tooltips.scss";
// -------------------------------------
//   Layout
// -------------------------------------
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
html {
  overflow: hidden;
  font-size: 16px;
}
body {
  font-family: "Open Sans", sans-serif;
}
#react-app {
  position: relative;
  max-height: 100%;
  height: 100%;

  &.react-mode div#app {
    display: none;
  }
}
#app,
.app {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > md-progress-linear {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 3px;

    * {
      height: 3px;
    }

    &.ng-hide-add {
      animation: fadeOutUp 1s;
      -webkit-animation: fadeOutUp 1s;
    }
    &.ng-hide-remove.ng-hide-remove-active {
      animation: fadeInDown 0.3s;
      -webkit-animation: fadeInDown 0.3s;
    }
  }
}

.wf-loading .material-icons {
  visibility: hidden;
}

.wf-active .material-icons {
  visibility: visible;
}

.full-height {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.full-width {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.stretch {
  min-width: 100%;
  min-height: 100%;
}

.flex-height {
  display: flex;
  flex-direction: column;
}

.full-screen {
  @extend .full-height;
  @extend .full-width;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-select {
  user-select: none;
}

.display-none {
  display: none;
}

// -------------------------------------
//   Material Design Icons
// -------------------------------------

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

// -------------------------------------
//   Headings
// -------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

// -------------------------------------
//   Cloak
// -------------------------------------
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.ng-hide.ng-hide-animate {
  display: none !important;
}

// -------------------------------------
//   Foreground
// -------------------------------------
.fg-white {
  color: $white !important;
}
.fg-light {
  color: $white-54 !important;
}
.fg-dark {
  color: $black-54 !important;
}
.fg-black {
  color: $color-text !important;
}
.fg-primary {
  color: $color-primary !important;
}
.fg-accent {
  color: $blue !important;
}
.fg-success {
  color: $green-3C !important;
}
.fg-warn {
  color: $red-A9 !important;
}
.fg-red {
  color: $color-error !important;
}

// -------------------------------------
//   Background
// -------------------------------------
.bg-white {
  background-color: $white;
}
.bg-white-f1 {
  background-color: $white-F1;
}
.bg-gray {
  background-color: $white-F1;
}
.bg-light {
  background-color: $white-54;
}
.bg-dark {
  background-color: $black-54;
}
.bg-black {
  background-color: $color-text;
}
.bg-primary {
  background-color: $color-primary;
}
.bg-accent {
  background-color: $blue;
}
.bg-secondary {
}
.bg-success {
  background-color: $green-3C;
}
.bg-warn {
  background-color: $red-F2;
}

.circle {
  border-radius: 50%;
}

// -------------------------------------
//   Chips
// -------------------------------------
.abx-chips {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;

  md-chips-wrap {
    margin: 0;
    padding: 0;
    box-shadow: none;

    md-chip {
      line-height: 24px;
      height: 24px;
    }
  }
}

.abx-chip {
  overflow: hidden;
  word-wrap: break-word; // For IE
  overflow-wrap: break-word;
  hyphens: auto;

  span:first-child {
    @extend .text-bold;
  }

  &__text {
    &--field {
      font-weight: 600;
      margin: 0 2px 0 0;
    }
  }

  &__clear {
    display: inline-block;
    margin: 0 0 2px 2px;

    $chip-clear-dimension: 16px;
    font-size: $chip-clear-dimension;
    width: $chip-clear-dimension;
    height: $chip-clear-dimension;
    min-width: $chip-clear-dimension;
    min-height: $chip-clear-dimension;
  }
}

// -------------------------------------
//   Alignment
// -------------------------------------
.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

// -------------------------------------
//   Scroll
// -------------------------------------
.scroll-y {
  overflow-y: auto;
}

// -------------------------------------
//   Toolbar
// -------------------------------------
md-toolbar {
  .md-toolbar-tools {
    .toolbar-tools-left {
      margin-left: -16px;
    }
    .toolbar-tools-right {
      margin-right: -16px;
    }
  }

  &.md-dark-theme:not(.md-menu-toolbar) {
    color: $white;
  }
}

// -------------------------------------
//   Logo
// -------------------------------------
.abx-logo-header {
  margin: 16px;
  text-align: center;
  @media screen and (min-width: 960px) {
    margin-top: 48px;
    margin-bottom: 32px;
  }
}
img.abx-logo {
  width: auto;
  font-size: 36px;
  height: 36px;
  min-height: 36px;
}

// -------------------------------------
//   Footer
// -------------------------------------
.footer {
  background-color: $white-F1;
  padding: 8px 16px;
}

// -------------------------------------
//   Material
// -------------------------------------

@import "~angular-material/angular-material.css";

// -------------------------------------
//   Material Themes
// -------------------------------------
.md-button.md-dark-theme[disabled],
.md-button.md-dark-theme.md-accent[disabled],
.md-button.md-dark-theme.md-fab[disabled],
.md-button.md-dark-theme.md-raised[disabled],
.md-button.md-dark-theme.md-warn[disabled] {
  color: rgba(255, 255, 255, 0.54);
}

// -------------------------------------
//   Well
// -------------------------------------
.well,
.well-sm,
.well-md,
.well-lg {
  border-radius: 0;
  background-color: $white-F1;
}
.well-sm {
  padding: 8px;
}
.well-md {
  padding: 16px;
}
.well-lg {
  padding: 24px;
}

.well-primary {
  background-color: $color-primary;
  color: $black-87;
}

.well-success {
  background-color: $green-5C;
  color: $black-87;
}

.well-warn {
  background-color: $red-D9;
  color: $white-87;
}

// -------------------------------------
//   Tooltips
// -------------------------------------
md-tooltip.md-show {
  @media screen and (min-width: 960px) {
    &:not(.md-origin-top):not(.md-origin-bottom) {
      font-size: 12px;
    }
  }
  &.md-light {
    color: $color-text;
    background-color: $color-light-gray;
  }
  &.md-white {
    color: $color-text;
    background-color: $white;
  }
}

// -------------------------------------
//   Visibility
// -------------------------------------
.invisible {
  visibility: hidden;
}

// -------------------------------------
//   Print
// -------------------------------------
.show-print {
  display: none;
}

@media print {
  @page {
    margin: 0.35in;
  }
  .no-print {
    display: none !important;
  }
  .hide-print {
    visibility: hidden !important;
  }
  .show-print {
    display: block;
  }
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  * {
    overflow: visible !important;
  }

  html,
  body,
  #app,
  .app,
  #content-container {
    display: block !important;
    height: 100% !important;
  }
  html,
  body,
  #app,
  .app,
  #content,
  md-content {
    background-color: $white !important;
  }

  img {
    &.current-view-only {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: contain;
    }
  }

  md-toast {
    display: none !important;
  }
}

// -------------------------------------
//   Loading Splash Screen
// -------------------------------------
#splash-screen {
  background-color: #d8d8d8;
  margin: 0px !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    background-color: $color-light-gray;
    padding: 12px 8px 12px 18px;
  }
  .dot {
    height: 40px;
    width: 40px;
    background-color: $color-border-gray;
    border-radius: 50%;
    display: block;
  }
  .logo {
    position: absolute;
  }
  .icon {
    float: right;
    margin: 0 2px;
    display: none;
    @include respond-to(gt-sm) {
      display: inline-block;
    }
  }
  .place-holder {
    height: 16px;
    border-radius: 2%;
    background-color: $color-border-gray;
    float: left;
    clear: left;
    min-width: 10%;
    margin-left: 50px;
    display: block;
  }
  .large-block {
    width: 18%;
    margin-top: 5px;
  }

  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .svg-container svg {
    width: 100px;
    height: 100px;
  }
  .loading {
    width: 64px;
    color: $color-medium-gray;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    display: block;
  }
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
    animation: ellipsis steps(4, end) 1200ms infinite;
    content: "\2026";
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}

// injector
// endinjector

@import "../app/core/**/*.scss";
@import "../css/pin-icons.css";
