abx-stack-chart {
  display: block;
  flex: 1 1 auto;
  min-height: 305px;

  .x-axis-text {
    text-transform: capitalize;
    fill: $color-text;
  }

  .x-axis-text--rotated {
    text-anchor: end;
    transform: rotate(-90deg);
    text-transform: capitalize;
  }
}
