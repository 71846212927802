abx-building-info {
  display: block;

  .abx-building-info__top-section {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 16px;

    @include respond-to(gt-sm) {
      flex-direction: row;
      min-height: 600px;
    }

    abx-map {
      flex: 1 1 100%;
      min-height: 400px;

      // Tablet
      @include respond-to(gt-sm) {
        flex: 1 1 50%;
        max-width: 50%;
      }
      // Desktop
      @include respond-to(gt-md) {
        flex: 1 1 66%;
        max-width: 66%;
      }
    }

    .building-properties {
      flex: 1 1 auto;
      align-self: flex-start;
      min-width: 100%;
      max-width: 100%;

      // Tablet
      @include respond-to(gt-sm) {
        flex: 1 1 50%;
        margin-left: 16px;
        min-width: 0;
        max-width: 50%;
      }
      // Desktop
      @include respond-to(gt-md) {
        flex: 1 1 33%;
        min-width: 0;
        max-width: 33%;
      }

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      @extend .pad-4;

      .building-properties__split {
        display: flex;
        flex-direction: column;

        @include respond-to(gt-xs) {
          flex-direction: row;
          flex-wrap: wrap;

          .building-property {
            max-width: 50%;
          }
        }
      }

      .building-properties__name {
        display: flex;
        min-height: 56px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        border-bottom: 1px solid $blue;

        md-input-container.building-name {
          max-width: 65%;
          margin: 0;
          md-select {
            md-select-value {
              font-size: 20px;
              @extend .text-bold;
            }
          }
          @include respond-to("gt-xs") {
            max-width: 80%;
          }
          @include respond-to("gt-sm") {
            max-width: 65%;
          }
        }

        h4 {
          @extend .text-bold;
          font-size: 20px;
        }
      }

      .building-property {
        flex: 1 1 auto;
        padding: 8px 0;
      }

      .building-property__label {
        @extend .text-bold;
        padding-bottom: 4px;
        color: $color-dark-gray;
      }

      #portal-url-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        max-height: 20px;

        button.md-button {
          min-width: 40px;
        }
      }
    }
  }

  .abx-building-info__floors {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 414px;

    @include respond-to(gt-sm) {
      margin-top: 64px;
    }

    #abx-floor-card-container {
      padding: 0;
      background-color: unset;
    }
  }

  .abx-building-info__asset-stats,
  .abx-building-info__room-stats {
    abx-room-stats {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.abx-building-info-select-container {
  margin: -1px 0 0 -2px;
}
