.bulk-add-relationships-dialog {
  position: relative;

  &__retry-container {
    display: flex;
    justify-content: center;
    margin: 0;
    gap: 1.5rem;

    strong {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40px;
      line-height: 16px;
      height: auto;
      color: $red;
    }

    button.btn-link {
      display: inline-flex;
      align-self: stretch;
      align-items: center;
      min-width: fit-content;
      text-transform: capitalize;
      border: none;
      padding: 0;
      margin: 0;

      &:hover {
        background-color: initial;
      }
    }
  }

  &__help-text {
    font-size: 1.45rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__selected-items-info {
    display: block;
    text-align: center;
    max-width: fit-content;
    margin: 0 auto 2rem;
  }

  &__body {
    display: flex;
    align-self: baseline;
    align-items: stretch;
    flex-direction: column;

    .grid {
      display: grid;
      column-gap: 2rem;

      &.three-columns {
        grid-template-columns: repeat(3, 1fr);
      }

      &.two-columns {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
