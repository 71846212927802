.bulk-edit-pinfield-dialog {
  &__retry-container {
    display: flex;
    justify-content: center;
    margin: 0;
    gap: 1.5rem;

    strong {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40px;
      line-height: 16px;
      height: auto;
      color: $red;
    }

    button.btn-link {
      display: inline-flex;
      align-self: stretch;
      align-items: center;
      min-width: fit-content;
      text-transform: capitalize;
      border: none;
      padding: 0;
      margin: 0;

      &:hover {
        background-color: initial;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 0 100%;
  }

  &__list-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    align-self: stretch;
    gap: 0 1.5rem;
  }

  &__pin-input-component {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-self: center;
    align-items: stretch;
  }

  &__clear-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    md-checkbox {
      margin-bottom: 0;
    }
  }

  &__help-text {
    text-align: center;
    max-width: 525px;
    margin: 0 auto 16px auto;
  }
}
