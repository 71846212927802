.work-order-log-error-list {
  max-height: 480px;
  overflow-y: auto;
  border-top: 1px solid $color-border-gray;
  &__item {
    margin: 16px;
  }
  &__error {
    padding: 0 8px;
    color: $color-error;
  }
}
