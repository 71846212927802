abx-reporting-filter-bar {
  display: block;

  .reporting-filter-bar {
    display: flex;
    flex-direction: row;
    min-height: 48px;
    background-color: $color-link;

    .filter-bar-select {
      flex: 1 1 25%;
      max-width: 25%;
      padding: 8px;

      tooltip.tooltips {
        width: 100%;
      }
    }
  }

  .reporting-filter-chips {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 56px;

    &--disabled {
      min-height: 0;
    }

    .filter-chips__clear-all {
      margin: 8px;
      color: $color-link;
    }

    .filter-chips {
      border: 2px solid $gray-CC;
      border-radius: 2px;
      margin: 8px;
      padding: 4px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.filter-chips__disabled {
        background-color: $color-light-gray;
        cursor: not-allowed;
        color: $color-dark-gray;
      }

      .filter-chips__type {
        font-weight: bold;
        text-transform: uppercase;
      }

      .filter-chips__name {
        margin: 0px 6px;
      }

      .filter-chips__clear {
        min-height: unset;
        min-width: unset;
        font-size: 20px;
        color: $color-link;
      }
    }
  }
}
