abx-decommissioned-header {
  .decommissioned-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $color-error;
    overflow: hidden;
    width: 100%;
    min-height: 37px;
    padding: 4px 4px 4px 24px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    color: $color-white;
    font-weight: 600;

    .date-text {
      font-style: italic;
      text-decoration: underline;
      text-decoration-color: $color-white;

      &.read-only {
        text-decoration: none;
      }
    }
  }
}
