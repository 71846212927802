body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

/* styles that should only apply to our online editor */
.ck.ck-content.ck-editor__editable {
  min-height: 297mm;
  border: 1px solid hsl(0, 0%, 88%);
  border-radius: var(--ck-border-radius);
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);
}

/* styles mainly for the export pdf, but applies to both the editor and pdf export */
.ck-content {
  word-wrap: break-word;
  padding: 0 var(--ck-spacing-standard);
  background: transparent;
  width: 210mm;
  box-sizing: content-box;
}

.ck-content > :first-child {
  margin-top: var(--ck-spacing-large);
}
.ck-content > :last-child {
  margin-bottom: var(--ck-spacing-large);
}

.ck-content p {
  margin: 0 0 10px;
}

.ck-content img {
  /* Force all images in ck-editor to conform to the width of the page, this helps mostly with exporting to pdf */
  max-width: 100%;
  height: auto;
}

.ck-content .table thead {
  display: table-row-group;
}
.ck-content .table tr {
  break-inside: avoid;
  break-after: auto;
}

/* This needs to change each time AssetWidget's style sheet changes, the ss.img */
.ck-content .raw-html-embed img,
.ck-content .asset-image-contained img {
  border: 1px solid #d9d9d9;
  background-color: #eee;
  height: 225px;
  aspect-ratio: 4 / 3;
  object-fit: contain;
  max-width: 300px;
}

.ck-content figure {
  page-break-inside: avoid;
  break-inside: avoid;
}
