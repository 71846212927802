abx-markup-tool-drawer {
  display: block;
  $button-height: 40px;
  $button-width: 44px;
  $add-button-width: calc(4 * #{$button-width});
  $drawer-height: 44px;

  .abx-markup-tool-drawer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    height: $drawer-height;
    transition: all 0.3s;
    right: 0;
    transform: translateX(calc(100% - #{$button-width}));
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-bottom: 4px solid $blue;

    &.open {
      transform: translateX(0);
    }

    & > * {
      flex-grow: 0;
    }

    .dropdown-arrow {
      min-height: 1em;
      min-width: 1em;
      font-size: 16px;
      margin: 0 -7px;
      vertical-align: bottom;
    }

    &-btn {
      height: $button-height;
      min-width: 44px;
      border-radius: 0;
      margin: 0;
      padding: 8px 0 10px 0;
      background-color: $white;

      &:hover:not([disabled]) {
        background-color: $secondary-1;
      }

      &.no-pad {
        padding: 0;
      }

      button {
        box-shadow: none;
        background-color: $white;
        overflow: visible !important;
      }

      md-icon {
        color: $color-text;
      }
    }
    &-new {
      color: $color-link;
      font-size: 14px;
      height: $button-height;
      width: $add-button-width;
      margin: 0;
      padding: 10px 6px 10px 0;
      background-color: $white;

      &:hover:not([disabled]) {
        background-color: $secondary-1;
      }
    }

    &-new:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 0 0px 1.5px $color-border-gray;
      z-index: 1;
      border: solid 3px transparent;
    }

    &-dropdown {
      // Set position to relavive so that that dropdown will anchor to the corner
      position: relative;
      font-size: 14px;
      height: $button-height;
      width: $add-button-width;
      margin: 0;
      padding: 2px;
      background-color: $white;

      display: flex;
      flex-direction: row;

      &__name {
        display: inline-block;
        flex-basis: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px;
        border: solid 1px $color-border-gray;
        width: 100%;

        &--selected {
          background-color: $color-light-gray;
        }

        &--visibility_off {
          color: $color-medium-gray;
          font-style: italic;
        }
      }

      &__action {
        cursor: pointer;
        flex-basis: 10px;
        padding: 8px 0px;
        border: solid 1px $color-border-gray;
        user-select: none;

        //  Make carrot  blue
        & > md-icon {
          color: $blue;
        }

        &--selected {
          background-color: $secondary-1;
        }

        &:hover:not([disabled]) {
          background-color: $blue;

          & > md-icon {
            color: $white;
          }
        }
      }
    }

    .save-state-notifier {
      position: absolute;
      display: flex;
      top: $drawer-height;
      right: 0px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      height: 22px;
      width: 225px;
      padding: 0 6px;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);

      md-icon {
        margin: 0;
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        min-width: 1em;
        min-height: 1em;
        width: 1em;
        height: 1em;
      }

      &.synced {
        background-color: #666;
      }

      &.saving {
        background-color: #999;
      }

      &.failed {
        background-color: $color-error;
        width: 350px;
        height: 32px;
        font-size: 14px;
        justify-content: center;
      }

      .loading:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0;
      }

      @keyframes ellipsis {
        to {
          width: 1.25em;
        }
      }
    }

    &-container {
      box-shadow: none;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;

      button[disabled] {
        cursor: not-allowed;
        background-color: $white;

        md-icon {
          cursor: not-allowed;
          color: $color-medium-gray;
        }
      }

      .md-button {
        border-left: 1px solid $color-border-gray;

        &.disabled,
        &[disabled] {
          md-icon:not(.highlighter) {
            color: $color-medium-gray;

            svg {
              * {
                fill: $color-medium-gray !important;
              }
            }
          }
        }

        &.selected {
          overflow: visible;
          background-color: $color-link;

          md-icon:not(.highlighter) {
            color: $white;

            svg {
              * {
                fill: white !important;
              }
            }
          }

          &:hover {
            background-color: $color-link-hover;
          }
        }

        &.saving md-icon.save-icon {
          padding-top: 0px;
          animation: spin 1s ease-in-out infinite;

          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }

        md-icon {
          color: $color-link;

          &.save-icon {
            font-size: 30px;
            padding-top: 2px;

            &.just-saved {
              color: #4ca54c;
            }
          }
        }

        & > .color-modifier {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #dcdcdc;
          position: absolute;
          top: 12px;
          left: 11px;
        }
      }
    }
  }

  .rel-container {
    position: relative;
  }
}
