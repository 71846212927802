.markup-generic-layer-list {
  // Variables
  $container-width: 400px;

  @extend .elevation-frame-6dp;

  background-color: $white;
  display: block;
  position: absolute;
  left: 0px;
  top: 45px;
  width: $container-width;

  &__header {
    border-bottom: 1px solid $color-medium-gray;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 2px;

    & > span {
      padding: 10px;
      font-weight: bold;
    }

    // Only care for the button inside the header dropdown
    & > .btn-link.md-button.abx-button {
      border-left: none !important;
      margin: 0px !important;
      padding: 0px !important;

      &.btn-loading .material-icons {
        animation: spin 1s ease-in-out infinite;

        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  &__list {
    overflow-y: auto;
    max-height: 400px;
  }
}
