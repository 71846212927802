.label-paste {
  display: flex;
  :hover > & {
    background-color: #e2f1fb;
    a {
      visibility: inherit;
    }
  }
  label {
    margin: 0 8px 0 0;
    font-weight: normal;
  }
  a {
    text-align: end;
    visibility: hidden;
  }
  .label-input {
    border: 0px;
    width: 100%;
  }
}
