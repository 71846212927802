.abx-association-dialog {
  &__preloader-line {
    @extend .preloader;
    @extend .preloader-line;
    @extend .preloader-pulse;
    width: 100%;
    height: 24px;
    margin-bottom: 16px;
  }
  &__preloader-block {
    @extend .preloader;
    @extend .preloader-block;
    @extend .preloader-pulse;
    width: 100%;
    height: 250px;
  }

  &__inputs-container {
    button {
      width: 100%;
    }
  }

  &__input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 3px;

    abx-input {
      flex: 48%;
      width: 100%;
      box-sizing: border-box;

      &:nth-child(odd) {
        margin-right: 8px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    hr {
      width: 100%;
      color: $color-border-gray;
      border-top-width: 3px;
    }
  }

  &__add-container {
    button {
      margin: 0;
    }
  }

  .abx-force-flex-wrap {
    flex-basis: 100%;
  }

  .warning {
    font-style: italic;
  }
}
