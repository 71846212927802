.abx-multiple-assignee-input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 16px;

  &__assignee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  &__remove-assignee {
    display: flex;
    text-align: right;
    flex-basis: 40px;
    color: $color-error;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;

    i.disabled {
      color: #97929248;
      cursor: not-allowed;
    }

    &.mobile {
      flex-basis: 30px;
      margin-bottom: 25px;
      margin-left: 10px;
    }
  }

  // Type ahead for already existing assignee
  abx-input {
    flex: 1 1 auto;
  }
}
