.reporting-container {
  @include respond-to(gt-sm) {
    padding: 16px 16px 128px;
  }

  .reporting-card {
    @extend .card-2dp;
    @extend .bottom-border;
    @extend .m-16-bot;

    display: block;
    padding: 0;

    .empty-chart {
      @extend .text-placeholder;
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    .card-header {
      abx-date-input {
        input {
          width: 92px;
        }
      }
      .reporting-date-filter {
        display: flex;
        align-items: center;
        min-width: 20%;

        .abx-range-input__wrapper {
          .abx-range-input__separator {
            padding: 15px 3px;
          }
          .abx-input__error-container {
            display: none;
          }
          .abx-input:not(.abx-input--invalid) {
            .abx-input__field {
              background-color: $white;
            }
          }
        }

        .reporting-date-filter__date-select {
          margin: 0 16px;
          flex: 1 1 50%;
          min-width: 160px;
          max-width: 50%;

          md-select {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .card-content {
      abx-tab {
        display: flex;
        flex-direction: column;

        .div {
          flex: 1;
        }
      }

      section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .reporting-card__wo-source-filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .source-label {
          padding: 16px;
          text-align: right;
          flex: 1;
        }

        .abx-input__field--light {
          @include respond-to(gt-md) {
            flex: 1 1 30%;
            max-width: 30%;
          }

          flex: 1 1 50%;
          max-width: 50%;

          padding-top: 8px;
        }
      }

      .card-subheader {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 32px;
        padding: 0 8px;

        &:first-child {
          margin-top: 16px;
        }

        abx-help-tooltip {
          padding-left: 8px;
        }

        .card-subheader-title {
          font-size: 18px;
        }
      }
    }
  }

  tooltip tip {
    min-width: 150px;
    @include respond-to(gt-md) {
      min-width: 240px;
    }
  }

  .reporting-card__statistics {
    min-height: 950px;

    @include respond-to(gt-xs) {
      min-height: 514px;
    }
    @include respond-to(gt-sm) {
      min-height: 316px;
    }
    @include respond-to(gt-md) {
      min-height: 201px;
    }
  }

  abx-reporting-kpi {
    width: 100%;
  }

  .chart-container {
    min-height: 400px;

    .chart {
      position: relative;
      min-height: 305px;
    }
  }

  .chart-legend {
    min-height: 56px;
  }

  abx-y-axis-switcher {
    margin-right: 16px;
    padding-top: 40px;
  }

  abx-stack-chart {
    width: 100%;
    z-index: 2; // above y-axis switcher
  }

  @media print {
    padding: 0;

    * {
      color: #000 !important;
    }

    .reporting-card {
      margin-top: 16px;
      border: none;

      .card-header {
        border: none;
      }

      .card-content {
        padding: 0 !important;
      }
    }

    abx-tab-content {
      padding: 8px;
      border: none;
    }

    .reporting-card {
      .card-header {
        background-color: $white;
      }
    }

    .md-select-icon {
      display: none;
    }

    .abx-date-input__datepicker-button {
      display: none;
    }

    .reporting-card__wo-source-filter {
      abx-input {
        flex: none !important;
      }
    }

    section {
      page-break-after: always;
    }
  }
}
