abm-field-container {
  &.no-errors-spacer .abm-field-value-container {
    .abm-field-value,
    .abm-field-placeholder {
      margin-bottom: 16px;
    }
  }
  &.abm-list-field {
    .abm-field-value {
      max-height: none;
    }
  }
  .abm-field-label {
    font-size: 1em;
    font-weight: bold;
    color: $gray-light;
  }
  .abm-field-value,
  .abm-field-placeholder {
    margin-bottom: 24px;
    font-size: 1.125em;
    font-weight: lighter;
  }
  .abm-field-value {
    max-height: 174px;
    overflow: hidden;
    color: $gray-light;
    word-wrap: break-word;
  }
  .abm-field-placeholder {
    font-style: italic;
    color: $black-38;
  }
  .show-more-container {
    margin-top: -24px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    button.md-button {
      margin: 0;
      padding: 4px;
    }
  }

  abx-datepicker {
    padding: 3px 0 1px 2px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: lighter;
  }

  abx-datepicker input {
    flex: 1 1 auto;
  }

  md-input-container {
    min-height: 53px;
    margin: 0 -2px 0;
    padding: 0;

    md-select {
      font-weight: lighter;
      md-select-value.md-select-value span {
        padding-bottom: 2px;
      }
    }

    input,
    textarea,
    md-select {
      padding: 0px;
      font-size: 1.125em;
      font-family: "Open Sans", sans-serif;
      font-weight: lighter;
    }

    textarea.md-input {
      padding-top: 3px;
      line-height: 24px;
    }
  }
  md-autocomplete {
    md-autocomplete-wrap {
      md-input-container {
        label {
          display: none !important;
        }
        input:not(:focus) {
          color: $black-87 !important;
        }
      }
    }
    &[md-floating-label] {
      .md-show-clear-button {
        button {
          top: -3px;
          background-color: transparent;
          border-radius: 0;
        }
      }
    }
  }

  &[md-truncate] {
    abm-field-value {
      display: inline-block;
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
    }
  }

  .abm-disabled {
    label {
      color: $black-38;
    }
  }
}
