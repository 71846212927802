abx-dashboard-tasks {
  display: block;

  .abx-dashboard-tasks__toggles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include respond-to(gt-sm) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .abx-dashboard-tasks__toggle {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    max-width: 50%;
    margin-top: 16px;

    @include respond-to(gt-sm) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    label {
      @extend .text-uppercase;
      margin-bottom: 8px;
      padding-left: 16px;
      font-size: 12px;
    }

    abx-radio-button,
    .radio-button-option {
      position: relative;
      display: flex;
      flex: 1 1 50%;
      flex-direction: row;
      min-width: unset;
      max-width: 50%;
      height: 64px;
      margin: 0;

      color: $color-text;
      background: $color-light-gray;
      border: 2px solid $color-border-gray;
      border-radius: 0;

      &:last-of-type {
        border-left: none;
      }

      &:hover {
        background: darken($color-light-gray, 3%);
        border-color: darken($color-border-gray, 3%);
      }

      .abx-dashboard-tasks__radio-button-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 8px;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;

        i.material-icons {
          font-size: 18px;
          color: $color-dark-gray;
          fill: $color-dark-gray;
        }
        span {
          font-size: 8px;
          @include respond-to(gt-sm) {
            font-size: 10px;
          }
        }

        @include respond-to(gt-sm) {
          padding: 0 16px;
        }
      }

      .abx-dashboard-tasks__radio-button-count {
        font-size: 16px;

        @include respond-to(gt-sm) {
          font-size: 32px;
        }

        .preloader-line {
          height: 32px;
          width: 64px;
        }
      }

      &.radio-button-option--selected {
        color: $color-white;
        background: $color-secondary;
        border: 2px solid $color-secondary;

        &:hover {
          border-color: $color-secondary;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          margin: 0 auto -2px; // border height
          border-left: 12px solid rgba(0, 0, 0, 0);
          border-right: 12px solid rgba(0, 0, 0, 0);
          border-bottom: 12px solid $color-white;
        }

        .abx-dashboard-tasks__radio-button-icon {
          i.material-icons {
            color: $color-white;
            fill: $color-white;
          }
        }
      }

      &:first-child:not(.radio-button-option--selected) {
        border-left: 2px solid $color-border-gray;
      }
      &:last-child:not(.radio-button-option--selected) {
        border-right: 2px solid $color-border-gray;
      }
    }

    .max-width-100 {
      max-width: 100%;
    }

    &:first-child {
      abx-radio-button,
      .radio-button-option {
        &:first-child:not(.radio-button-option--selected) {
          border-left: none;
        }
      }
    }

    &:last-child {
      abx-radio-button,
      .radio-button-option {
        &:first-child {
          .abx-dashboard-tasks__radio-button-icon {
            i.material-icons {
              color: $color-graph-8;
              fill: $color-graph-8;
            }
          }
        }
        &:last-child {
          .abx-dashboard-tasks__radio-button-icon {
            i.material-icons {
              color: $color-success;
              fill: $color-success;
            }
          }
          &:not(.radio-button-option--selected) {
            border-right: none;
          }
        }
      }
    }
  }
  .abx-dashboard-tasks__toggles-divider {
    width: 16px;
    background-color: $color-white;
    margin-top: 28px;
  }
  .abx-dashboard-tasks__spacer {
    display: flex;
    flex-direction: column-reverse;

    height: 112px;
    padding: 0 8px;
    transition: height 0.5s ease;

    @include respond-to(gt-sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 56px;
    }

    .abx-dashboard-tasks__help-text,
    .abx-dashboard-tasks__my-overdue,
    .abx-dashboard-tasks__total-overdue {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .abx-dashboard-tasks__help-text {
      height: 56px;

      .high-priority {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $color-error;
        font-size: 18px;
      }

      a.abx-dashboard-tasks__overdue-text {
        @extend .abx-link;
        @extend .abx-link--destructive;
        padding-left: 8px;

        .abx-dashboard-tasks__overdue-number {
          font-weight: bold;
        }
      }

      .caret-placeholder {
        color: $akita-blue;
      }
    }
  }
  .abx-dashboard-tasks__intent-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 56px;
    margin: 0;
    font-size: 14px;

    @include respond-to(gt-sm) {
      justify-content: flex-end;
    }

    .dashboard-radio-button-container {
      display: flex;
      align-items: center;
      margin: 0 12px;
    }

    .dashboard-radio-button {
      margin: 0;
    }
  }
  .abx-dashboard-tasks__view-all {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 8px 0 16px;
    padding: 0 16px;

    button.md-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;

      @include respond-to(xs) {
        flex: 1 1 100%;
        justify-content: center;
      }
    }

    .material-icons {
      margin-left: 8px;
      color: $color-black;
      float: right;
    }
  }

  .work-order-intent__reactive {
    display: flex;
    justify-content: center;
    position: relative;

    .reactive-triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 21.7px 12.5px;
      border-color: transparent transparent $blue-2E transparent;
      _border-color: $color-black $color-black $blue-2E $color-black;
      line-height: 0px;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    .reactive-label {
      position: absolute;
      top: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      color: $white;
      font-weight: bold;
    }
  }
  .work-order-intent__preventive {
    display: flex;
    justify-content: center;
    border: 2px solid $blue-2E;
    border-radius: 50%;
    min-width: 25px;
    color: $blue-2E;
    font-weight: bold;
  }

  @include respond-to(gt-xs) {
    .work-order-intent__reactive,
    .work-order-intent__preventive {
      margin-left: 4px;
    }
  }

  abx-wo-list .work-order-list,
  abx-sr-list .service-request-list {
    border: none;
  }

  // abx-sr-list {
  //   margin-top: -104px; // height of mobile spacer + 8px, eliminates large gap

  //   @include respond-to(gt-sm) {
  //     margin-top: 0;
  //   }
  // }
}
