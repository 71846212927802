.abx-create-schedule-dialog {
  .abx-create-schedule-dialog__repeat-inputs {
    align-items: flex-end;
  }
  .abx-create-schedule-dialog__freq-summary {
    padding: 8px;

    label {
      font-size: 12px;
    }
  }
}
