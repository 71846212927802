.abm-autocomplete-clear-button {
  position: absolute;
  right: 0;
  top: 6px;
  margin-right: 2px;
  margin-left: auto;
  cursor: pointer;
}
.abm-autocomplete-dialog {
  md-toolbar.abm-autocomplete-toolbar {
    z-index: 5;
    .md-toolbar-tools input {
      border-width: 0;
      outline-style: none;
    }
  }
}
