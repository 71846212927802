abx-block-radio {
  display: flex;
  border-radius: 3px;
  border: 1px solid black;
  overflow: hidden;

  .option {
    flex: 1;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid $color-dark-gray;
    }

    &:not(.selected) {
      background-color: $gray-EF;
      color: $color-dark-gray;
    }
    &.selected {
      background-color: $color-secondary;
      color: $white;
    }
  }
}
