abx-round-stop-list-item {
  display: flex;
  border-bottom: 1px solid $color-border-gray;

  .abx-stop-header {
    background-color: $color-white;
    width: 100%;
    padding: 0px;
    min-height: 36px;
    display: flex;
    align-items: center;
    line-height: inherit;
    letter-spacing: inherit;

    &:hover {
      background-color: rgba(45, 141, 217, 0.2) !important;
    }

    &__select {
      display: flex;
      height: 100%;
      border-right: 1px solid $color-border-gray;

      &--checked {
        background: lighten($color-link, 40%);
        border-right: 1px solid $color-link;
      }

      .checkbox-select {
        margin: 0 0 0 18px;
        padding-right: 8px;
      }
    }

    &__icon {
      abx-pin-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 16px;
        .abx-pin-type-icon-wrap {
          margin: 0;
        }
      }
      .floor-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        margin-right: 16px;
        border-radius: 50%;
      }
    }

    &__info {
      .abx-stop-title,
      .abx-stop-subtitle {
        text-transform: initial;
      }
      .abx-stop-title {
        font-size: 14px;
        color: $color-link;
        font-weight: 600;
      }
      .abx-stop-subtitle {
        font-size: 12px;
        color: $color-dark-gray;
        font-weight: normal;
      }
      .abx-stop-indicator {
        margin-left: 4px;
      }
    }

    .abx-stop-info {
      padding: 8px 16px;
    }
  }

  .abx-pin-type-wrap {
    position: relative;
    height: 24px;

    &.pass {
      &::after {
        position: absolute;
        font-family: "Material Icons";
        content: "\e876";
        color: white;
        background-color: $color-success;
        text-align: center;
        display: block;
        left: 12px;
        top: -4px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        font-weight: normal;
        border-radius: 50%;
        line-height: 14px;
        border: 1.5px solid white;
      }
    }
    &.fail {
      &::before {
        position: absolute;
        font-family: "Material Icons";
        content: "\e14c";
        color: white;
        background-color: $color-error;
        text-align: center;
        display: block;
        left: -4px;
        top: -4px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        font-weight: normal;
        border-radius: 50%;
        line-height: 14px;
        border: 1.5px solid white;
      }
    }

    &.skip {
      &::after {
        content: "";
        display: block;
        height: 160%;
        background-color: $color-dark-gray;
        border: 1px solid white;
        width: 4px;
        transform: rotate(45deg);
        position: absolute;
        left: 9px;
        top: -7px;
      }
    }
  }
}
