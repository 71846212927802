// -------------------------------------
//   Text
// -------------------------------------
.text-capitalize {
  text-transform: capitalize;
}
.text-small-caps {
  font-variant: small-caps;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}
.text-light {
  font-weight: lighter;
}
.text-italic {
  font-style: italic;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-monospace {
  font-family: "Lucida Console", Monaco, monospace;
}
.text-break-word {
  word-break: break-all;
}
.text-readonly {
  color: rgba(0, 0, 0, 0.38);
}
.text-placeholder {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color: $color-medium-gray;
}
.preserve-whitespace {
  white-space: pre-wrap;
}
