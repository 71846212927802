.abx-stop-item-question-list {
  &__question {
    width: 100%;
    margin: 8px 0 16px;
    padding: 0 24px;
    overflow-wrap: break-word;

    .abx-stop-item-controls {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      button.abx-button {
        flex-grow: 1;
        margin: 0 5% 0 0;
        min-width: 30%;

        &:last-child {
          margin-right: 0;
        }
        &.btn-secondary {
          &:focus {
            border: 2px solid $color-border-gray;
          }
        }
        &.btn-pass {
          &:disabled,
          &:disabled:hover,
          &:disabled:focus,
          &:disabled:active {
            @extend .btn-pass;
            color: $color-text;
            background: white;
          }
        }
        &.btn-fail {
          &:disabled,
          &:disabled:hover,
          &:disabled:focus,
          &:disabled:active {
            @extend .btn-fail;
            color: $color-text;
            background: white;
          }
        }
      }
      @include respond-to(gt-xs) {
        min-width: 88px;
      }
    }

    .abx-stop-item-header {
      position: relative;
      left: -10px;
      font-size: 14px;
      color: $color-text;
      font-weight: 600;
    }

    .abx-stop-item-enum-controls {
      font-weight: 600;
      margin-top: 16px;

      &__grid-item {
        align-items: center;
        display: inline-flex;
        width: 50%;

        @include respond-to(xs) {
          width: 100%;
        }
      }

      .chkbx-pass.md-checked div.md-icon {
        background-color: $color-success;
      }
      .chkbx-fail.md-checked div.md-icon {
        background-color: $color-fail;
      }

      .md-label {
        margin-right: 4px;

        & span {
          font-size: 12px;
          word-break: break-all;
          /* non-standard / ie11 */
          word-break: break-word;
          hyphens: auto;
          max-width: 100%;
        }
      }
    }
    .abx-stop-item-index {
      float: left;
      position: relative;
      left: -10px;
      font-size: 12px;
      color: $color-medium-gray;
      font-weight: 600;
    }

    .abx-stop-item-text {
      font-size: 12px;
      font-weight: 600;
      color: $color-text;
      width: 100%;

      &-required {
        color: $blue;
      }
    }

    .abx-stop-item-signature {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      &-input {
        flex-grow: 1;
        .abx-input__field {
          background-color: transparent;
        }
        &:not(.editing) .abx-input__field {
          font-family: "Herr Von Muellerhoff", cursive;
          font-size: 2em;
          color: $color-text;
          padding-left: 0;
          border: none;
        }
        .abx-input__error-container {
          min-height: 0;
        }
      }

      button {
        color: $color-link;
        font-size: 14px;
        margin: 0 0 0 12px;
      }

      &-timestamp {
        font-size: 10px;
        color: $color-text;
      }
    }

    .abx-stop-item-markdown {
      font-size: 12px;
      font-weight: 600;
      color: $color-text;
      width: 100%;
      padding-left: 1em;

      ul {
        list-style-type: disc;
        ul {
          list-style-type: circle;
          ul {
            list-style-type: square;
          }
        }
      }
      img {
        max-width: 35%;
      }

      table {
        display: block;
        width: 100%;
        overflow: auto;
      }

      table th {
        background-color: $color-dark-gray;
        color: $color-white;
        font-weight: 600;
      }

      table td,
      table th {
        padding: 6px 13px;
        border: 1px solid $color-border-gray;
      }

      table tr {
        background-color: $color-white;
        border-top: 1px solid $color-border-gray;
      }

      table tr:nth-child(2n) {
        background-color: $color-light-gray;
      }
    }
  }
}
