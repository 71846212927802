abx-breadcrumb-header {
  padding: 6px 20px;
  display: block;
  background-color: #494d5c;
  color: $color-white;

  .breadcrumb-header {
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;

      &__prefix {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }

      &__info {
        display: flex;
        flex-direction: column;

        .breadcrumb-trail {
          display: flex;
          .breadcrumb-item {
            display: flex;

            md-icon.breadcrumb-item__icon {
              font-size: 14px;
              margin: 2px 6px 0 0;
              color: $color-white;
              fill: $color-white;
              opacity: 0.5;

              svg path {
                color: $color-white;
                fill: $color-white;
                opacity: 0.5;
              }
            }

            &__name {
              color: $color-white;
              text-decoration: underline;
              font-size: 12px;
            }
            &__chevron {
              padding: 0 8px;
              width: 32px;
              > md-icon {
                width: 0.3em;
                min-width: unset;
                font-size: 12px;
                color: $color-white;
                position: relative;
                bottom: 1px;
              }
            }
          }
        }
        .breadcrumb-header-title {
          margin: 4px 0 0 0;
          font-size: 16px;
        }
      }
    }
    &__right {
      .abx-button {
        margin: 0;
      }
    }
  }
}
