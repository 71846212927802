.abx-work-order-detail-dialog {
  &__field-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
    margin-bottom: 10px;

    & > * {
      flex: 0 0 33%;
    }
  }

  &__field {
  }

  abx-field-preloader:not(:last-child) {
    padding-right: 16px;
  }

  abx-field-preloader {
    height: 30px;
    margin-bottom: 10px !important;
    div.abx-field-preloader__field {
      height: 24px;
    }
  }

  &__field-name {
    font-weight: bold;
    font-size: 80%;
    margin-bottom: 5px;
  }

  &__field-value {
  }

  &__description {
    margin: 0;
  }

  &__assignees,
  &__locations {
    white-space: pre-line;
  }

  &__not-provided {
    font-style: italic;
    color: $color-dark-gray;
  }
}
