abx-map {
  display: flex;
  flex: 1;
  background-color: #e5e5e5;

  ui-gmap-google-map {
    display: flex;
    flex: 1;

    .angular-google-map {
      display: flex;
      flex: 1;

      .angular-google-map-container {
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }

    .info-window-title {
      margin-bottom: 8px;
    }
  }
}
