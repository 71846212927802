.room-filter-bar-container {
  @extend .asset-filter-bar-container;
}

.room-list-wrapper {
  flex: 1;
  display: flex;
  overflow: auto;

  & > abx-pin-detail-sidebar {
    // makes sidebar non-absolute positioned
    display: flex;
    overflow: initial !important;

    & > .room-list-sidenav {
      position: relative;
    }
  }
}
