abx-multi-round-stop-item {
  display: block;
  border-bottom: 1px solid $color-border-gray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;

  .abx-stop-item {
    background-color: $color-white;
    height: 100%;
    overflow: hidden;

    .abx-stop-header {
      width: 100%;
      min-height: 36px;
      padding: 8px 0 8px 16px;
      display: flex;
      align-items: center;
      border-bottom: 3px solid $color-link;
      margin-bottom: 8px;

      &__action-buttons {
        margin-left: auto;
        color: $color-link;
        margin-right: 6px;
      }

      .abx-stop-info {
        display: flex;
        flex-direction: row;
        min-width: 30%;
        &__info {
          .abx-stop-title,
          .abx-stop-subtitle {
            text-transform: initial;
          }
          .abx-stop-title {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            font-weight: 600;

            &__button {
              min-height: 20px;
              padding: 0;
              margin: 0 0 0 16px;
            }

            .stop-info-tooltip-container {
              display: flex;
              align-items: center;
              margin-left: 8px;
            }
          }
          .abx-stop-subtitle {
            font-size: 12px;
            color: $color-dark-gray;
            font-weight: normal;
          }
        }
      }
    }

    .abx-stop-content {
      position: absolute;
      /* Needs adjustment if header height gets adjusted */
      top: 59px;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 8px;
      overflow: auto;

      &__container {
        width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        margin: 0 8px;
      }

      .abx-complete-spinner {
        margin: 32px;
        align-self: center;
      }

      .abx-complete-percent {
        align-self: center;
        font-size: 24px;
      }

      .abx-completing-text {
        font-size: 20px;
        font-style: italic;
        color: $color-dark-gray;
        align-self: center;
      }

      .abx-stop-error-list {
        display: flex;
        flex-direction: column;

        .abx-stop-error-row {
          display: flex;
          flex-direction: row;

          .abx-stop-error-message {
            color: $color-error;
          }

          .abx-stop-error-name {
            font-weight: bold;
          }
        }
      }

      .abx-stop-complete {
        border-top: 1px solid $color-border-gray;
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .abx-stop-complete-text {
          font-size: 12px;
          font-style: italic;
          color: $color-dark-gray;

          &__warning {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
          }
        }

        &.skippable {
          flex-wrap: wrap;
          justify-content: space-evenly;

          & > * {
            flex: 1;
          }

          .abx-stop-complete-text {
            flex-basis: 100%;
            text-align: center;
          }
        }
      }

      .abx-round-stop-item-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 16px);
        height: 100%;
        background: white;

        .abx-round-stop-item-preloader__question {
          width: 100%;
          margin: 8px 0 16px;
          padding: 0 24px;
        }
        .abx-round-stop-item-preloader-answers {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}
