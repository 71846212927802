.abx-checklist-template-override-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  min-height: 480px;

  &__left {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  &__right {
    display: flex;
    flex-direction: column;
  }
}
