.abx-multi-pin-side-bar__content {
  overflow-x: hidden;
  padding: 16px;
}

.abx-multi-pin-side-bar__details {
  display: flex;
  flex-direction: column;
}

.abx-multi-pin-side-bar__title {
  font-weight: 700;
  font-size: 14px;
  padding: 8px;
  text-transform: uppercase;
}

.abx-multi-pin-side-bar__sub-title {
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  text-transform: uppercase;
}

.abx-multi-pin-side-bar__pin-detail {
  font-weight: normal;
  font-size: 12px;
  padding: 8px 0px;
}

.abx-multi-pin-side-bar__separator {
  background-color: $color-medium-gray;
  border-color: $color-medium-gray;
  color: $color-medium-gray;
  margin: 0;
  margin-bottom: 8px;
}

.abx-multi-pin-side-bar__actions {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: baseline;

  .abx-button {
    margin: 0;
    border: 0;
    padding: 0 8px;
  }
}

.abx-multi-pin-side-bar-item__icon-and-label {
  // not active, hovering over icon & label
  &:hover {
    background-color: $secondary-1 !important;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 36px); // to allow room for the filter icon

  .abx-multi-pin-side-bar-item__icon-or-spinner {
    padding-left: 8px;
    margin-right: 8px;
    // to control icon display in IE
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    abx-pin-type {
      .abx-pin-type-icon-wrap {
        margin: 0;
      }
    }
  }

  .abx-multi-pin-side-bar-item__name {
    height: 100%;
    overflow: hidden;
    line-height: 1.2;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 0px;
    padding-right: 12px;
  }
}
