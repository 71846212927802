.abx-sidebar-header {
  position: relative;
  box-sizing: border-box;
  border-bottom: 3px solid $secondary;
  @extend .elevation-frame-2dp;

  .abx-name-row {
    min-height: 58px;
    cursor: default;
    padding: 12px 16px;
    border-bottom: 1px solid $color-border-gray;
    text-transform: uppercase;

    md-icon {
      color: $color-text;
    }

    // Left-aligned, user defined icon
    .abx-icon {
      margin-right: 16px;
    }

    // Title text for the header
    .abx-title {
      font-size: 16px;
      font-weight: 700;
    }

    .abx-pin__name {
      color: $color-text;
      font-weight: normal;

      &.showing-pin-type {
        text-transform: none;
      }
    }

    .abx-pin-type__name {
      font-size: 10px;
      line-height: 1.2;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $secondary;
      text-transform: uppercase;
    }

    .abx-sidebar-close {
      color: $secondary;
    }
  }
}
