abx-pin-typeahead .pin-typeahead {
  &__input-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-label {
    @extend %abx-input-label;
    &.abx-required::after {
      @extend %abx-required-asterisk;
    }
  }

  &__search-input {
    @extend %abx-input;
    height: auto;
    min-height: 32px;

    md-autocomplete-wrap {
      height: initial;
      box-shadow: none;
    }

    md-progress-linear {
      height: 2px;

      .md-container.md-mode-indeterminate {
        background-color: $secondary-1;
        height: 100%;
      }

      .md-bar {
        background-color: $secondary;
      }
    }

    input {
      @extend .abx-input__field;
      &:focus {
        @extend %abx-input__field--editing;
      }
    }

    &--loading {
      // Make room for the autocomplete's loading bar
      input {
        border-bottom: none !important;
        height: 30px;
      }
    }

    &[disabled] input {
      @extend %abx-input__field--disabled;
    }
  }
}

.pin-typeahead {
  &__dropdown {
    // md-autocomplete suggestions
    li {
      &:not(:last-child) {
        border-bottom: 1px solid $color-light-gray;
      }
    }
  }

  &__item-template {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
      font-weight: 600;
    }
  }

  &__text {
    &--pin-type {
      text-transform: uppercase;
      color: $color-medium-gray;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 6px;
    }

    &--name {
      line-height: 14px;
      font-size: 14px;
    }
  }
}
