.abx-details-content {
  &.ng-hide {
    display: none;
  }
  &.ng-hide-remove.ng-hide-remove-active {
    animation: fadeIn 0.5s;
  }

  .abx-building-detail {
    padding: 3px;
    margin-bottom: 28px;
    font-size: 16px;

    &__label {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__name {
      padding-left: 2px;
    }
  }

  .abx-asset-condition {
    margin-left: 16px;
    margin-right: 16px;
  }

  .wo-assignees-section {
    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  .work-order-location,
  .schedule-location {
    display: flex;
    flex-direction: row;

    .work-order-location-info,
    .schedule-location-info {
      display: flex;
      flex-direction: column;
      margin: auto auto 28px 16px;

      .abx-location-detail {
        padding: 3px;
        margin-bottom: 28px;
        font-size: 16px;

        &__label {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
    }

    .work-order-location-edit {
      .btn-primary {
        margin-right: 0;
      }
      .btn-link {
        white-space: nowrap;
      }
    }
  }
}
