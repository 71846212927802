abx-list {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: auto;
  max-height: 100%;

  .material-icons.small-icon,
  abx-generic-tooltip .material-icons {
    font-size: 15px;
  }

  .abx-list-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20px;
    min-height: 20px;
  }

  /* IE 10+ */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // Needed in IE since it won't respect max-height from above
    // Known problem with this: empty list message and button will be at bottom of page
    flex: 1;
  }

  md-toolbar.abx-list-header,
  md-toolbar.abx-batch-actions {
    @extend .elevation-frame-bot-2dp;
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    padding: 0;
    background-color: #f9f9f9;
  }

  abx-list-header,
  md-toolbar.abx-batch-actions {
    & > * {
      padding-right: 8px;
      font-size: 14px;

      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
  }

  abx-list-header {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    align-content: center;
    background-color: #f9f9f9;
    color: $color-dark-gray;

    & > div {
      font-weight: 700;
      text-transform: uppercase;
      color: $color-dark-gray;
    }
  }

  md-toolbar.abx-batch-actions {
    padding-top: 2px; // accounts for border bottom
    border-bottom: 2px solid $color-primary;
    background-color: $white;

    .abx-batch-action {
      padding: 0 8px;
      margin: 0 2px;

      &:first-of-type {
        margin-left: 8px;
      }
      &:last-of-type {
        margin-right: 8px;
      }

      span {
        margin-left: 8px;
        font-weight: bold;
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
      }
    }
  }

  md-content.abx-list {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: auto;
    padding-bottom: 80px;

    abx-list-item {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: stretch;
      min-height: 48px;
      padding: 12px 0;
      background-color: $white;
      overflow-wrap: break-word;

      &:not(:last-of-type) {
        border-bottom: solid 1px $gray-DD;
      }

      &:last-of-type {
        // add space for the fab
        margin-bottom: 64px;
      }

      &.abx-list-placeholder {
        border-bottom: none;
        &:hover {
          background-color: transparent !important;
        }
      }
      &.abx-2-line {
        min-height: 64px;
      }
      &.abx-3-line {
        min-height: 84px;
      }
      & > * {
        padding-right: 8px;

        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }

  &:not(.no-select) md-content.abx-list {
    abx-list-item {
      &:hover {
        background-color: $white-F9;
      }
      &.selected {
        background-color: $blue-20;
      }
    }
  }

  button.abx-add-doc-btn {
    // Mirrors abx-document-thumbnail sizes
    min-width: 53px;
    max-width: 53px;
    width: 53px;
    min-height: 53px;
    max-height: 53px;
    height: 53px;

    margin: 0px;
  }
}

abx-asset-list,
abx-document-list,
abx-floor-list,
abx-inspection-program-list,
abx-job-list,
abx-schedule-list,
abx-room-list,
abx-service-request-list,
abx-work-order-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  background-color: $white;
  overflow: auto;

  &.flat {
    md-toolbar.abx-list-header,
    md-toolbar.abx-batch-actions {
      box-shadow: none !important;
      border-bottom: 2px solid $black-10;
    }
  }

  md-content.abx-list {
    background-color: inherit;
  }

  /* IE Edge 12+ */
  @supports (-ms-ime-align: auto) {
    height: 1px !important;
  }
  .abx-subtitle {
    font-size: 12px;
  }
}

abx-list,
abx-asset-list,
abx-document-list,
abx-floor-list,
abx-job-list,
abx-schedule-list,
abx-room-list,
abx-service-request-list,
abx-work-order-list {
  &.flat {
    md-toolbar.abx-list-header,
    md-toolbar.abx-batch-actions {
      box-shadow: none !important;
      border-bottom: 2px solid $black-10;
    }
  }
}

*[dnd-list] .dndDraggingSource {
  display: none;
}

.dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 80px;
}
