.select-document-dialog {
  max-height: 90%;
  min-height: 90%;
  height: 90%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .select-dialog-list-container {
    height: 100%;
  }
}

abx-list-item:hover {
  cursor: pointer;
}
