.hover-highlight {
  margin: 0px;
  background-color: transparent;
  border: 5px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  &:hover,
  &.abx-active {
    border: 5px solid $secondary;
    border-radius: 4px;
    cursor: pointer;
  }
  md-progress-circular {
    margin: 0 auto;
    min-height: 20px;
    min-width: 20px;
  }
  md-card {
    margin: 0px;
    overflow: hidden;
  }

  .card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px;
    width: 100%;
    color: $secondary;
    font-weight: 600;
    text-transform: uppercase;

    .icon {
      color: $secondary;
      font-size: 56px;
    }
  }

  img {
    display: block;
  }

  .no-floor-plan {
    display: flex;
    flex-direction: column;
  }

  md-card-footer {
    @extend .elevation-frame-2dp;
    background-color: $white;
    padding: 5px;
    display: flex;
    justify-content: space-between;

    span {
      flex-grow: 3;
    }

    .icon {
      color: $secondary;
      margin: 0;
    }
  }
}
