md-dialog,
.abx-dialog {
  &.md-dialog-fullscreen {
    @extend .full-screen;
  }
  md-toolbar.md-dark-theme:not(.md-menu-toolbar) {
    color: $black;
    background-color: $white;
    button {
      color: $black;
      &.md-icon-button {
        color: $black;
        i,
        md-icon {
          color: $black;
        }
      }
    }
  }
  md-dialog-actions {
    min-height: 56px; // md-button height + margin
  }
}
