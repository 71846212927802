.my-export-list {
  display: flex;
  flex-direction: column;
  &__export-list {
    border-top: 1px solid $color-border-gray;
    .abx-list {
      background-color: transparent;
      abx-list-item {
        min-height: 65px;
        align-items: center;
      }
    }
  }
}
