img.error-img {
  @media screen and (max-width: 599px) {
    width: 200px;
    height: 200px;
  }
  @media screen and (min-width: 600px) {
    width: 480px;
    height: 480px;
  }
}

.error-503 {
  $window-padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background: #2d8dd9;
  padding: $window-padding;
  position: relative;
  overflow: hidden;

  &__text {
    height: 100%;
    position: relative;
    color: $color-white;

    &:before {
      color: #fff;
      font-size: 20px;
      position: relative;
      top: 450px;
      font-weight: bold;
      z-index: 10;
    }
  }

  &__img {
    top: 10%;
    position: absolute;
    min-width: 200px;
    width: 100%;
    max-width: 450px;
    padding: $window-padding;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media screen and (max-width: 400px) {
    &__text:before {
      top: 350px;
    }
  }
}

img.error-img-404 {
  @media screen and (max-width: 599px) {
    width: 250px;
    height: 250px;
  }
  @media screen and (min-width: 600px) {
    width: 480px;
    height: 480px;
  }
}

.error-page {
  background-color: #2d8dd9;
  color: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 599px) {
    h3 {
      font-size: 20px;
    }
  }
}
