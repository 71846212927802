abx-dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;

  width: auto;
  padding: 16px 0 64px;
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond-to(gt-sm) {
    padding: 16px 16px 128px;
  }

  .dashboard-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
    font-size: 18px;

    i {
      margin-bottom: 8px;
    }
  }

  // This should be extended to every card in dashboard
  .dashboard-card {
    @extend .card-2dp;
    @extend .bottom-border;
    @extend .m-16-bot;

    display: block;
    padding: 0;
  }

  .list-titles {
    margin: -16px -16px 16px;
    border-bottom: 1px solid $color-primary;

    @include respond-to(gt-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      margin: 0;
      padding: 0 48px;
      border-bottom: none;
    }

    @include respond-to(gt-md) {
      padding: 0 8vw;
    }

    @include respond-to(gt-lg) {
      padding: 0 16vw;
    }

    .list-title {
      @extend .text-bold;
      @extend .text-uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      padding: 8px;
      text-align: center;
      color: $color-medium-gray;

      &.active-section {
        color: $color-black;
      }

      .list-title-count {
        display: flex;
        justify-content: center;
        font-size: 24px;
        font-weight: normal;

        .preloader-block {
          height: 34px;
          min-width: 34px;
        }
      }
    }

    .list-title-arrow {
      width: 0;
      height: 0;
      padding-bottom: 15px;
      margin-bottom: -25px;
      border-left: 10px solid rgba(0, 0, 0, 0);
      border-right: 10px solid rgba(0, 0, 0, 0);

      border-bottom: 12px solid rgb(33, 38, 58);
    }
  }

  .auto-refresh {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 8px 0px auto;
    padding-right: 16px;
    min-height: 20px;
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
    color: $color-dark-gray;

    .auto-refresh__timer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .loading-dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 18px;
    }
    .loading-dots .loading-dots__dot {
      height: 6px;
      width: 6px;
      margin: 4px;
      background-color: $blue;
      border-radius: 50%;
      animation: loading-dot 1s linear infinite;
    }
    .loading-dots .loading-dots__dot:nth-child(1) {
      animation-delay: 0.2s;
    }
    .loading-dots .loading-dots__dot:nth-child(2) {
      animation-delay: 0.4s;
    }
    .loading-dots .loading-dots__dot:nth-child(3) {
      animation-delay: 0.6s;
    }

    @keyframes loading-dot {
      0% {
        opacity: 1;
        margin-top: 0;
        margin-bottom: 0;
      }
      20% {
        opacity: 0;
        margin-bottom: 50%;
        margin-top: 0;
      }
      30% {
        opacity: 0;
        margin-bottom: 0;
        margin-top: 50%;
      }
      50% {
        opacity: 1;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .refresh-button {
    color: $blue;
    margin-left: 4px;
    cursor: pointer;
  }

  .building-info-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    md-input-container.building-selector {
      max-height: 30px;
      margin: 0 0 0 8px;
      padding: 0;
      font-weight: 500;
      text-transform: none;
      z-index: 2;

      md-select {
        max-height: 30px;
        md-select-value.md-select-value {
          min-width: unset;
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 1px !important;
          border-bottom: 1px solid transparent !important;
          font-size: 14px;

          .md-select-icon {
            width: auto;
            margin: 4px 8px 0;
          }
        }
      }
    }
  }
}
