.markup-layer-list-item {
  cursor: default;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  padding: 10px 0px;
  border-bottom: 1px solid $gray-E5;

  &__visibility {
    text-align: center;
    margin: auto;
    flex: 0 0 15%;

    md-icon.visible_icon {
      margin: unset;
      color: $blue;
    }
    md-icon.invisible_icon {
      margin: unset;
      color: $color-dark-gray;
    }
  }

  &__admin {
    display: flex;
    align-items: center;
    justify-content: center;
    md-icon.visible_icon {
      margin: -10px -12px -10px 0px !important;
      font-size: 48px;
    }
  }

  &__content {
    flex: 1 1 auto;
    flex-wrap: nowrap;
    max-width: 65%;

    .layer-name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .layer-date {
      font-size: 10px;
      color: $color-dark-gray;

      &__title {
        font-weight: bold;
        margin-right: 0.2em;
      }
    }
  }

  &__actions {
    flex: 0 0 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px 5px;

    md-icon.layer-edit {
      cursor: pointer;
      color: $blue;

      &:hover {
        color: lighten($blue, 20%);
      }
    }

    md-icon.layer-remove {
      cursor: pointer;
      color: $color-error;

      &:hover {
        color: lighten($color-error, 35%);
      }
    }
  }

  &:hover {
    color: inherit;
    background-color: $secondary-1;

    md-icon.visible_icon {
      margin: unset;
      color: $blue;
    }
    md-icon.invisible_icon {
      margin: unset;
      color: $color-dark-gray;
    }

    md-icon.layer-edit {
      color: $blue;

      &:hover {
        color: lighten($blue, 20%);
      }
    }

    .layer-date {
      color: $color-dark-gray;
    }
  }

  &--selected {
    background-color: $blue;
    color: white;

    md-icon.visible_icon {
      margin: unset;
      color: white;
    }
    md-icon.invisible_icon {
      margin: unset;
      color: white;
    }

    md-icon.layer-edit {
      color: $white;
    }

    .layer-date {
      color: white;
    }
  }
}
