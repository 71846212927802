abx-floor-plan {
  display: block;
  flex: 1 1 auto;
  position: relative;

  &.fitted-print {
    position: absolute;
    top: 0;
    left: 0;
    flex: unset;
    overflow: hidden !important;
  }
}

#floor-plan {
  width: 100%;
  height: 100%;

  @media print {
    background: transparent;

    .leaflet-control-container {
      display: none;
    }
  }

  // Allow mouse-events to fall through
  canvas {
    &.inactive {
      pointer-events: none;
    }

    &.hide-cursor {
      cursor: none;
    }
  }

  // Custom pin type icon styling for the floor plan
  .abx-pin-div-icon {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 95%;
      width: 95%;
    }

    .fail {
      position: absolute;
      color: white;
      background-color: $color-error;
      text-align: center;
      display: none;
      left: -4px;
      top: -4px;
      width: 16px;
      height: 16px;
      font-size: 12px;
      border-radius: 50%;
      line-height: 13px;
      border: 1.5px solid white;

      &.show {
        display: block;
      }
    }

    .pass {
      position: absolute;
      color: white;
      background-color: $color-success;
      text-align: center;
      display: none;
      left: 12px;
      top: -4px;
      width: 16px;
      height: 16px;
      font-size: 12px;
      border-radius: 50%;
      line-height: 13px;
      border: 1.5px solid white;

      &.show {
        display: block;
      }
    }

    .skip {
      display: none;
      height: 160%;
      background-color: $color-dark-gray;
      border: 1px solid white;
      width: 4px;
      transform: rotate(45deg);
      position: absolute;
      left: 9px;
      top: -7px;

      &.show {
        display: block;
      }
    }

    &--dimmed {
      filter: brightness(54%);
    }

    &--selected {
      @extend .elevation-frame-6dp;
    }
  }

  // Custom styling for tooltips
  .abx-tooltip {
    border: 1px solid $white;
    color: $white;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 20px;
    font-weight: 600;

    &.leaflet-tooltip-right {
      // Gradient to match the solid color of the arrow SVG.
      // This is to prevent an ugly line from overlapping transperencies
      background: linear-gradient(
        to right,
        rgb(90, 90, 90) 10px,
        rgba(0, 0, 0, 0.65) 25px
      );
      margin-left: 18px;
      border-left-color: transparent;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      &::before {
        border: none;
        background-image: url("../../../../../../img/floorplan-tooltip-arrow-left.svg");
        background-repeat: no-repeat;
        background-position: 0px;
        height: 20px;
        width: 19px;
        margin-left: -10px;
        margin-top: -10px;
      }
    }

    &.leaflet-tooltip-left {
      // Gradient to match the solid color of the arrow SVG.
      // This is to prevent an ugly line from overlapping transperencies
      background: linear-gradient(
        to left,
        rgb(90, 90, 90) 10px,
        rgba(0, 0, 0, 0.65) 25px
      );
      margin-left: -18px;
      border-right-color: transparent;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      &::before {
        border: none;
        background-image: url("../../../../../../img/floorplan-tooltip-arrow-left.svg");
        transform: scaleX(-1);
        background-repeat: no-repeat;
        background-position: 0px;
        height: 20px;
        width: 19px;
        margin-right: -10px;
        margin-top: -10px;
      }
    }
  }
}

#floor-plan {
  z-index: 1;
}

#printed-floor-plan {
  display: block;
  position: absolute;
  top: 64px;
  left: 0;
  overflow: hidden;
  background: transparent;
  z-index: 0;

  .leaflet-pane {
    transform: none !important;
  }

  .leaflet-control-container {
    display: none;
  }
}

.abx-floor-plan-message {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: white;
  padding-top: 72px;
  height: 100%;

  header {
    font-style: italic;
    margin-bottom: 24px;
  }

  img,
  button {
    margin: 24px 0;
  }

  .abx-sub-text {
    margin: 24px 0 0;
  }
}

.floor-plan-warning-banner-wrap {
  position: absolute;
  top: 0px;
  width: 100%;

  display: flex;
  justify-content: center;

  &.dismissed {
    transition: 0.7s ease-in;
    top: -200px;
  }

  .floor-plan-warning-banner {
    @extend .elevation-frame-4dp;

    flex: 0 auto;

    font-size: 14px;
    text-align: center;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 48px;
    padding-left: 48px;

    background-color: $color-light-gray;
    border: 1px solid $color-medium-gray;

    width: 760px;

    position: relative;

    a {
      font-weight: 600;
    }

    .dismiss-button {
      color: black;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
