abx-step-list {
  display: block;
  background-color: $white;

  md-toolbar {
    md-input-container {
      margin: 0;
    }
  }

  md-content {
    flex: 1;
    background-color: $white;
  }

  &.slide-left {
    .step-container.ng-enter {
      animation: slideInLeft 0.3s;
    }
  }
  &.slide-right {
    .step-container.ng-enter {
      animation: slideInRight 0.3s;
    }
  }
  &.toggle {
    .step-container.ng-enter {
      animation: fadeIn 0.3s;
    }
  }
}
md-menu-item > .dropdown-align {
  text-align: right;
}

md-menu-item > .dropdown-size {
  font-size: 18px;
}
