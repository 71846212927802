.qr-manual-entry-dialog {
  & &__icon {
    font-size: 42px;
    color: $color-error;
  }
  .abx-dialog__inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    abx-input {
      width: 112px;
      min-width: 112px;
      max-width: 112px;
      font-family: monospace;
    }
  }
  & &__error {
    font-size: 14px;
    height: 19px;
    line-height: 19px;
    color: $color-error;
    font-weight: 600;
  }
  & &__search-text {
    height: 20px;
  }
  .md-dialog-content {
    text-align: center;
    p {
      font-size: 18px;
      margin-top: 10px;
    }
    label {
      margin-top: 30px;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      display: block;
    }
    label > abx-input {
      font-weight: normal;
    }
    .md-button {
      text-align: center;
      height: 40px;

      md-progress-circular {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
