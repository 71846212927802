abx-static-floor-plan {
  display: inline-block;
  position: relative;

  md-progress-circular {
    position: absolute;
    left: 50%;
    bottom: 50%;
  }

  .abx-static-floor-plan,
  .abx-static-floor-plan__img-container,
  img.abx-static-floor-plan__img {
    height: 100%;
  }

  .abx-static-floor-plan__pin {
    position: absolute;
    border-radius: 50%;
    i.pin-icon {
      width: 100%;
      height: 100%;
      background-size: 90%;
    }
  }
}
