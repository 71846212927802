abx-toggle-icon {
  display: block;
  cursor: pointer;

  md-icon {
    transition: transform 0.4s;
    transform: rotateX(0deg);

    &.expanded {
      transform: rotateX(180deg);
    }
  }
}
