.abx-calendar-panel {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 340px;
}

abx-datepicker {
  display: flex;
  flex-direction: row;
  height: 30px;

  input.md-input {
    height: 30px;
    flex: 1 1 auto;
    order: 1;
  }
  .abx-calendar-button {
    top: -4px;
    order: 2;
  }

  &[disabled] {
    .abx-calendar-button {
      display: none;
    }
  }
}
