abx-dashboard-stats {
  display: block;

  .work-summary-date-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;

    abx-date-input {
      input {
        width: 92px;
      }
    }

    .work-summary-date-filter__date-range-select,
    .work-summary-date-filter__date-range-input {
      margin: 0 16px;
    }

    .abx-range-input__wrapper {
      .abx-input__error-container {
        display: none;
      }
    }
    .work-summary-date-filter__date-range-select {
      margin-bottom: 8px;

      md-select {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @include respond-to(gt-sm) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      min-width: 20%;

      .work-summary-date-filter__date-range-select {
        min-width: 160px;
        margin-bottom: 0;
      }
      .work-summary-date-filter__date-range-input {
        margin: 0;
      }
    }
  }

  .work-summary-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;

    @include respond-to(gt-xs) {
      flex-direction: row;
    }

    .work-summary-block {
      @extend .pad-4;

      padding-bottom: 18px;
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      align-content: center;

      .work-summary-header {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
      }

      .work-summary-stats {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 8px;

        .preloader-block {
          height: 34px;
          min-width: 48px;
        }

        .stat-divider {
          margin: 0 16px;
          border-right: 1px solid $color-border-gray;
        }

        .work-summary-stat {
          display: flex;
          flex-direction: row;

          .work-summary-label {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            text-transform: uppercase;

            .work-summary-label-text {
              font-size: 10px;
              margin-top: -8px;
            }
          }

          .work-summary-value {
            font-size: 28px;
            padding-left: 8px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }

      &:first-child {
        .work-summary-stat {
          .work-summary-label {
            md-icon.material-icons {
              color: $secondary;
              fill: secondary;
            }
          }
        }
      }
    }
  }

  .dashboard-stats-date-range {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;

    .dashboard-stats-date-range__label {
      display: none;
      margin-right: 16px;
      padding-bottom: 16px;
      font-size: 12px;
      font-weight: 700;
    }

    abx-range-input {
      .abx-range-input__wrapper {
        flex-direction: column;
      }
      .abx-range-input__separator {
        display: none;
      }

      @include respond-to(gt-xs) {
        .abx-range-input__wrapper {
          flex-direction: row;
        }
        .abx-range-input__separator {
          display: inline-block;
        }
      }
    }

    @include respond-to(gt-sm) {
      justify-content: flex-end;

      .dashboard-stats-date-range__label {
        display: inline-block;
      }
    }
  }

  .dashboard-stats-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @include respond-to(gt-xs) {
      flex-direction: row;
    }

    .preloader-block {
      height: 34px;
      min-width: 34px;
    }

    .text-disabled {
      color: $gray-CC;
    }

    .dashboard-stat-block {
      @extend .pad-4;

      padding-bottom: 18px;
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      align-content: center;

      @include respond-to(gt-xs) {
        flex: 1 1 50%;
        max-width: 50%;
      }
      @include respond-to(gt-sm) {
        flex: 1 1 25%;
        max-width: 25%;
      }

      @include respond-to(gt-md) {
        flex: 1 1 12.5%;
        max-width: 12.5%;
      }

      .stat-label {
        @extend .text-center;
        flex: 1 1 auto;

        @include respond-to(gt-md) {
          font-size: 0.85vw;
        }

        @include respond-to(gt-lg) {
          font-size: 18px;
        }
      }

      .stat-value-block {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .stat-value {
          @extend .text-bold;
          @extend .text-center;
          font-size: 25px;
        }
      }
    }
  }
}
