.abx-dialog__inputs {
  .abx-name-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    abx-input {
      flex: 1;

      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
