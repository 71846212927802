.bulk-assign-dialog {
  md-dialog-content {
    background-color: white;
    color: $black;
  }

  &__header {
    margin-bottom: 1em;
  }

  &__add-section,
  &__remove-section {
    border: 1px solid $gray-CC;
  }

  &__add-section-header,
  &__remove-section-header {
    background-color: $gray-EE;
    padding: 0.5em;

    i {
      margin-right: 0.3em;
      margin-bottom: 2px;
    }

    span {
      display: inline-block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.4em;

      &:first-child {
        display: flex;
        align-items: flex-end;
      }

      &:last-child {
        font-size: 0.9em;
        text-align: center;
        text-transform: none;
      }
    }
  }

  &__add-section-header i {
    color: $color-success;
  }
  &__remove-section-header i {
    color: $color-error;
  }

  &__list {
    max-height: 20em;
    height: 20em;
    padding: 1em;
    overflow-y: auto;
  }

  md-dialog-actions {
    background-color: white;
    color: $black;
  }
}
