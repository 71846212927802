.assign-wo-dialog {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1.5em;
  }

  &__icon {
    margin-right: 0.5em;
  }

  &__input {
    flex-grow: 1;
  }
}
