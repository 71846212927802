.associate-qr-code-dialog {
  color: $color-text;
  text-align: center;

  .qr-code {
    md-icon {
      font-size: 42px;
      color: $color-error;
      display: block;
    }

    p {
      margin: 1em;
      font-weight: 600;

      span {
        font-weight: normal;
      }
    }
  }

  .content {
    font-size: 18px;

    p {
      margin: 0;
    }
  }

  .actions {
    width: 100%;
    max-width: 600px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .action-item {
      flex: 0 1 150px;
      margin-top: 10px;

      button {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        color: $blue;

        .icon {
          font-size: 32px;
          margin-bottom: 5px;
          color: inherit;

          svg {
            fill: currentColor;
          }
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
