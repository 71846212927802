.asset-cost-line-selection-dialog {
  @media screen and (max-width: 1380px) {
    width: inherit !important;
    max-width: 95% !important;
  }
  width: inherit !important;
  max-width: 80% !important;
  max-height: 85%;

  &__action-delete-wrapper {
    // for left-alignment
    flex-grow: 1;
  }

  .md-warn {
    color: $red-A9;
    background-color: lighten($color: $red-A9, $amount: 43%);
  }

  &__loading {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding: 50px 16px;
  }
}
