// -------------------------------------
//   Fonts
// -------------------------------------

/** error **/
$text-error-font-size: 14px;
$text-error-color: $color-error;
$text-error-font-family: Open Sans;
$text-error-font-weight: 400;
$text-error-text-align: left;

@mixin text-error() {
  font-size: $text-error-font-size;
  color: $text-error-color;
  font-family: $text-error-font-family;
  font-weight: $text-error-font-weight;
  text-align: $text-error-text-align;
}
/** h1 **/
$text-h1-font-size: 20px;
$text-h1-color: $color-black;
$text-h1-font-family: Open Sans;
$text-h1-font-weight: 700;
$text-h1-text-align: left;

@mixin text-h1() {
  font-size: $text-h1-font-size;
  color: $text-h1-color;
  font-family: $text-h1-font-family;
  font-weight: $text-h1-font-weight;
  text-align: $text-h1-text-align;
}
/** h2 **/
$text-h2-font-size: 18px;
$text-h2-color: $color-black;
$text-h2-font-family: Open Sans;
$text-h2-font-weight: 600;
$text-h2-text-transform: uppercase;
$text-h2-text-align: left;

@mixin text-h2() {
  font-size: $text-h2-font-size;
  color: $text-h2-color;
  font-family: $text-h2-font-family;
  font-weight: $text-h2-font-weight;
  text-transform: $text-h2-text-transform;
  text-align: $text-h2-text-align;
}
/** h3 **/
$text-h3-font-size: 16px;
$text-h3-color: $color-black;
$text-h3-font-family: Open Sans;
$text-h3-font-weight: 700;
$text-h3-text-align: left;

@mixin text-h3() {
  font-size: $text-h3-font-size;
  color: $text-h3-color;
  font-family: $text-h3-font-family;
  font-weight: $text-h3-font-weight;
  text-align: $text-h3-text-align;
}
/** input-error **/
$text-input-error-font-size: 12px;
$text-input-error-color: $color-error;
$text-input-error-font-family: Open Sans;
$text-input-error-font-weight: 400;
$text-input-error-text-align: left;

@mixin text-input-error() {
  font-size: $text-input-error-font-size;
  color: $text-input-error-color;
  font-family: $text-input-error-font-family;
  font-weight: $text-input-error-font-weight;
  text-align: $text-input-error-text-align;
}
/** input-label **/
$text-input-label-font-size: 12px;
$text-input-label-color: $color-black;
$text-input-label-font-family: Open Sans;
$text-input-label-font-weight: 600;
$text-input-label-text-align: left;

@mixin text-input-label() {
  font-size: $text-input-label-font-size;
  color: $text-input-label-color;
  font-family: $text-input-label-font-family;
  font-weight: $text-input-label-font-weight;
  text-align: $text-input-label-text-align;
}
/** input-placeholder-text **/
$text-input-placeholder-text-font-size: 14px;
$text-input-placeholder-text-color: $color-dark-gray;
$text-input-placeholder-text-font-style: italic;
$text-input-placeholder-text-font-family: Open Sans;
$text-input-placeholder-text-font-weight: 400;
$text-input-placeholder-text-text-align: left;

@mixin text-input-placeholder-text() {
  font-size: $text-input-placeholder-text-font-size;
  color: $text-input-placeholder-text-color;
  font-style: $text-input-placeholder-text-font-style;
  font-family: $text-input-placeholder-text-font-family;
  font-weight: $text-input-placeholder-text-font-weight;
  text-align: $text-input-placeholder-text-text-align;
}
/** no-results-found **/
$text-no-results-found-font-size: 20px;
$text-no-results-found-color: $color-dark-gray;
$text-no-results-found-font-style: italic;
$text-no-results-found-font-family: Open Sans;
$text-no-results-found-font-weight: 400;
$text-no-results-found-text-align: left;

@mixin text-no-results-found() {
  font-size: $text-no-results-found-font-size;
  color: $text-no-results-found-color;
  font-style: $text-no-results-found-font-style;
  font-family: $text-no-results-found-font-family;
  font-weight: $text-no-results-found-font-weight;
  text-align: $text-no-results-found-text-align;
}
/** number **/
$text-number-font-size: 28px;
$text-number-color: $color-black;
$text-number-text-align: center;
$text-number-font-family: Open Sans;
$text-number-font-weight: 700;

@mixin text-number() {
  font-size: $text-number-font-size;
  color: $text-number-color;
  text-align: $text-number-text-align;
  font-family: $text-number-font-family;
  font-weight: $text-number-font-weight;
}
/** p **/
$text-p-font-size: 14px;
$text-p-color: $color-black;
$text-p-font-family: Open Sans;
$text-p-font-weight: 400;
$text-p-text-align: left;

@mixin text-p() {
  font-size: $text-p-font-size;
  color: $text-p-color;
  font-family: $text-p-font-family;
  font-weight: $text-p-font-weight;
  text-align: $text-p-text-align;
}
/** a **/
$text-a-font-size: 14px;
$text-a-color: $color-link;
$text-a-font-family: Open Sans;
$text-a-font-weight: 600;
$text-a-text-align: left;

@mixin text-a() {
  font-size: $text-a-font-size;
  color: $text-a-color;
  font-family: $text-a-font-family;
  font-weight: $text-a-font-weight;
  text-align: $text-a-text-align;
}
/** sm **/
$text-sm-font-size: 12px;
$text-sm-color: $color-black;
$text-sm-font-family: Open Sans;
$text-sm-font-weight: 400;
$text-sm-text-align: left;

@mixin text-sm() {
  font-size: $text-sm-font-size;
  color: $text-sm-color;
  font-family: $text-sm-font-family;
  font-weight: $text-sm-font-weight;
  text-align: $text-sm-text-align;
}
/** xs **/
$text-xs-font-size: 10px;
$text-xs-color: $color-black;
$text-xs-font-family: Open Sans;
$text-xs-font-weight: 400;
$text-xs-text-align: left;

@mixin text-xs() {
  font-size: $text-xs-font-size;
  color: $text-xs-color;
  font-family: $text-xs-font-family;
  font-weight: $text-xs-font-weight;
  text-align: $text-xs-text-align;
}
