abx-pin-search {
  display: flex;
  align-items: center;
}

.abx-search {
  position: relative;
  $borderRadius: 2px;
  height: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $white-10;
  border-radius: $borderRadius;

  &.squareBottomBorder {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .abx-search__spyglass {
      border-bottom-right-radius: 0;
    }
  }

  &.focused,
  &.search-is-active {
    background-color: $white;

    input {
      color: $color-text;
      background-color: $white;
    }
    .abx-search__spyglass {
      background-color: $white;
      border-radius: $borderRadius;
      color: $secondary;
    }
  }

  input {
    flex: 1;
    width: 100%;
    color: $white-54;
    height: 100%;
    font-size: 16px;
    padding: 4px 8px;
    border: none;
    z-index: 6;
    outline: none;
    border-radius: $borderRadius;
    background-color: transparent;

    &::placeholder {
      color: $white-54;
    }

    &:focus {
      color: $color-text;

      &::placeholder {
        color: $color-dark-gray;
      }
    }
  }

  .abx-search__spyglass {
    color: $white;
    cursor: pointer;
    z-index: 6;
    height: 100%;
    margin: 0;
    display: flex;
    flex-basis: 36px; //give 12px right 'padding' to 24px element
    align-items: center;
  }

  .abx-search__progress {
    position: absolute;
    bottom: 0;
    height: 3px;
    z-index: 6;
    .md-container {
      height: 3px;
      background-color: $color-medium-gray;
    }
  }

  .abx-search-list {
    padding: 0; //override md-list 8px padding, would have height on empty list
    background-color: $white;
    flex-basis: 100%;
    max-height: 240px;
    overflow: auto;
    color: $color-text;

    abx-pin-type {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }

    .abx-pin-type-count {
      color: $color-medium-gray;
      font-weight: 600;
      font-size: 14px;
      justify-content: flex-end;
    }

    .abx-search-list-item {
      @extend .abx-list-item;
      font-size: 14px;

      .md-list-item-inner span {
        line-height: 1.2;
      }

      .unplaced-tag {
        color: $color-medium-gray;
        font-size: 10px;
        font-weight: 600;
        margin-left: 6px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      &:hover {
        .unplaced-tag {
          color: $color-black;
        }
      }
    }
  }

  &.disabled {
    background-color: $blue-2E;

    &:hover {
      cursor: not-allowed;
    }
    input {
      background-color: $blue-2E;
      color: $white-38;
      cursor: not-allowed;
    }

    .abx-search__spyglass {
      background-color: $blue-2E;
      color: $white-38;
      cursor: not-allowed;
    }
  }
}
