$_component: ".upgrade-browser";
#{$_component} {
  &__browser-image {
    width: 128px;
    height: 128px;
    margin: 16px;
    display: inline-block;
  }
  &__title {
    font-size: 40px;
    color: $color-text;
    text-align: center;
    text-transform: uppercase;
  }
  &__section-heading {
    font-size: 22px;
    color: $color-dark-gray;
    text-align: center;
  }
  &__text {
    margin-bottom: 54px;
    text-align: center;
    line-height: 1.4;
    max-width: 800px;
  }
  &__browser-list {
    display: table;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    &__item {
      display: inline-block;
      vertical-align: top;
      flex-direction: column;
      align-items: center;
      flex: 0;
      padding: 8px;
      text-align: center;
    }
    #{$_component}__caption-container {
      text-align: center;
      padding: 8px;
      flex-direction: column;
      align-items: center;
      display: block;
      &__name {
        font-size: 16px;
        letter-spacing: 0.01em;
        white-space: nowrap;
        display: block;
      }
      &__sub {
        font-size: 12px;
        display: block;
        a {
          font-weight: normal;
        }
        color: $color-medium-gray;
      }
    }
  }
}
