@use "sass:math";
abx-cost-line-selection-list {
  display: block;
  flex-grow: 1;
}

.cost-line-selection-list {
  background-color: $color-light-gray;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 1px;
  padding-bottom: 4px;
  min-height: 420px;

  @for $i from 1 through 10 {
    &__item-preloader--#{$i} {
      @extend .preloader;
      width: 100%;
      margin-bottom: 4px;
      height: 40px;

      background-color: rgba(255, 255, 255, math.max(0, 1 - 0.2 * $i));
    }
  }
}
