.abx-qr-confirm-assign {
  // have to overwrite the normal min/max width for dialogs because they are too wide
  min-width: 500px;
  max-width: 500px;
}
.abx-qr-confirm-assign__toolbar {
  display: flex;
  justify-content: space-between;
}
.abx-qr-confirm-assign__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .abx-qr-confirm-assign__content-line1 {
    font-size: 18px;
  }
  .abx-qr-confirm-assign__content-line2 {
    max-width: 100%;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
      &.abx-pin-name {
        max-width: 150px;
      }
    }
    abx-pin-type {
      display: inline-block;
      margin-left: 5px;
      .abx-pin-type-icon-wrap {
        margin: 0;
      }
    }
  }
  .abx-qr-confirm-assign__content-line3 {
    display: flex;
    flex-direction: column;
    color: $color-error;
    align-items: center;
  }
}

.abx-qr-confirm-assign__actions {
  display: flex;
  justify-content: flex-end;
}
