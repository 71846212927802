.asset-filter-bar-container {
  display: flex;
  align-items: baseline;

  & > abx-managed-filter-bar {
    flex: 1;
  }

  & > .sidebar-switch {
    flex: 0;
    min-width: 100px;
    margin-right: 16px;
  }
}

.asset-list-wrapper {
  flex: 1;
  display: flex;
  overflow: auto;

  & > abx-pin-detail-sidebar {
    // makes sidebar non-absolute positioned
    display: flex;
    overflow: initial !important;

    & > .asset-list-sidenav {
      position: relative;
    }
  }
}
