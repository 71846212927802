abx-reporting-kpi {
  display: block;
  flex-shrink: 0;
  padding: 16px;

  #reporting-stats-container {
    display: flex;

    .preloader-block {
      height: 34px;
      min-width: 34px;
    }

    .text-disabled {
      color: $gray-CC;
    }

    .work-order-kpi-container,
    .service-request-kpi-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      @include respond-to(gt-xs) {
        flex-direction: row;
      }
    }

    .reporting-stat-block {
      @extend .pad-4;

      padding-bottom: 18px;
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      align-content: center;

      @include respond-to(gt-xs) {
        flex: 1 1 50%;
        max-width: 50%;
      }

      &.service-request-stat-block {
        @include respond-to(gt-sm) {
          flex: 1 1 33.33%;
          max-width: 33.33%;
        }
        @include respond-to(gt-md) {
          flex: 1 1 16.66%;
          max-width: 16.66%;
        }
      }

      &.work-order-stat-block {
        @include respond-to(gt-sm) {
          flex: 1 1 25%;
          max-width: 25%;
        }

        @include respond-to(gt-md) {
          flex: 1 1 12.5%;
          max-width: 12.5%;
        }
      }

      .stat-label {
        @extend .text-center;
        flex: 1 1 auto;

        @include respond-to(gt-md) {
          font-size: 0.85vw;
        }

        @include respond-to(gt-lg) {
          font-size: 18px;
        }

        abx-help-tooltip {
          padding-top: 4px;
        }
      }

      .stat-value-block {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .stat-value {
          @extend .text-bold;
          @extend .text-center;
          font-size: 25px;
        }
      }
    }
  }
}
