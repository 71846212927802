abx-inspection-program-instruction-item {
  .abx-inspection-program-instruction-item {
    padding: 8px 0;

    &__header {
      font-size: 12px;
    }
    &__title {
      font-weight: bold;
      font-size: 18px;
    }
    &__description {
      font-style: italic;
      font-size: 14px;
      color: $color-dark-gray;
      padding: 4px 0;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 8px;
    }
  }
}
