.delete-dialog {
  color: $color-text;

  &__list-item {
    font-weight: bold;
    align-items: center;
    color: $color-link;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 36px;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      color: $color-link-hover;
    }
  }

  &__disabled {
    font-weight: normal;
    font-style: italic;
    color: $color-dark-gray;
    cursor: default !important;

    &:hover {
      color: $color-dark-gray;
    }
  }

  &__green {
    color: $color-success;
  }

  .abx-dialog__help-text {
    margin-bottom: 24px;
  }
}
