abx-inspection-program-details-section {
  display: block;
}
.abx-inspection-program-details-section {
  margin: 12px;

  .detail-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__back-button {
      padding: 12px;
      md-icon {
        color: $color-link;
      }
    }
  }

  &__card {
    padding: 0 0 16px 0;

    h4 {
      padding: 16px 16px 0;
    }
    hr {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .empty-list {
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      color: $color-medium-gray;
    }
  }

  &__table-header {
    flex-direction: row;
    padding: 0 16px;
    min-height: 50px;

    .header-column {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 25%;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;

    > div:nth-of-type(2n) {
      background-color: $color-light-gray;
    }
  }

  &__table-row {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    min-height: 50px;
    font-size: 14px;

    .row-column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 25%;

      span:empty:before {
        content: "\200b"; // unicode zero width space character
      }

      .stop-indicator {
        margin-left: 4px;
        color: $color-dark-gray;
      }

      .building-container {
        display: flex;
        align-items: center;

        &__icon {
          margin-right: 4px;
          color: $color-dark-gray;
        }
      }

      .row-pin-name {
        font-weight: 600;
      }

      .cell-subtext {
        font-size: 12px;
      }

      &__status {
        display: flex;
        flex-direction: row;
        align-items: center;

        .row-status {
          margin-right: 8px;
          font-weight: 600;
        }

        .status-passed {
          color: $color-pass;
        }

        .status-incomplete {
          color: $color-medium-gray;
        }

        .status-failed {
          color: $color-fail;
        }
      }

      .work-orders-and-link-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__wos {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
