abx-life-cycle {
  display: block;

  .abx-life-cycle {
    .chart-legend {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      margin-top: 8px;
      padding: 0px 6px 4px;
      border: 1px solid $color-border-gray;

      .legend-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &__label {
          font-size: 10px;
          text-align: center;
        }
      }

      .legend-circle {
        border-radius: 50%;

        &--open {
          width: 9px;
          height: 9px;
          margin: 7px;
          border: 1px solid $color-link;
        }
        &--closed {
          width: 6px;
          height: 6px;
          margin: 9px 8px;
          background: $color-link;
        }
      }

      .legend-line {
        width: 30px;
        height: 2px;
        margin: 11px;
        &--solid {
          border-bottom: 2px solid $color-link;
        }
        &--dashed {
          border-bottom: 2px dashed $color-medium-gray;
        }
      }
    }
  }
}
