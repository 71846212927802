abx-sr-quick-actions {
  display: block;

  .sr-quick-actions {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    background-color: #eeeeee;
    padding: 8px 24px;

    button.md-button {
      padding: 8px 12px;
      min-height: 0px;
    }

    .sr-room-asset-section {
      flex: 1 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      margin: 8px 0 16px;

      @include respond-to(gt-sm) {
        display: none;
      }

      & > span {
        flex: 0 auto;
        margin-right: 24px;
      }
    }

    .sr-buttons-section {
      display: flex;
      flex: 1 100%;
      flex-wrap: wrap;

      align-items: center;
      justify-content: center;

      @include respond-to(gt-sm) {
        flex: 2 auto;
        justify-content: flex-end;
      }

      button.md-button {
        flex: 0 auto;

        span {
          white-space: nowrap;
        }
      }
    }
  }
}
