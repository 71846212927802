.abx-checklist-summary {
  display: flex;
  flex-direction: column;

  abx-checklist-summary-filters {
    margin-left: auto;
  }
}

.ip-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;

  .ip-summary-chart-container {
    min-height: 305px;
    width: 70%;
    margin-right: 16px;

    .ip-summary-chart {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .empty-chart {
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
        color: $color-medium-gray;
      }

      .preloader-chart {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .preloader-donut-legend {
          max-height: unset;
          flex: 0;
          .legend {
            width: 100%;
          }
        }
      }

      abx-donut-chart {
        display: flex;

        .legend {
          flex: 1 1 auto;
          width: auto;
          max-width: 50%;
          max-height: unset;

          .legend-value {
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            width: 100%;
            height: auto;
            padding: 0;
          }

          .legend-value-container {
            display: flex;
            align-items: center;
            padding: 8px 0;

            .legend-children {
              display: flex;
              flex-direction: column;
            }
          }
          .inner-legend-value {
            display: flex;
            padding-left: 16px;
          }
        }

        @include respond-to(gt-sm) {
          flex-direction: row;

          .abx-donut-chart {
            flex: 1 1 auto;
          }
        }

        @include respond-to(gt-md) {
          .legend {
            max-width: 25%;
          }
          .abx-donut-chart {
            flex: none;
          }
        }
      }
    }
  }

  .selected-filters {
    display: flex;
    flex-direction: column;
    margin: 24px 16px;
    width: 30%;

    .selected-filters-display {
      margin: 8px;

      &__title {
        font-weight: 600;
      }

      &__value {
        padding-left: 4px;
      }
    }
  }
}
