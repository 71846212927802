.abx-work-order-card {
  .abx-work-order-collapsible-card {
    .abx-collapsible-card {
      border-bottom: none;
    }

    .header-icon {
      margin: 0 4px 0 0;
    }

    &__content {
      padding: 0;

      .content-row {
        padding: 4px 16px;

        .work-order-number {
          .overdue-icon {
            font-size: 16px;
            color: $color-error;
          }
        }

        .work-order-building {
          .building-icon {
            font-size: 16px;
            color: $color-dark-gray;
          }
        }

        .work-order-subtext {
          font-size: 12px;
          color: $color-dark-gray;
        }
      }

      .view-all-work-orders {
        display: flex;
        justify-content: center;
        padding: 8px;

        &--link {
          font-weight: bold;
        }
      }
    }
  }

  .dark-row {
    background-color: $color-light-gray;
  }
}
