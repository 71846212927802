abx-app-header {
  display: flex;
  flex-direction: column;
  z-index: 12;

  .abx-app-header {
    @extend .elevation-frame-8dp;
    height: 64px !important;
    border-bottom: 1px solid $color-link;

    .md-toolbar-tools {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 64px !important;
      max-height: 64px !important; // TODO: (BB) Make all toolbars this height
      z-index: 12;
    }

    @include respond-to(xs) {
      box-shadow: none;
    }
    @include respond-to(sm) {
      box-shadow: none;
    }
    @include respond-to(gt-sm) {
      border-bottom: none;
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: row;

      .md-icon-button {
        &:hover {
          background-color: $color-white-20 !important;
        }
      }
    }
    &__left {
      margin-left: -8px;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 3;
      min-width: 0;
      width: 100%;
      height: 56px;

      .abx-branding-logo {
        width: 120px;
        height: 100%;
        border-right: 1px solid rgba(238, 238, 238, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        img {
          height: 80%;
        }
        @include respond-to(gt-md) {
          width: 240px;
        }
      }
    }
    &__right {
      margin-right: -8px;
      justify-content: flex-end;
      flex-shrink: 0;

      button.md-button {
        margin-left: 0px;
        margin-right: 0px;
      }

      abx-pin-search {
        max-width: 700px;
        width: 40vw;
        flex-grow: 8;
        padding-left: 20px;
        padding-right: 20px;
      }

      &__icon-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    // Logo
    &__logo {
      min-width: 32px;
      margin: 8px;

      &__img {
        height: 32px;
        min-height: 32px;
        max-height: 32px;
      }
    }

    &__preloader {
      margin-left: 8px;

      &__org {
        @extend .preloader;
        @extend .preloader-block;
        @extend .preloader-pulse;
        width: 60px;
        height: 15px;
        margin-bottom: 4px;
      }
      &__building {
        @extend .preloader;
        @extend .preloader-block;
        @extend .preloader-pulse;
        width: 160px;
        height: 20px;
      }
    }

    &__info {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;
      margin: 0 8px;
      align-items: center;

      .abx-header-pipe {
        margin: 0 8px;
      }

      &__super {
        max-width: 100%;
        font-size: 15px;
        font-weight: 700;
      }

      &__main,
      &__details {
        display: flex;
        align-items: baseline;
      }
      &__main {
        justify-content: flex-start;
        flex-direction: column;
        width: calc(100% - 28px);
      }
      &__details {
        width: 100%;
      }

      &__primary {
        font-size: 20px;
        font-weight: 600;
        min-width: 36px;
      }

      &__secondary {
        font-size: 20px;
        min-width: 96px;

        &::before {
          content: "|";
          margin: 0 7px;
        }
      }

      .abx-drop-down-icon {
        margin-left: 4px;
      }

      @include respond-to(gt-sm) {
        margin-left: 0;
      }
    }

    &__add-button.md-button.md-icon-button {
      padding: 0px;
      md-icon {
        font-size: 32px;
      }
    }
  }

  /**
   * TODO: When removing old header bar make these the default styles by replacing
   * '.abx-app-header' styles with these 
   */
  .abx-updated-app-header {
    z-index: 12;
    height: 64px;
    min-height: 64px;

    .md-toolbar-tools {
      position: relative;
      z-index: 12;
      display: flex;
      flex-direction: row;
      align-content: stretch;
      justify-content: flex-start;
      align-items: center;

      padding: 0 6px 0 0;

      width: 100%;
      min-height: 64px;
      height: 64px;

      .abx-branding-logo {
        width: 240px;
        height: 100%;
        border-right: 1px solid rgba(238, 238, 238, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        img {
          height: 80%;
        }
      }
    }

    &__right,
    &__left {
      display: flex;
      align-items: center;

      .md-icon-button {
        &:hover {
          background-color: $color-white-20 !important;
        }
      }
    }
    &__left {
      font-size: 21px;
      padding: 0 8px 0 0px;
      height: 100%;
      // Allows section to shrink
      min-width: 0;
    }
    &__right {
      flex: 1 0;
      padding: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button.md-button {
        margin-left: 0px;
        margin-right: 0px;
      }

      abx-pin-search {
        max-width: 700px;
        width: 40vw;
        flex-grow: 8;
        padding-left: 20px;
        padding-right: 20px;
      }

      &__icon-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    // Logo
    &__logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 41px;
      margin-right: 0.67em;
      padding-left: 14px;
      /* Enforce max height */
      &__img {
        height: 41px;
      }
    }

    &__preloader,
    &__info {
      border-right: 1px solid rgba(238, 238, 238, 0.3);
    }
    &__preloader {
      margin-left: 8px;
      width: 191px;
      height: 100%;
      padding: 0 7px 0 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__org {
        @extend .preloader;
        @extend .preloader-block;
        @extend .preloader-pulse;
        width: 60px;
        height: 15px;
        margin-bottom: 4px;
      }
      &__building {
        @extend .preloader;
        @extend .preloader-block;
        @extend .preloader-pulse;
        width: 160px;
        height: 20px;
      }
    }
    &__info {
      border-right: 1px solid rgba(238, 238, 238, 0.3);

      display: inline-flex;
      align-items: center;
      height: 100%;
      padding: 0 7px 0 14px;
      min-width: 191px;

      &__building-icon {
        font-size: 17px;
        margin: 0 7px 0 0;
      }

      &__toggle-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;

        & md-icon.abx-drop-down-icon {
          color: $color-link;
        }
      }

      cursor: pointer;
      & * {
        pointer-events: none;
        user-select: none;
      }

      &:focus {
        outline: none;
      }
      &:focus,
      &:hover {
        background-color: rgba(0, 0, 0, 0.16);

        & md-icon.abx-drop-down-icon {
          color: lighten($color-link, 15%) !important;
        }
      }

      &__main {
        font-size: 12px;
      }
      /* Allow this info to stretch if secondary info is to be shown */
      &__main {
        min-width: 133px;

        @media screen and (min-width: 0) {
          max-width: 133px;
        }

        @media screen and (min-width: 768px) {
          max-width: 333px;
        }

        @media screen and (min-width: 1024px) {
          max-width: 633px;
        }
      }
      &-stretch {
        .abx-updated-app-header__info__main {
          min-width: 133px;

          @media screen and (min-width: 0) {
            max-width: 133px;
          }

          @media screen and (min-width: 768px) {
            max-width: 333px;
          }

          @media screen and (min-width: 1024px) {
            max-width: 633px;
          }
        }
      }
      &__super,
      &__primary,
      &__secondary {
        max-width: 100%;
      }

      &__super {
        display: block;
        height: 100%;
        margin-right: 7px;

        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.33px;
        line-height: 17px;
      }

      &__details {
        display: flex;
        align-items: center;
      }

      &__secondary {
        &::before {
          content: "|";
          margin: 0 3px;
        }
      }
    }
    /* TODO: When removing old header bar move these styles to "pin-search.component.scss" */
    & .abx-search input {
      font-size: 12px;
      padding: 12px 52px 12px 12px;
    }
    @media screen and (max-width: 711px) {
      // Assist making the app switcher into just an icon
      // See abx-app-switcher styles
      &__logo {
        margin-right: 0.33em;
      }
    }
    @media screen and (max-width: 542px) {
      // Prevent Overlap
      &__info-stretch {
        padding: 0 14px;
        & .abx-updated-app-header__info__building-icon,
        & .abx-updated-app-header__info__toggle-icon {
          display: none;
        }
      }
    }
    @media screen and (max-width: 960px) {
      &__info-stretch {
        margin-right: 60px;
      }
      /* TODO: When removing old header bar move these styles to "pin-search.component.scss" */
      // Make the search bar into an icon that expands when focused
      & abx-pin-search {
        position: absolute;
        width: auto !important;
        padding: 0 10px !important;
        /* unset doesn't work in ie11 */
        max-width: initial;

        height: 64px;

        /* Extend from logo to right-side actions */
        right: 144px;
        left: 63px;
        top: 0;

        /* make sure the user can click through to reach the buttons */
        pointer-events: none;
      }
      & .abx-search {
        position: absolute;
        /* Make sure it's at the correct Y coord on IE */
        top: 12px;
        right: 0;
        transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

        /* Make sure the user can still interact with the search bar */
        pointer-events: all;

        &__spyglass {
          flex-basis: 40px;
          width: 40px;
          justify-content: center;
        }
      }
      & .abx-search:not(.focused):not(.search-is-active):not(.disabled) {
        & .abx-search__spyglass:hover {
          color: lighten($color-link, 15%);
        }
      }
      & .abx-search:not(.focused):not(.search-is-active) {
        width: 40px;
        overflow: hidden;

        & .abx-search__input {
          width: 0px;
          padding: 0;
          opacity: 0;
          overflow: hidden;
        }
      }
      & .abx-search.search-is-active,
      & .abx-search.focused {
        z-index: 10;
        outline: 12px solid $color-secondary;

        /* Prevents issues with extending animation */
        & .abx-search__input {
          background-color: transparent;
        }

        & .abx-search__spyglass {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  ng-transclude {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .abx-no-switcher {
    .abx-app-header-info {
      cursor: text;
    }
  }

  @media print {
    border: none;

    * {
      color: #000 !important;
    }
    md-toolbar.md-dark-theme:not(.md-menu-toolbar) {
      background-color: $white;
      border-bottom: 2px solid $color-secondary;
    }
    .abx-drop-down-icon {
      display: none;
    }
  }
}

// -------------------------------------
//   Tooltips
// -------------------------------------

// Adjusts the location of the arrow so it actually points to the button
.abx-app-header__account-menu-button::after {
  @media screen and (min-width: 960px) {
    left: 70% !important;
  }
  left: 80% !important;
}

.abx-app-header__dashboard-button::after {
  @media screen and (min-width: 960px) {
    left: 32% !important;
  }
  left: 22% !important;
}

// -------------------------------------
//   Account Menu
// -------------------------------------
small.app-version {
  min-width: 192px;
  margin: 8px 0 -8px;
  padding: 4px 16px;
  font-size: 10px;
  color: $color-text;
  background: $white-F1;
  border-top: 1px solid $gray-DD;
}

md-menu-content.abx-account-menu-content {
  padding-top: 0;
  max-height: none;

  .current-user {
    padding: 8px;
    background: $white-F1;
    border-bottom: 1px solid $gray-DD;

    md-icon {
      color: $color-secondary;
    }

    span {
      font-style: italic;
      font-size: 12px;
      color: $color-dark-gray;
      line-height: 1;
      vertical-align: text-bottom;
    }
  }
}

// -------------------------------------
//   Add Menu
// -------------------------------------
$app-header-item-width: 112px;
$app-header-item-height: 118px;
$app-header-padding: 32px;

.app-header-add-menu {
  @extend .card-2dp;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: $app-header-item-width + $app-header-padding;
  height: $app-header-item-height + $app-header-padding;
  margin: 0;
  background-color: $white;

  .add-menu-item {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 8px;
    text-align: center;
    background: $white;
    border: none;

    md-icon {
      margin: unset;
      color: $color-dark-gray !important;
      fill: $color-dark-gray !important;
      svg path {
        color: $color-dark-gray !important;
        fill: $color-dark-gray !important;
      }
    }
    span {
      margin-top: 8px;
    }
    &:hover:not([disabled]) {
      background: $white-F1;
    }

    &.add-menu-item--floor,
    &.add-menu-item--room,
    &.add-menu-item--asset {
      display: none;
    }
    &[disabled],
    &[disabled="disabled"] {
      md-icon {
        color: $color-border-gray;
      }
      color: $color-medium-gray;
    }
  }

  &--1 {
    .add-menu-item {
      &--0 {
        display: flex;
      }
    }
  }
  &--2 {
    width: ($app-header-item-width * 2) + $app-header-padding;
    .add-menu-item {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
    }
    .add-menu-item {
      &--0,
      &--1 {
        display: flex;
      }
    }
  }
  &--3 {
    width: ($app-header-item-width * 3) + $app-header-padding;
    .add-menu-item {
      width: 33%;
      min-width: 33%;
      max-width: 33%;
    }
    .add-menu-item {
      &--0,
      &--1,
      &--2 {
        display: flex;
      }
    }
  }
  &--4,
  &--5,
  &--6 {
    width: ($app-header-item-width * 3) + $app-header-padding;
    height: ($app-header-item-height * 2) + $app-header-padding;
    .add-menu-item {
      width: 33%;
      min-width: 33%;
      max-width: 33%;
      height: 50%;
      min-height: 50%;
      max-height: 50%;
    }
    .add-menu-item {
      &--0,
      &--1,
      &--2 {
        display: flex;
      }
    }
  }
  &--4 {
    .add-menu-item {
      &--3 {
        display: flex;
      }
    }
  }
  &--5 {
    .add-menu-item {
      &--3,
      &--4 {
        display: flex;
      }
    }
  }
  &--6 {
    .add-menu-item {
      &--3,
      &--4,
      &--5 {
        display: flex;
      }
    }
  }
  &--7,
  &--8,
  &--9 {
    width: ($app-header-item-width * 3) + $app-header-padding;
    height: ($app-header-item-height * 3) + $app-header-padding;
    .add-menu-item {
      display: flex;
      width: 33%;
      min-width: 33%;
      max-width: 33%;
      height: 33%;
      min-height: 33%;
      max-height: 33%;
    }
  }
}
