abx-filter-bar-component {
  display: block;
}

.filter-bar-component {
  padding: 0 6px 6px 6px;
  position: relative;

  &__top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__filter-selection-section {
    flex: 0.3;
    select {
      width: 100%;
    }
  }

  &__filter-input-section {
    flex: 1;
    padding-left: 2px;
    &--loading {
      padding-left: 16px;
    }
  }

  &__filter-button-section {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;

    button.md-button {
      white-space: nowrap;
      min-width: 110px;
    }
  }

  &__bottom-row {
    display: flex;
    flex-direction: row;

    .abx-chip,
    .clear-all-btn {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 3px 3px 0;
      height: 25px;
    }

    .clear-all-btn {
      min-height: unset;
    }

    .abx-chip {
      display: block;
      background-color: mix($color-light-gray, white);
      padding: 3px;
      border-radius: 2px;
    }

    .abx-chips {
      flex-grow: 1;
    }

    .abx-result-count {
      padding: 3px 14px 3px 50px;
      flex-shrink: 0;
      font-size: 12px;
      line-height: 20px;
      color: $color-text;
    }
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $color-light-gray;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // TODO: remove this after properly adding code to hide this container
  .abx-input__error-container {
    display: none;
  }

  abx-range-input .abx-range-input__separator {
    flex: 0 1 5%;
  }
}
