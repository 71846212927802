abx-checklist-template-override-list {
  display: block;
  .abx-checklist-template-override-list {
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;

    &__header {
      font-size: 12px;
      color: $color-text;
      position: relative;

      md-icon {
        position: absolute;
        right: 0;
      }
    }

    &__counts {
      background-color: $color-secondary;
      font-size: 12px;
      color: $color-white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .abx-input__error-container {
      display: none;
    }

    ul {
      padding-left: 0;
      border: 1px solid $color-border-gray;
    }
    li {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $color-border-gray;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 4px;
      md-icon {
        margin-left: 4px;
        margin-right: 4px;
      }

      &[ng-click] {
        cursor: pointer;

        &:hover {
          background-color: $secondary-3;
        }
      }
    }

    &__loading {
      justify-content: center;
      height: 40px;
    }

    &__help-text {
      text-align: center;
      color: $color-dark-gray;
      font-style: italic;
    }

    &__child {
      margin-left: 32px;
    }

    md-icon,
    md-checkbox {
      margin: 0;
    }

    &__remove-button {
      margin-right: 8px;
    }

    // High specificity selector for overriding md styles
    #{repeatString(".abx-checklist-template-override-list__add-button", 10)} {
      border-radius: 50%;
      padding: 0;
      margin: 0;
      min-width: 0;
      min-height: 0;
      width: 32px;
      height: 32px;

      align-self: center;

      background-color: $color-secondary;

      md-icon {
        color: rgba(255, 255, 255, 0.87);
      }

      position: relative;
      top: -16px;
      margin-bottom: -16px;
    }

    #{repeatString(".abx-checklist-template-override-list__add-button--disabled", 10)} {
      background-color: $color-dark-gray;
    }
  }
}
