abx-pin-details {
  display: block;

  .attachment-list {
    padding-left: 8px;
  }

  .abx-pin-details__life-cycle {
    margin-bottom: 16px;
  }

  .abx-asset-life-cycle {
    .asset-life-cycle-header {
      header {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .abx-pin-details__room-asset-relationships {
    .room-asset-relationship-header {
      header {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .pin-field-group {
    margin: 0 -16px;

    &:last-child {
      border-bottom: 1px solid $color-border-gray;
    }
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding: 16px;
      background: $white-F9;
      border-top: 1px solid $color-border-gray;
      &--expanded {
        border-bottom: 1px solid $color-border-gray;
      }
    }
    &__fields {
      padding: 16px;
    }

    .pin-field-group-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }
  }
}
