// -------------------------------------
//   Animation
// -------------------------------------

// PULSE
// -------------------------------------
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-darken {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(93%);
  }
  100% {
    filter: brightness(100%);
  }
}

@-webkit-keyframes pulse-darken {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(93%);
  }
  100% {
    filter: brightness(100%);
  }
}

@keyframes greenteam {
  0% {
    opacity: 1;
    filter: none;
  }
  0.1% {
    opacity: 1;
  }
  0.2% {
    opacity: 0;
  }
  0.3% {
    opacity: 1;
  }
  0.4% {
    opacity: 0;
  }
  0.5% {
    opacity: 1;
  }
  0.6% {
    opacity: 1;
  }
  0.7% {
    opacity: 0;
  }
  0.8% {
    opacity: 1;
    filter: none;
  }
  1% {
    opacity: 1;
    filter: brightness(65%) hue-rotate(67.1deg) saturate(167.4%)
      brightness(126.7%);
  }
  3% {
    opacity: 1;
    filter: brightness(65%) hue-rotate(67.1deg) saturate(167.4%)
      brightness(126.7%);
  }
  3.1% {
    opacity: 0;
    filter: none;
  }
  3.2% {
    opacity: 1;
  }
  3.3% {
    opacity: 0;
  }
  3.4% {
    opacity: 1;
  }
  3.5% {
    opacity: 0;
  }
  3.6% {
    opacity: 1;
  }
}
@-webkit-keyframes greenteam {
  0% {
    opacity: 1;
    filter: none;
  }
  0.1% {
    opacity: 1;
  }
  0.2% {
    opacity: 0;
  }
  0.3% {
    opacity: 1;
  }
  0.4% {
    opacity: 0;
  }
  0.5% {
    opacity: 1;
  }
  0.6% {
    opacity: 1;
  }
  0.7% {
    opacity: 0;
  }
  0.8% {
    opacity: 1;
    filter: none;
  }
  1% {
    opacity: 1;
    filter: brightness(65%) hue-rotate(67.1deg) saturate(167.4%)
      brightness(126.7%);
  }
  3% {
    opacity: 1;
    filter: brightness(65%) hue-rotate(67.1deg) saturate(167.4%)
      brightness(126.7%);
  }
  3.1% {
    opacity: 0;
    filter: none;
  }
  3.2% {
    opacity: 1;
  }
  3.3% {
    opacity: 0;
  }
  3.4% {
    opacity: 1;
  }
  3.5% {
    opacity: 0;
  }
  3.6% {
    opacity: 1;
  }
}
