abx-cost-line-asset-details-section {
  display: block;

  .cost-line-asset-details-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &__column {
      display: flex;
      flex-direction: column;

      &.cost-line-asset-details_carousel-wrapper {
        width: 400px;
        margin-right: 60px;
        row-gap: 4px;

        .cost-line-asset-details_no-image {
          font-weight: bold;
          opacity: 0.4;
          font-size: large;
          height: 100%;
          text-align: center;
          padding-top: 45%;
        }
      }

      &.cost-line-asset-details_specs-wrapper {
        width: 100%;
      }

      .media-viewer-image {
        background-color: $color-light-gray;
        border: 1px solid $color-border-gray;
        pointer-events: none;

        .control-container {
          display: none;
        }

        img,
        .backdrop {
          transition: none;
        }
      }

      .cost-line-asset-details-section__carousel {
        display: flex;
        align-self: center;

        md-icon {
          color: $color-link;
          font-size: 20px;
          &:hover {
            color: $color-link-hover;
          }
        }

        .cost-line-asset-details-section__carousel-of {
          padding: 0 4px;
        }
      }
    }

    &__details-wrapper {
      display: flex;
      height: 300px;
      margin-top: 6px;
      overflow-y: scroll;
      border: 1px solid $color-border-gray;
    }

    &__loading {
      padding: 20px;
    }

    &__info-title {
      font-weight: bold;
      display: flex;
      span {
        margin-left: 6px;
      }
    }

    &__field {
      margin-bottom: 6px;
      padding: 0 10px;
      display: flex;
      border-bottom: 1px solid $color-border-gray;
      padding: 8px 16px;
    }

    &__label {
      text-align: left;
      pointer-events: none;
      &::after {
        content: ":";
      }
    }

    &__field-value {
      padding-left: 8px;
      max-width: 600px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #717171;
      font-style: italic;
      margin: 0px 5px;

      &:empty::before {
        content: "\200B";
      }
    }
  }
}
