abx-pin-type-filter-sidebar {
  $content-width: 240px;

  .abx-sidebar--pin-type-filter {
    position: absolute;
    top: 0;
    height: 100%;
    width: 240px;

    left: -$content-width;
    transition: left 0.3s;
    &.open {
      left: 0;
    }
  }

  .abx-pin-type-header {
    display: flex;
    align-items: center;

    height: 52px;
    padding: 12px 14px 0 10px;
    z-index: 2;

    font-size: 16px;
    background-color: $white;
    border-bottom: 4px solid $blue;
    color: $black-87;
    font-weight: 700;

    .abx-pin-type-header__checkbox {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .abx-pin-type-header__text {
      margin: 0 0 0 4px;
    }
  }

  .abx-pin-type-filters-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $white;
  }

  .abx-side-bar__footer {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
      color: #cccccc;
      font-size: 7px;
      text-align: center;
    }
  }

  .abx-sidebar-btn {
    // Separation between button and app header
    position: absolute;
    top: 12px;
    left: $content-width;

    // Square button (including bottom border)
    height: 40px;
    min-width: 24px;
    width: 24px;
    border-radius: 0;
    margin: 0;
    padding: 0px;

    // Angular Material theming doesn't support what we want here, so
    // override their colors
    background-color: $color-secondary !important;

    // Override our default buttons to add elevation
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12) !important;

    &.md-button.md-raised[disabled] {
      background-color: $white !important;
      cursor: not-allowed;

      md-icon {
        color: $color-medium-gray !important;
      }
    }

    md-icon {
      color: $white !important;
    }
  }
}
