// -------------------------------------
//   Charts
// -------------------------------------

%chart-component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: block !important;
  }
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;

  .chart {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  abx-chart-legend,
  .chart-legend {
    width: 100%;
  }

  @media print {
    display: block !important;
  }
}

.abx-chart--print {
  width: 1040px;
  min-width: 1040px;
  max-width: 1040px;
}

.chart-hover {
  position: relative;

  .label-container {
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border: 2px solid $color-graph-2;
    height: 28px;
    position: absolute;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    white-space: nowrap;

    .label-number {
      font-weight: bold;
    }

    .label-name {
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  .label-container:after,
  .label-container:before {
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .label-container:after {
    border-color: rgba(136, 183, 213, 0);
    border-width: 12px;
    margin-top: -12px;
  }
  .label-container:before {
    border-color: rgba(194, 225, 245, 0);
    border-width: 14px;
    margin-top: -14px;
  }

  .label-container:after,
  .label-container:before {
    right: 100%;
  }

  .label-container:after {
    border-right-color: $white;
  }
  .label-container:before {
    border-right-color: $color-graph-2;
  }

  .label-container-rotate {
    transform: rotateY(-180deg);
    transform-origin: left center;

    .label-number {
      transform: rotateY(-180deg);

      .label-name {
        transform: rotateY(360deg);
      }
    }

    .label-name {
      transform: rotateY(-180deg);
    }
  }
}
