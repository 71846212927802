.summary-filters {
  display: flex;
  flex-direction: row;

  .input-field {
    width: 150px;
    padding-right: 8px;
    text-transform: none;
  }
}
