abx-document-thumbnail {
  display: block;

  > [abx-thumbnail-size="mini"] {
    min-width: 53px;
    max-width: 53px;
    width: 53px;
    min-height: 53px;
    max-height: 53px;
    height: 53px;
  }

  > [abx-thumbnail-size="small"] {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    min-height: 100px;
    max-height: 100px;
    height: 100px;
  }

  > [abx-thumbnail-size="medium"] {
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    min-height: 300px;
    max-height: 300px;
    height: 300px;
  }

  > [abx-thumbnail-size="large"] {
    min-width: 500px;
    max-width: 500px;
    width: 500px;
    min-height: 500px;
    max-height: 500px;
    height: 500px;
  }

  .abx-thumbnail-border {
    border: 1px solid $color-light-gray;
  }

  .abx-thumbnail-image {
    position: relative;

    .abx-thumbnail-pin {
      position: absolute;
    }
  }
}

.abx-thumbnail-popover {
  max-width: none;
}
