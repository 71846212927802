abx-top-banner {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;

  ng-transclude {
    display: flex;
    justify-content: center;

    @extend .elevation-frame-2dp;
    background-color: $color-light-gray;
    border: 1px solid $color-medium-gray;
    width: 50%;
    > * {
      max-width: 560px;
      word-wrap: break-word;
    }
  }
}
