.srp-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 80%;

  &__speech-bubble {
    position: relative;
    background: #ffffff;
    border-radius: 0.4em;
    margin: 16px;
    padding: 16px;
    font-size: 2.5vh;
    line-height: 1.6;
    max-width: 90%;
    max-height: 70%;
    text-align: center;

    @include respond-to(gt-xs) {
      font-size: 16pt;
      margin: 24px;
      padding: 36px;
    }

    @include respond-to(gt-md) {
      max-width: 65%;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &-confused-pup {
      height: 50%;
      width: 50%;
    }
  }
}
