.abx-asset-type-checkbox {
  margin-right: 0;
}

.abx-include-data-checkbox {
  margin-left: 16px;
  md-checkbox {
    margin: 16px 0 0;
  }
}

.abx-manage-export-title {
  font-size: 18px !important;
  margin-left: 16px;
}

.abx-manage-export-title-small {
  font-size: 12px !important;
  margin: 0px 0px 0px 8px;
  color: $color-text !important;
}

.abx-manage-export-options {
  border-style: solid;
  border-width: 1px;
  border-color: $black-12;
  padding: 16px;
}

.abx-export-options {
  margin-bottom: 16px;

  .abx-export-list {
    list-style: none;
    margin-left: -24px;
  }
}
