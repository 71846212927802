.print-inspection-program-dialog {
  .md-dialog-content {
    min-height: 192px;
  }
  .print-actions {
    width: 100%;
    max-width: 600px;
    margin: 16px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &__item {
      flex: 0 1 144px;
      margin-top: 8px;

      button {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        color: $blue;

        .icon {
          font-size: 32px;
          margin-bottom: 8px;
          color: inherit;

          svg {
            fill: currentColor;
          }
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
